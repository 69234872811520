import React, { useEffect, useRef } from 'react'
import { Language } from '../../types/rest'
import { useSelector } from 'react-redux'
import { getLanguage, getLanguageSpec } from '@store/selectors'
import { usePayPalScriptReducer } from '@paypal/react-paypal-js'
import useRest from '../../hooks/useRest'
import { logger } from '@helpers'

const PayPalCurrencyManager: React.FC = () => {
  const language = useSelector(getLanguage)
  const languageSpec = useSelector(getLanguageSpec)

  const [{ options }, dispatchPP] = usePayPalScriptReducer()

  const optionsRef = useRef(options)

  const { results: languages } = useRest<Language>('languages', { lazy: false })

  useEffect(() => {
    optionsRef.current = options
  }, [options])

  useEffect(() => {
    if (!languages.length) return
    const lan = languages.find((l) => l.code === language)
    if (!lan) return
    const lanInfo = lan.info.find((li) => li.code === languageSpec.split('_')[1])
    if (!lanInfo) return
    logger.info('Setting paypal options', { ...optionsRef.current, currency: lanInfo.currency })
    dispatchPP({ type: 'resetOptions', value: { ...optionsRef.current, currency: lanInfo.currency } })
  }, [languages, languageSpec, language, dispatchPP])

  return null
}

export default PayPalCurrencyManager

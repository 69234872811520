import JanusLib, { JanusJS } from '../libs/janus'
import EventEmitter from 'events'
import * as t from './types'
import {logger} from "@helpers";

type JSEP = JanusJS.JSEP
type Publisher = JanusJS.Publisher

class VideoRoom extends EventEmitter {
  janus: JanusJS.Janus
  pluginHandle: JanusJS.PluginHandle | null
  type: t.VideoRoomPluginType | null
  room: number | null
  private_id: number

  constructor(janus: JanusJS.Janus) {
    super()
    this.janus = janus
    this.pluginHandle = null
    this.type = null
    this.room = null
    this.private_id = 0
  }

  init(opts: t.InitOptions = {}): Promise<void> {
    // Quando si vuole pubblicare senza mandare contenuto, vanno settati a true per poter ricevere
    // correttamente lo stream dei publishers
    const { subscriberAnswerMediaAudioSend = false, subscriberAnswerMediaVideoSend = false } = opts
    return new Promise((resolve, reject) => {
      if (!this.janus) return reject('no janus')

      const plugin: JanusJS.PluginOptions = {
        plugin: 'janus.plugin.videoroom',
        success: (pluginHandle) => {
          this.pluginHandle = pluginHandle
          resolve()
        },
        error: (error) => {
          reject(error)
        },
        onmessage: (message, jsep) => {
          const msg = message as JanusJS.VideoRoomPluginMessage
          logger.debug('Janus::Videoroom::Message', msg)
          if (msg.videoroom) {
            if (msg.videoroom === 'joined') {
              this.emit('joined', {
                id: msg.id,
                private_id: msg.private_id,
                room: msg.room,
                publishers: msg.publishers
              })
            } else if (msg.videoroom === 'event') {
              if (msg.streams) this.emit('streams', msg.streams)
              else if (msg.publishers) this.emit('publishers', msg.publishers)
              else if (msg.unpublished) this.emit('leaved', msg.unpublished)
              else if (msg.leaving) this.emit('leaving', msg.leaving)
              else if (msg.joining) this.emit('joining', msg.joining)
              else if (msg.error_code === 436) this.emit('already_exists')
              else if (msg.error_code === 426) this.emit('no_such_room')
              else logger.error('Unhandled event:', msg)
            } else {
              logger.error('Unhandled message:', msg)
            }
          }

          if (jsep) {
            if (this.type === 'publisher') {
              this.pluginHandle?.handleRemoteJsep({ jsep })
            } else if (this.type === 'subscriber' || this.type === 'listener') {
              this.pluginHandle?.createAnswer({
                jsep,
                media: { audioSend: subscriberAnswerMediaAudioSend, videoSend: subscriberAnswerMediaVideoSend },
                success: (jsep: JSEP) => {
                  this.pluginHandle?.send({
                    message: {
                      request: 'start',
                      room: this.room
                    },
                    jsep
                  })
                }
              })
            }
          }

          this.emit('message', { msg, jsep })
        },
        onlocaltrack: (track, on) => this.emit('localtrack', track, on),
        onremotetrack: (track, mid, on) => this.emit('remotetrack', track, mid, on),
        oncleanup: () => this.emit('cleanup')
      }

      this.janus.attach(plugin)
    })
  }

  /**
   * Crea la videoroom
   * @param opts { Object } oggetto con le opzioni del metodo
   * @param opts.publishers { number } numero di publisher massimi nella stanza
   * @param opts.pin {string} codice per poter fare il join
   * @param opts.bitrate { number } velocità con cui mandare lo stream
   * @param opts.record { boolean } se attivare la registrazione o meno
   * @param opts.rec_path { string } path in cui salvare il file di registrazione
   * @param opts.notify_joining { boolean } notifica il join anche di chi non pubblica contenuto
   * @param opts.secret { string } password per poter eseguire azioni da moderatore
   * @param opts.description { string } descrizione della stanza
   * @param opts.videocodec { string } videocodec da usare
   * @return {Promise<unknown>}
   */
  create(opts: t.CreateOptions): Promise<number> {
    const {
      publishers = 4,
      pin,
      bitrate = 256000,
      record = false,
      rec_path,
      notify_joining = false,
      secret,
      description = '',
      videocodec = 'h264'
    } = opts
    return new Promise((resolve, reject) => {
      if (!this.pluginHandle) return reject('plugin not initialized')

      const message: any = {
        request: 'create',
        permanent: false,
        publishers,
        pin,
        videocodec,
        bitrate,
        bitrate_cap: true,
        rec_dir: rec_path,
        notify_joining,
        description,
        secret,
        audiolevel_event: true,
        video_svc: videocodec === 'vp9'
      }

      if (record) {
        message.record = true
      }

      this.pluginHandle.send({
        message,
        success: (res: { room: number }) => {
          resolve(res.room)
        },
        error: (error) => {
          reject(error)
        }
      })
    })
  }

  /**
   * esegue il join nella videoroom
   * @param type {'publisher' | 'subscriber' } tipologia di sessione di cui fare il join,
   *    ogni client solitamente è sia publisher che subscriber con due sessioni separate,
   *    su una pubblica contenuto ed è subsc
   *    riber per ogni altro publisher nella videoroom
   * @param info { Object } oggetto per configurare il join (cambia per publisher e subscriber)
   * @param info.room { number } numero della stanza
   * @param info.pin { string } codice da conoscere per poter entrare
   * @param info.id { number } id dell'utente da assegnare, assegnandolo è facile conoscerlo e quindi
   *    "ricordarlo" nel client (SOLO PUBLISHER)
   * @param info.displayName { string } nome assegnato al publisher (SOLO PUBLISHER)
   * @param info.publisher { number } id del publisher cui sottoscriversi (SOLO SUBSCRIBER)
   * @param info.subscriber { number } private_id del client che si sta sottoscribendo (SOLO SUBSCRIBER)
   */
  join(type: t.VideoRoomPluginType, info: t.JoinInfo): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.pluginHandle) return reject('plugin not initialized')

      this.type = type
      this.room = info.room

      const message: any = {
        request: 'join',
        room: info.room,
        pin: info.pin
      }

      if (type === 'publisher') {
        message.id = info.id
        message.ptype = 'publisher'
        message.display = info.displayName
      } else {
        message.ptype = 'subscriber'
        message.feed = info.publisher
        message.private_id = info.subscriber
        message.streams = info.streams
      }

      this.pluginHandle.send({ message })

      return resolve()
    })
  }

  /**
   * lascia esplicitamente la videoroom
   */
  leave(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.pluginHandle) return reject('plugin not initialized')

      const message = { request: 'leave' }

      this.pluginHandle.send({ message })
      this.pluginHandle.hangup()

      return resolve()
    })
  }

  /**
   * ritorna i partecipanti nella videoroom passata come parametro
   * @param room { number } id della videoroom
   */
  getParticipants(room: number): Promise<Publisher[]> {
    return new Promise((resolve, reject) => {
      if (!this.pluginHandle) return reject('plugin not initialized')

      const message = { request: 'listparticipants', room }
      this.pluginHandle.send({
        message,
        success: (res: { participants: Publisher[] }) => {
          resolve(res.participants)
        },
        error: (e) => {
          reject(e)
        }
      })
    })
  }

  /**
   * Permette di smettere di pubblicare contenuto sedutastante
   */
  unpublish(): Promise<t.UnpublishResponse> {
    return new Promise((resolve, reject) => {
      if (!this.pluginHandle) return reject('plugin not initialized')

      const message = { request: 'unpublish' }
      this.pluginHandle.send({
        message,
        success: (res: t.UnpublishResponse) => {
          resolve(res)
        },
        error: (e) => {
          reject(e)
        }
      })
    })
  }

  start(): Promise<t.StartResponse> {
    return new Promise((resolve, reject) => {
      if (!this.pluginHandle) return reject('plugin not initialized')

      const message = { request: 'start' }
      this.pluginHandle.send({
        message,
        success: (res: t.StartResponse) => {
          resolve(res)
        },
        error: (e) => {
          reject(e)
        }
      })
    })
  }

  /**
   * elimina la videoroom
   * @param room { number } id della videoroom
   * @param opts { Object } oggetto per le opzioni
   * @param opts.secret { string } se configurato in create, è la password per eseguire azioni con privilegi
   * @param opts.permanent { boolean } se true elimina anche room create come permanenti, ovvero che non spariscono
   *    mai, nemmeno con il riavvio di janus
   */
  destroy(room: number, opts: t.DestroyOptions = {}): Promise<number> {
    const { secret, permanent = false } = opts
    return new Promise((resolve, reject) => {
      if (!this.pluginHandle) return reject('plugin not initialized')

      const message: any = { request: 'destroy', room, permanent }
      if (secret) message.secret = secret
      this.pluginHandle.send({
        message,
        success: (res: t.DestroyResponse) => {
          resolve(res.room)
        },
        error: (e) => {
          reject(e)
        }
      })
    })
  }

  /**
   * ritorna tutte le videoroom attualmente attive su janus
   */
  getVideorooms(): Promise<t.Room[]> {
    return new Promise((resolve, reject) => {
      if (!this.pluginHandle) return reject('plugin not initialized')

      const message = { request: 'list' }
      this.pluginHandle.send({
        message,
        success: (res: { list: t.Room[] }) => {
          resolve(res.list)
        },
        error: (e) => {
          reject(e)
        }
      })
    })
  }

  /**
   * muta un partecipante a livello di server
   * @param opts { Object } opzioni del comando
   * @param opts.room { number } id della videoroom
   * @param opts.muted { boolean } id del partecipante da moderare
   * @param opts.id { number } id del partecipante da moderare
   * @param opts.secret { string } password impostata nel create, obbligatoria se impostata
   */
  moderate(opts: t.ModerateOptions): Promise<void> {
    const { room, id, muted, secret } = opts
    return new Promise((resolve, reject) => {
      if (!this.pluginHandle) return reject('plugin not initialized')

      const message: any = { request: 'moderate', room, id, muted: !muted }
      if (secret) message.secret = secret
      this.pluginHandle.send({
        message,
        success: () => {
          resolve()
        },
        error: (e) => {
          reject(e)
        }
      })
    })
  }

  /**
   * caccia un partecipante dalla videoroom (può rientrare aggiornando, non viene bandito)
   * @param opts { Object } oggetto delle opzioni
   * @param opts.room { number } id della videoroom
   * @param opts.user { number } id dell'utente da rimuovere
   * @param opts.secret { string } password impostata nel create, obbligatoria se impostata
   */
  kick(opts: t.KickOptions): Promise<void> {
    const { room, user, secret } = opts
    return new Promise((resolve, reject) => {
      if (!this.pluginHandle) return reject('plugin not initialized')

      const message: any = { request: 'kick', room, id: user }
      if (secret) message.secret = secret
      this.pluginHandle.send({
        message,
        success: () => {
          resolve()
        },
        error: (e) => {
          reject(e)
        }
      })
    })
  }

  /**
   * rinegoziazzione della connessione webrtc per cambiare device / contenuto pubblicato
   * @param opts { Object }
   * @param opts.addAudio { boolean } specifica che l'audio va aggiunto (fallisce se già presente)
   * @param opts.removeAudio { boolean } specifica che l'audio va tolto (fallisce se già non presente)
   * @param opts.addVideo { boolean } specifica che il video va aggiunto (fallisce se già presente)
   * @param opts.removeVideo { boolean } specifica che l'audio va tolto (fallisce se già non presente)
   * @param opts.replaceAudio { boolean } specifica che l'audio va rimpiazzato (va rispecificato in "audio")
   * @param opts.replaceVideo { boolean } specifica che il video va rimpiazzato (va rispecificato in "video")
   * @param opts.audio { string | boolean | MediaTrackConstraints } opzioni audio
   * @param opts.video { string | boolean | MediaTrackConstraints } opzioni video
   */
  renegotiate(opts: t.RenegotiateOptions = {}): Promise<void> {
    const { addAudio, removeAudio, addVideo, removeVideo, replaceVideo, replaceAudio, audio, video } = opts
    return new Promise((resolve, reject) => {
      if (!this.pluginHandle) return reject('plugin not initialized')

      const media = {
        addAudio,
        removeAudio,
        addVideo,
        removeVideo,
        replaceAudio,
        replaceVideo,
        audio,
        video
      }

      this.pluginHandle.createOffer({
        media,
        success: (jsep: JSEP) => {
          const message = {
            request: 'configure',
            audio: true,
            video: true
          }

          this.pluginHandle?.send({ message, jsep })

          resolve()
        },
        error: (error) => {
          reject(error)
        }
      })
    })
  }

  /**
   * inizia a pubblicare contenuto specificato dalle opzioni e dai device
   * @param opts { Object } oggetto di configurazione
   */
  sendStream(opts: t.SendStreamOptions): Promise<void> {
    const { audio, video, devices } = opts
    return new Promise((resolve, reject) => {
      if (!this.pluginHandle) return reject('plugin not initialized')

      const videoinput = devices ? devices.videoinput : null
      const audioinput = devices ? devices.audioinput : null

      this.pluginHandle.createOffer({
        media: {
          audioRecv: false,
          videoRecv: false,
          audioSend: !!audio,
          videoSend: !!video,
          audio,
          video,
          ...(videoinput && { video: { deviceId: videoinput.deviceId } }),
          ...(audioinput && { audio: { deviceId: audioinput.deviceId } })
        },
        success: (jsep: JSEP) => {
          const message = {
            request: 'configure',
            audio: Boolean(audio),
            video: Boolean(video)
          }

          this.pluginHandle?.send({ message, jsep })

          resolve()
        },
        error: (error) => {
          reject(error)
        }
      })
    })
  }

  /**
   * avvia la condivisione dello schermo
   * NB: per condividere sia webcam che schermo servono due publisherSession,
   * quindi va fatto due volte join con id diversi
   * @param video
   */
  async sendCapture({ video }: { video: t.VideoOptions }): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.pluginHandle) return reject('plugin not initialized')

      const media = {
        videoRecv: false,
        audioSend: true,
        video
      }

      this.pluginHandle.createOffer({
        media,
        success: (jsep: JSEP) => {
          const message = {
            request: 'configure',
            audio: true,
            video: true
          }

          this.pluginHandle?.send({ message, jsep })

          resolve()
        },
        error: (error) => {
          reject(error)
        }
      })
    })
  }

  async handleOffer(jsep: JanusJS.JSEP): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.pluginHandle) return reject('plugin not initialized')

      this.pluginHandle.handleRemoteJsep({ jsep })

      return resolve()
    })
  }

  monitorBitrate() {
    const pluginHandle = this.pluginHandle
    if (
      pluginHandle === null ||
      pluginHandle === undefined ||
      pluginHandle.webrtcStuff === null ||
      pluginHandle.webrtcStuff === undefined
    ) {
      console.warn('Invalid handle')
      return 'Invalid handle'
    }
    const config = pluginHandle.webrtcStuff
    if (config.pc === null || config.pc === undefined) return 'Invalid PeerConnection'
    // Start getting the bitrate, if getStats is supported
    if (config.pc.getStats) {
      if (config.bitrate.timer === null || config.bitrate.timer === undefined) {
        logger.debug('Starting bitrate timer (via getStats)')
        // @ts-ignore
        config.bitrate.timer = window.setInterval(() => {
          config.pc.getStats().then((stats) => {
            stats.forEach((res) => {
              if (!res) return
              // Parse stats now
              if ((res.bytesSent || res.bytesReceived) && res.timestamp && res.mediaType === 'video') {
                let bytesSent = 0
                let bytesReceived = 0
                if (res.bytesSent) bytesSent = res.bytesSent
                if (res.bytesReceived) bytesReceived = res.bytesReceived
                // @ts-ignore
                config.bitrate.bsnow = bytesSent + bytesReceived
                config.bitrate.tsnow = res.timestamp
                // @ts-ignore
                config.bitrate.mtnow = res.mediaType
                if (config.bitrate.bsbefore === null || config.bitrate.tsbefore === null) {
                  // Skip this round
                  config.bitrate.bsbefore = config.bitrate.bsnow
                  config.bitrate.tsbefore = config.bitrate.tsnow
                } else {
                  // Calculate bitrate
                  // @ts-ignore
                  let timePassed = config.bitrate.tsnow - config.bitrate.tsbefore
                  if (JanusLib.webRTCAdapter.browserDetails.browser == 'safari') timePassed = timePassed / 1000 // Apparently the timestamp is in microseconds, in Safari
                  let bitRate = 0
                  if (timePassed !== 0) {
                    // @ts-ignore
                    bitRate = Math.round(((config.bitrate.bsnow - config.bitrate.bsbefore) * 8) / timePassed)
                    if (JanusLib.webRTCAdapter.browserDetails.browser === 'safari') bitRate = bitRate / 1000
                    config.bitrate.value = bitRate + ' kbits/sec'

                    config.bitrate.bsbefore = config.bitrate.bsnow
                    config.bitrate.tsbefore = config.bitrate.tsnow
                  }
                }
              }
            })
          })
        }, 1000)
        return 'bitrate not yet available' // We don't have a bitrate value yet
      }
      return config.bitrate.value
    } else {
      console.warn('Getting the video bitrate unsupported by browser')
      return 'Feature unsupported by browser'
    }
  }

  async startRecording(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.pluginHandle) return reject('plugin not initialized')

      this.pluginHandle.send({
        message: {
          request: 'configure',
          record: true
        }
      })

      return resolve()
    })
  }
}

export default VideoRoom

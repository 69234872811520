import * as events from '../../api/socket/types'
import { Staff, User, Live } from '../../types/rest'
export * from '../../api/socket/types'

export const SET_MEETING = 'SET_MEETING'
export const SET_LIVE = 'SET_LIVE'
export const SET_USERS = 'SET_USERS'
export const SET_STAFF = 'SET_STAFF'
export const RESET_MEETING = 'RESET_MEETING'

export interface MeetingFromServer {
  live: Live | null
  staff: Staff[]
  users: User[]
  handsUp: number[]
  canTalk: number[]
}

export interface MeetingState {
  live: Live | null
  staff: Map<string, Staff>
  users: Map<number, User>
  handsUp: Set<number>
  canTalk: Set<number>
}

//region Actions

export interface UserRaisedHand {
  type: typeof events.meeting_user_raised_hand
  payload: number
}

export interface UserLoweredHand {
  type: typeof events.meeting_user_lowered_hand
  payload: number
}

export interface UserCanTalkNow {
  type: typeof events.meeting_user_can_talk_now
  payload: number
}

export interface UserIsMuted {
  type: typeof events.meeting_user_is_muted
  payload: number
}

export interface UserJoined {
  type: typeof events.meeting_user_joined
  payload: User
}

export interface MeetingEnd {
  type: typeof events.meeting_end
  payload: number
}

export interface SetLive {
  type: typeof SET_LIVE
  payload: Live
}

export interface SetMeeting {
  type: typeof SET_MEETING
  payload: MeetingState
}

export interface SetUsers {
  type: typeof SET_USERS
  payload: Map<number, User>
}

export interface SetStaff {
  type: typeof SET_STAFF
  payload: Map<string, Staff>
}

export interface ResetMeeting {
  type: typeof RESET_MEETING
}

export type MeetingActionTypes =
  | UserRaisedHand
  | UserLoweredHand
  | UserCanTalkNow
  | UserIsMuted
  | SetLive
  | UserJoined
  | SetMeeting
  | SetUsers
  | SetStaff
  | MeetingEnd
  | ResetMeeting
//endregion

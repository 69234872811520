import { useState, useEffect } from 'react'
import useRest from './useRest'

function useVideoToken(entityName: string, entityId?: number) {
  const [videoToken, setVideoToken] = useState('')
  const [id, setId] = useState<number | null>(entityId || null)
  const [notAllowed, setNotAllowed] = useState(false)
  const { getById: getVideoToken } = useRest('get-video-token')

  useEffect(() => {
    if (!id && entityName !== 'trailers') return
    let timeoutId: number
    const getter = () => {
      getVideoToken({ id: id ? `${entityName}/${id}` : `${entityName}` })
        .then((res) => {
          setVideoToken(res.videoToken)
          timeoutId = window.setTimeout(() => {
            getter()
          }, 160000)
        })
        .catch(() => {
          setNotAllowed(true)
        })
    }
    getter()
    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [entityName, id, getVideoToken])

  return { videoToken, setId, notAllowed }
}
export default useVideoToken

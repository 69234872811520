import React, {useMemo} from 'react'
import styled from 'styled-components'
import useRest from '../../hooks/useRest'
import {Author} from '../../types/rest'
import conf from '@configuration'
import {Splide, SplideSlide} from '@splidejs/react-splide'
import Logo from '../../images/logo_full.svg'
import {useSelector} from 'react-redux'
import {getTranslation} from '@store/selectors'
import useMediaQuery from '../../hooks/useMediaQuery'
import {small} from '../../constants'
import {useNavigate} from 'react-router'

/**
 * Slideshow degli autori in homepage che variano randomicamente
 */
interface Props {
  title?: StringConstructor
}

export const AuthorsSlider: React.FC<Props> = () => {
  const { results: authors } = useRest<Author>('authors', { lazy: false, initialFilters })
  const translation = useSelector(getTranslation)
  const isMobile = useMediaQuery(`(max-width: ${small})`)
  const navigate = useNavigate()

  const shuffledAuthors: Author[] = useMemo(()=>{
      return shuffle(authors)
  },[authors])

  return authors.length > 0 ? (
    <Container>
      <LogoBox isMobile={isMobile}>
        <img style={{ width: '100%'}} src={Logo} alt="" />
        <h4 style={{ color: 'var(--primary)', fontSize: isMobile ? 'var(--button-body-mobile)' : 'var(--surtitle-item)'}}>{translation.PAGES.userHome.ourAuthors}</h4>
      </LogoBox>
      <Slider>
        {authors.length > 0 ? (
          <SplideContainer>
            <Splide
              style={{ width: '100%' }}
              aria-label="My Favorite Images"
              options={{
                arrows: false,
                perPage: 1,
                autoWidth: true,
                autoHeight: true,
                interval: 3000,
                gap: '1rem',
                pagination: false
              }}>
              {shuffledAuthors.map((item, k) => (
                <SplideSlide style={{ display: 'flex', justifyContent: 'center' }} key={k}>
                  <Card onClick={() => navigate(`/authors/${item.slug || item.id}`)}>
                    <Avatar isMobile={isMobile}>
                      <img src={`${conf.baseUrl}${item.avatar}`} alt="" />
                    </Avatar>
                    <Info isMobile={isMobile}>
                      {item.name} {item.surname}
                    </Info>
                  </Card>
                </SplideSlide>
              ))}
            </Splide>
          </SplideContainer>
        ) : (
          <SplideContainer>
            <h4 style={{ height: '10rem', textAlign: 'center', lineHeight: '10rem', fontSize: '2rem' }}>
              {translation.PAGES.userHome.noContent}
            </h4>
          </SplideContainer>
        )}
      </Slider>
    </Container>
  ) : (
    <></>
  )
}

const initialFilters = {
  avatar: 'true',
  isVisible: 1
}

let shuffle = (unShuffled: any[]) => {
    return unShuffled
        .map(value => ({value, sort: Math.random()}))
        .sort((a, b) => a.sort - b.sort)
        .map(({value}) => value)
}

//region Style

const Container = styled.div`
  width: 100%;
  gap: 0.5rem;
  display: grid;
  grid-template-columns:  2fr 9fr;
  padding-left: calc( (100vw - var(--max-content-width)) / 2 - 5px) ;
  margin: 1rem auto;
  height: min-content;
  margin-top: 4rem;
  @media (max-width: 1340px) {
   padding-left: 20px ;
  }
`

const LogoBox = styled.div<{ isMobile: boolean }>`
  width: ${({isMobile}) => isMobile ? '9rem' : '15rem'};
`

const Slider = styled.div`
  overflow-x: hidden;
  height: min-content;
  overflow-y: visible;
`
const SplideContainer = styled.div`
  overflow-x: hidden;
  width: 100%;
`

const Card = styled.div`
  cursor: pointer;
  transition: 0.5s ease-out;
  opacity: 0.9;
  &:hover {
    opacity: 1;
    transition: 0.2s;
  }
  &:hover div {
    color: var(--primary);
    transition: 0.2s;
  }
`

const Avatar = styled.span<{ isMobile: boolean }>`
  width: ${(props) => (props.isMobile ? '8rem' : '25rem')};
  height: ${(props) => (props.isMobile ? '15rem' : '33rem')};
  background-color: var(--primary);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  & > img {
    object-fit: cover;
    max-height: 100%;
  }
`

const Info = styled.div<{isMobile: boolean}>`
  color: #ffffff;
  margin: 2rem 0;
  font-weight: 600;
  font-size: ${({isMobile}) => isMobile ? '1.1rem' : 'var(--big-parag-fs)'};
  transition: 0.4s ease-out;
  width: min-content;
  min-width: 100%;
`

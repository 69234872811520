import { RootState } from './types'

// Login
export const getLogin = (state: RootState) => state.login
export const getToken = (state: RootState) => state.login.token
export const getAuthenticated = (state: RootState) => state.login.isAuthenticated
export const getUser = (state: RootState) => state.login.user
export const getError = (state: RootState) => state.login.error

//applicationState
export const getTitle = (state: RootState) => state.applicationState.title
export const getDevices = (state: RootState) => state.applicationState.devices
export const getSelectedDevices = (state: RootState) => state.applicationState.selectedDevices

// Meeting
export const getMeeting = (state: RootState) => state.meeting
export const getUsers = (state: RootState) => state.meeting.users
export const getUsersArray = (state: RootState) => [...state.meeting.users.values()]
export const getStaff = (state: RootState) => state.meeting.staff
export const getStaffArray = (state: RootState) => [...state.meeting.staff.values()]
export const getHandsUp = (state: RootState) => [...state.meeting.handsUp]
export const getCanTalk = (state: RootState) => [...state.meeting.canTalk]
export const getLive = (state: RootState) => state.meeting.live

// Messages
export const getMessages = (state: RootState) => state.messages

// Localization
export const getLanguage = (state: RootState) => state.localization.language
export const getLanguageSpec = (state: RootState) => state.localization.languageSpec
export const getTranslation = (state: RootState) => state.localization.translation

import { Reducer } from 'redux'
import * as t from './types'

export const initialState: t.MeetingState = {
  live: null,
  staff: new Map(),
  users: new Map(),
  handsUp: new Set(),
  canTalk: new Set()
}

type MeetingReducer = Reducer<t.MeetingState, t.MeetingActionTypes>

const meetingReducer: MeetingReducer = (state = initialState, action: t.MeetingActionTypes): t.MeetingState => {
  switch (action.type) {
    case t.meeting_user_raised_hand:
      return { ...state, handsUp: new Set([...state.handsUp, action.payload]) }
    case t.meeting_user_lowered_hand:
      return { ...state, handsUp: new Set([...state.handsUp].filter((h) => h !== action.payload)) }
    case t.meeting_user_can_talk_now:
      return {
        ...state,
        canTalk: new Set([...state.canTalk, action.payload]),
        handsUp: new Set([...state.handsUp].filter((h) => h !== action.payload))
      }
    case t.meeting_user_is_muted:
      return { ...state, canTalk: new Set([...state.canTalk].filter((c) => c !== action.payload)) }
    case t.meeting_end:
      return { ...state, live: state.live ? { ...state.live, closed: true } : null }
    case t.SET_LIVE:
      return { ...state, live: action.payload }
    case t.SET_MEETING:
      return { ...action.payload }
    case t.SET_USERS:
      return { ...state, users: action.payload }
    case t.SET_STAFF:
      return { ...state, staff: action.payload }
    case t.RESET_MEETING:
      return { ...initialState }
    default:
      return state
  }
}

export default meetingReducer

import React from 'react'
import styled from 'styled-components'

type Props = {
  label: string
}
export const NoDataForSlider: React.FC<Props> = ({ label }) => {
  return <Container>{label}</Container>
}

//region Style

const Container = styled.div`
  font-size: 2rem;
  display: flex;
  place-self: center;
`
//endregion

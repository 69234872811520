export const already_connected = 'already-connected'
export const continue_here = 'continue-here'
export const force_logout = 'force-logout'
export const logout = 'logout'
export const ready = 'ready'
export const subscription_ready = 'subscription-ready'
export const payment_ready = 'payment-ready'

//region Meeting
export const meeting_start = 'meeting:start'
export const meeting_now_online = 'meeting:now-online'
export const meeting_leave = 'meeting:leave'
export const meeting_user_left = 'meeting:user-left'
export const meeting_staff_left = 'meeting:staff-left'
export const meeting_join = 'meeting:join'
export const meeting_user_joined = 'meeting:user-joined'
export const meeting_staff_joined = 'meeting:staff-joined'
export const meeting_raise_hand = 'meeting:raise-hand'
export const meeting_user_raised_hand = 'meeting:user-raised-hand'
export const meeting_lower_hand = 'meeting:lower-hand'
export const meeting_user_lowered_hand = 'meeting:user-lowered-hand'
export const meeting_can_talk = 'meeting:can-talk'
export const meeting_user_can_talk_now = 'meeting:user-can-talk-now'
export const meeting_mute = 'meeting:mute'
export const meeting_user_is_muted = 'meeting:user-is-muted'
export const meeting_end = 'meeting:end'

export const meeting_send_message = 'meeting:send-message'
export const meeting_got_new_message = 'meeting:got-new-message'
export const meeting_delete_message = 'meeting:delete-message'
export const meeting_message_deleted = 'meeting:message-deleted'
//endregion

import React, { MouseEventHandler, useState } from 'react'
import styled from 'styled-components'

/**
 * Componente che renderizza il bottone multiuso per diverse call-to-action
 */
export const BigButton: React.FC<Props> = (props) => {
  const { label, background, color, icon, onClick, border, width, style = {}, labelStyle = {}, hoverRule, ...rest } = props
  const [hovered, setHovered] = useState(false)

  return (
    <Container
      hoverRule={hoverRule}
      style={style}
      width={width}
      border={border}
      background={background}
      color={color}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick ? onClick : () => {}}>
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          alignSelf: 'center',
          fontSize: '1.56rem',

          // width: 'fit-content',
          display: 'block',
          whiteSpace: 'nowrap',
          ...labelStyle
        }}>
        {label}
      </div>
      {/* {icon(hovered)} */}
    </Container>
  )
}

type Props = {
  type?: HTMLButtonElement['type']
  background?: string
  color?: string
  label: React.ReactNode
  icon?: (hovered: boolean) => React.ReactNode
  onClick?: MouseEventHandler
  width?: string
  border?: string
  style?: React.CSSProperties
  labelStyle?: React.CSSProperties
  hoverRule?: string
}
//region Style

type ContainerProps = {
  background?: string
  color?: string
  width?: string
  border?: string
  hoverRule?: string
}
const Container = styled.div<ContainerProps>`
  border-radius: 6px;
  background-color: ${(props) => props.background || '#ffffff'};
  color: ${(props) => (props.color && props.color !== 'none' ? props.color : 'var(--primary)')};
  padding: 20px 25px !important;
  width: ${(props) => (props.width ? props.width : '250px')};
  font-weight: bold;
  font-size: 23px;
  display: grid;
  place-content: center start;
  grid-template-columns: 1fr auto;
  padding: 0.2rem 1.5rem;
  cursor: pointer;
  line-height: 1;
  ${(props) => (props.border && props.border !== 'none' ? 'border: 1px solid' + props.border + ';' : '')}
  text-align: center;
  transition: 0.4s;
  &:hover {
  transition: 0.4s;
    ${({hoverRule}) => hoverRule ? hoverRule : 'transform: scale(1.05);'}
  }
`

//endregion

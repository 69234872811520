import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {Product} from '../../../../types/rest'
import {useSelector} from 'react-redux'
import {getTranslation} from '@store/selectors'
import {animated, config, useSpring} from 'react-spring'
import useHeight from '../../../../hooks/useHeight'
import {SearchInput} from '../../../common/SearchInput'
import {ReactComponent as SearchIcon} from '../../../../images/icons/newSearch.svg'
import useRest from '../../../../hooks/useRest'
import SearchCard from "../../../common/SearchCard"

/**
 * Componente per la visualizzazione espansa del Menu
 * @param top: pixel da distanziare dall'alto per non coprire il menu
 * @param hide: funzione per la chiusura eseguita sul click away o quando si naviga altrove
 * @param categories: categorie recuperate tramite api
 * @constructor
 */

const SearchBox: React.FC<Props> = ({ on, hide, top, isInTemplate }) => {
  const [timer, setTimer] = useState(0)
  const [search, _setSearch] = useState('')
  const [showNoResultsAlert, setShowNoResultsAlert] = useState<boolean>(false)
  const searchRef = useRef(search)
  const translation = useSelector(getTranslation)
  const [heightRef, height] = useHeight()
  const slideInStyles = useSpring({
    config: { ...config.stiff },
    from: { height: 0 },
    to: {
      height: on ? height + 100 : 0
    }
  })
  const { result, getById } = useRest<{ products: Product[]; suggestions: string[] }>('products/search', {
    lazy: false
  })

  const setSearch = (s: string) => {
    searchRef.current = s
    _setSearch(s)
  }

  const doSearch = (query: string) => {
    setShowNoResultsAlert(false)
    if (timer) {
      clearTimeout(timer)
      setTimer(0)
    }

    if (query === '') {
      getById({id: 'search', afterPath: `?text=${encodeURIComponent(query)}`})
        .then((res) => {
          res.products && res.products.length === 0 && setShowNoResultsAlert(true)
        })
    }
    else {
      getById({id: 'search', afterPath: `?text=${encodeURIComponent(query)}`})
        .then((res) => {
          res.products && res.products.length === 0 && setShowNoResultsAlert(true)
        })
    }
  }

  return (
    <Container isInTemplate={isInTemplate} onClick={() => hide()} style={{ ...slideInStyles, overflow: 'hidden' }} top={top}>
      <Box ref={heightRef} isInTemplate={isInTemplate} onClick={(e) => e.stopPropagation()}>
        <SearchArea>
          <div style={{width: '100%'}}>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr auto', width: '100%',   alignItems: 'start' }}>
              <SearchInput
                placeholder={translation.topBar.search}
                autoComplete="off"
                keyPress={(event) => {
                  if (event.key === 'Enter') doSearch(search)
                }}
                inputStyle={{ fontSize: '35px' , width: '100%'}}
                style={{
                  containerWidth: '100%',
                  minWidth: '100%',
                  inputFontSize: '35px',
                  inputFontWeight: '600',
                  textColor: '#ffffff'
                }}
                input={{
                  value: search,
                  onChange: (e) => {
                    setShowNoResultsAlert(false)
                    setSearch(e.target.value)
                    if (timer) {
                      clearTimeout(timer)
                    }
                    setTimer(
                      window.setTimeout(() => {
                        doSearch(searchRef.current)
                      }, 500)
                    )
                  }
                }}
              />
              <SearchButton type="submit">
                <SearchIcon/>
              </SearchButton>
            </div>
          </div>
        </SearchArea>
        <ResultsAreaContainer>
          <ResultsArea nRows={result?.products?.length}>
            {result?.products && result.products.map((res, i: number) => (
              <SearchCard key={i} data={res} hide={hide}/>
            ))}
            {showNoResultsAlert && search && search.trim() &&
              <Alert>
                <p>{translation.topBar.noResults}: </p>
                <div>{search}</div>
              </Alert>}
          </ResultsArea>
        </ResultsAreaContainer>
      </Box>
    </Container>
  )
}

export default SearchBox

type Props = {
  on: boolean
  hide: Function
  top: number
  isInTemplate: boolean
}

//region Style
const ResultsAreaContainer = styled.div`
  overflow: auto;
  ::-webkit-scrollbar {
       background: transparent;
    }
`
const Container = styled(animated.div)<{ top: number, isInTemplate: boolean }>`
  position: absolute;
  top: ${({isInTemplate})=> isInTemplate ? 'var(--topbar-height)' : '155px' };
  right: 0;
  left: 0;
  z-index: 99999;

`
const Box = styled.div<{ isInTemplate: boolean }>`
  padding-top: 3rem;
  background-color: var(--primary);
  display: grid;
  grid-template-rows: auto 1fr;
  height:${({isInTemplate})=> isInTemplate ? 'calc(100vh - var(--topbar-height))' : 'calc(100vh - 155px)' } ;
`
const SearchArea = styled.div`
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: var(--max-content-width);
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  & svg {
    filter: brightness(0);
  }
  & button {
    margin-top: 1rem;
    background-color: transparent;
    border: 0px;
    cursor: pointer;
  }
`
const ResultsArea = styled.div<{ nRows: number }>`
  display: grid;
  grid-template-rows: ${({nRows}) => nRows > 2 && '1fr'}1fr ;
  grid-template-columns: repeat(auto-fit, minmax(20%, 31%));
  column-gap: 30px;
  row-gap: 50px;
  margin: 3rem auto;
  height: min-content;
  margin-top: 4rem;
  width: 100%;
  max-width: var(--max-content-width);
  padding: 0 15px;
  position: relative;
`
const SearchButton = styled.button`
 aspect-ratio: 1/1;
 margin-top: 0 !important;
 place-self: start;
 padding: 0;
  & svg{
    filter: invert(0);
    height: 53px;
    width: 53px;
  }
`
const Alert = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  grid-template-areas: 2;
  font-size: 2.1rem;
  font-weight: 600;
  position: absolute;
  color: #ffffff;
  top: 0;
  left: 0;
`
//endregion



import React, {useCallback} from 'react'
import styled from 'styled-components'
import {useDispatch, useSelector} from 'react-redux'
import {getLanguage} from '@store/selectors'
import getTranslation from '../../../../helpers/getTranslation'
import {setLanguage, setTranslation} from '@store/actions'

const ChangeLanguageButton = ({style}: any) => {
  const language = useSelector(getLanguage)
  const dispatch = useDispatch()

  const getLabel = useCallback(() => {
    return language === 'en' ? 'EN' : 'IT'
  }, [language])

  const changeLanguage = () => {
    if(language === 'en') {
      getTranslation('it')
        .then((translation) => {
          dispatch(setLanguage('it'))
          dispatch(setTranslation(translation.default))
        })
    } else {
      getTranslation('en')
        .then((translation) => {
          dispatch(setLanguage('en'))
          dispatch(setTranslation(translation.default))
        })
    }

  }

  return (
    <Container style={style} onClick={changeLanguage}>{getLabel()}</Container>
  )
}

const Container = styled.div`
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`

export default ChangeLanguageButton

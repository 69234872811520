import { Socket } from 'socket.io-client'
import * as events from '../../api/socket/types'
import { RootState } from '../types'
import { Dispatch } from 'redux'
import { getMeeting, getStaff, getUsers } from '../selectors'
import { logger } from '@helpers'
import { Live, Staff, User } from '../../types/rest'
import * as t from './types'
import { MeetingFromServer, MeetingState } from './types'

export const joinAfterDisconnection = (socket: Socket) => (dispatch: Dispatch, getState: () => RootState) => {
  logger.debug('JOIN AFTER DISCONNECTION')
  const meeting = getMeeting(getState())
  if (!meeting.live) return
  socket.emit(events.meeting_join, meeting.live.id, (meeting: MeetingFromServer | null) => {
    if (meeting) dispatch(setMeeting(meeting))
  })
}

export const setLive = (live: Live) => ({ type: t.SET_LIVE, payload: live })

export const setMeeting = (payload: MeetingFromServer) => {
  const meeting: MeetingState = {
    live: payload.live,
    canTalk: new Set(payload.canTalk),
    handsUp: new Set(payload.handsUp),
    users: new Map(payload.users.map((u) => [u.id, u])),
    staff: new Map(payload.staff.map((s) => [s.username, s]))
  }
  return { type: t.SET_MEETING, payload: meeting }
}

export const userJoined = (payload: User) => (dispatch: Dispatch, getState: () => RootState) => {
  const newMap = new Map(getUsers(getState()))
  newMap.set(payload.id, payload)
  dispatch({ type: t.SET_USERS, payload: newMap })
}

export const staffJoined = (payload: Staff) => (dispatch: Dispatch, getState: () => RootState) => {
  const newMap = new Map(getStaff(getState()))
  newMap.set(payload.username, payload)
  dispatch({ type: t.SET_STAFF, payload: newMap })
}

export const userLeft = (userId: number) => (dispatch: Dispatch, getState: () => RootState) => {
  const newMap = new Map(getUsers(getState()))
  newMap.delete(userId)
  dispatch({ type: t.SET_USERS, payload: newMap })
}

export const staffLeft = (username: string) => (dispatch: Dispatch, getState: () => RootState) => {
  const newMap = new Map(getStaff(getState()))
  newMap.delete(username)
  dispatch({ type: t.SET_STAFF, payload: newMap })
}

export const resetMeeting = () => ({ type: t.RESET_MEETING })

export const nowOnline = (meeting: MeetingFromServer) => {}

import React  from 'react'
import styled from 'styled-components'
import {PromoArea} from '../../types/rest'
import {useSelector} from 'react-redux'
import {getTranslation} from '@store/selectors'
import {useNavigate} from 'react-router-dom'

export interface PromoAreaButtonProps {
  disabled?: boolean
  promoArea: PromoArea
}

export const PromoAreaButton = (props: PromoAreaButtonProps) => {
  const { disabled = false, promoArea } = props

  const translation = useSelector(getTranslation)
  const navigate = useNavigate()

  return (
    <StyledButton
      theme={promoArea.theme}
      disabled={disabled}
      onClick={() => {promoArea.url && navigate(promoArea.url)}}>
      <div>
        {/*@ts-ignore*/}
        {translation.common.buttons[promoArea.action] || promoArea.action}
      </div>
    </StyledButton>
  )
}

type StyledButtonProps = { theme?: 'light' | 'dark' }

// region Style
const StyledButton = styled.button<StyledButtonProps>`
  position: relative;
  border: 0;
  font-size: 20px;
  min-width: 178px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  font-weight: 600;
  color: #ffffff;
  padding: 20px 25px !important;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  width: min-content;
  line-height: 1;
  border-radius: 6px;
  transition: 0.4s;
  ${({theme}) =>
    theme === 'dark' ? 
      `background-color: var(--black-base); 
        color: #ffffff; 
        border: 1px solid #000000; 
        &:hover { 
          color: var(--primary); 
          border: 1px solid var(--primary); 
          background-color: transparent;
        }` :
      `background-color: var(--primary); 
        color: #ffffff; 
        &:hover { 
          background-color: #564c78; 
        }`
  }}

  &:hover{
    transition: 0.2s;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    outline: 0;
  }

  @media print {
    display: none;
  }
`
// endregion

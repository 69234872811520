import styled from 'styled-components'
import React from 'react'

interface Props {
  data?: any[]
}

/**
 * Componente che mostra un un immagine di un autore/artista
 * @param props.data {any[]}  ???
 * @constructor
 */
export const Authors: React.FC<Props> = (props) => {
  const { data = [1, 2, 3, 4] } = props

  return (
    <Container>
      <Title>i nostri maestri / insegnanti</Title>
      <List data={data.length}>
        {data.map((_, i) => (
          <AuthorDetails key={i}>
            <Thumbnail
              src="https://c0.wallpaperflare.com/preview/811/935/645/adults-analysis-brainstorming-collaboration.jpg"
              alt=""
            />
          </AuthorDetails>
        ))}
      </List>
    </Container>
  )
}

const Container = styled.div`
  height: 15rem;
  display: grid;
  gap: 1rem;
  width: 100vw;
  grid-template-rows: max-content auto;
`

const Title = styled.div`
  place-self: center;
  text-transform: uppercase;
`
const List = styled.div<{ data: number }>`
  display: grid;
  grid-template-columns: repeat(${({ data }) => data}, 1fr);
  gap: 1rem;
`
const AuthorDetails = styled.div`
  height: 100%;
  border: 1px solid black;
  background-color: red;
`

const Thumbnail = styled.img``

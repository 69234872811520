import moment from 'moment'
import { AnyObject } from '../types'

const dateFields = ['fromDate', 'toDate']

//funzione per convertire i campi data in timestamp
export const formatValues = (values: AnyObject): AnyObject => {
  Object.entries(values).forEach(([key, value]) => {
    if (dateFields.includes(key)) {
      if (value) {
        values[key] = moment(value).valueOf()
      } else {
        delete values[key]
      }
    }
  })
  return values
}

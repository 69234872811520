import React, {useEffect} from 'react'
import styled from 'styled-components'
import TopBar from './TopBar/TopBar'
import Footer from './Footer'
import {small} from '../../constants'

import {Outlet, useLocation} from 'react-router-dom'
import useMediaQuery from '../../hooks/useMediaQuery'

/**
 * Componente Template del Front end
 * @param children
 * @constructor
 */
const Template: React.FC<{ topbarHeight: number }> = ({ topbarHeight }) => {
  const location = useLocation()
  const isMobile = useMediaQuery(`(max-width: ${small})`)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])
  return (
    <>
      <Container isMobile={isMobile} topbarHeight={topbarHeight}>
        <TopBar isInTemplate  height={topbarHeight} />
        <Content isMobile={isMobile} topbarHeight={topbarHeight}>
          <Outlet />
          <Footer />
        </Content>
      </Container>
    </>
  )
}

export default Template

//region Style

type ContainerProps = {
  topbarHeight: number
  isMobile: boolean
}
const Container = styled.div<ContainerProps>`
  position: relative;
  transition: all 0.3s;
  --topbar-height: ${(props) => `${props.topbarHeight}px`};
  width: 100%;
`

const Content = styled.div<ContainerProps>`
  grid-area: content;
  overflow-y: visible;
  margin: 0 auto;
  padding-top: ${(props) => (!props.isMobile ? `${props.topbarHeight}px` : '85px')};
  height: 100%;
  min-height: calc(100vh - var(--topbar-height));
`
//endregion

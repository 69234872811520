import * as events from '../../api/socket/types'
import { Message } from '../../types/rest'
export * from '../../api/socket/types'

export const INITIALIZE_MESSAGES = 'INITIALIZE_MESSAGES'
export type MessagesState = Message[]

//region Actions

export interface GotNewMessage {
  type: typeof events.meeting_got_new_message
  payload: Message
}

export interface InitializeMessages {
  type: typeof INITIALIZE_MESSAGES
  payload: Message[]
}

export interface MessageDeleted {
  type: typeof events.meeting_message_deleted
  payload: string
}

export type MessagesActonTypes = GotNewMessage | InitializeMessages | MessageDeleted
//endregion

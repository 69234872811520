import { Reducer } from 'redux'
import * as t from './types'
import { Languages, LanguagesSpecializations } from '../../types/rest'
import { EDIT_USER, LOGIN, RENEW_TOKEN } from '../login/types'

export const initialState: t.LocalizationState = {
  language: (navigator.language.split('-')[0] as Languages) || 'it',
  languageSpec: (navigator.language.replace('-', '_') as LanguagesSpecializations) || 'it_IT',
  translation: {} as any
}

type LocalizationReducer = Reducer<t.LocalizationState, t.LocalizationActionTypes>

const localizationReducer: LocalizationReducer = (
  state = initialState,
  action: t.LocalizationActionTypes
): t.LocalizationState => {
  switch (action.type) {
    case t.SET_TRANSLATION:
      return {
        ...state,
        translation: action.payload
      }
    case t.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload
      }
    case EDIT_USER:
      return {
        ...state,
        language: action.payload.language.split('_')[0] as Languages,
        languageSpec: action.payload.language
      }
    case LOGIN:
    case RENEW_TOKEN:
      return {
        ...state,
        language: action.payload.user.language.split('_')[0] as Languages,
        languageSpec: action.payload.user.language
      }
    default:
      return state
  }
}

export default localizationReducer

import styled from 'styled-components'
import {Product} from 'types/rest'
import React, {useCallback} from 'react'
import Play from '../../../images/icons/play_svg_variant.svg'
import {formatDuration} from '../../../helpers/formatDuration'
import {useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {getLanguage, getTranslation} from '@store/selectors'
import useMediaQuery from '../../../hooks/useMediaQuery'
import {small} from '../../../constants'

const SideMenu: React.FC<Props> = ({

  product,
  menuState,
  lessonId,
  courseId,
  openMenu,
  currentTime,
  wideOpenWidth
}) => {
  const language = useSelector(getLanguage)
  const translation = useSelector(getTranslation)
  const navigate = useNavigate()
  const isMobile = useMediaQuery(`(max-width: ${small})`)

  const renderLeaf = useCallback(({item, i}: any) => {
    return (<Module key={item.id}>
      {parseInt(lessonId) === item.id && (
        <div style={{ color: '#7c6eb0', display: 'flex', alignItems: 'center' }}>
          <img src={Play} alt="" style={{ height: '1.1rem', width: 'min-content' }} />
          <p style={{ marginLeft: '1rem' }}>{translation.PAGES?.courses.nowPlaying}</p>
        </div>
      )}
      <Lesson>
        <SubModuleTitle
          onClick={() => {

            openMenu()
            navigate(`/courses/${item.productSlug || courseId}/lessons/${item.slug ||item.id}`)
          }}>
          {`${i + 1}. ${item.name}`}
        </SubModuleTitle>
        <div>
          {parseInt(lessonId) === item.id && (
            <span>
                              <Time>{formatDuration(currentTime || 0)} </Time> <span>/</span>
                            </span>
          )}
          <Time>{formatDuration(item?.video?.duration || 0)}</Time>
        </div>
      </Lesson>
      <Content>{item.authors.map((a: any, i: number) => `${a.name} ${a.surname}${i < item.authors.length - 1 ? ', ' : ''}`)}</Content>
    </Module>)
  }, [courseId, currentTime, lessonId, openMenu, navigate, translation.PAGES?.courses.nowPlaying])

  const renderStructure = useCallback(({item, i}: any) => {
    if(!item.children || item.children.length === 0) {
      return renderLeaf({item, i})
    }

    return (
      <BigModule key={item.id} isSubmodule={item.parentId}>
        {!item.parentId && <p style={{color: '#7c6eb0', fontSize: '1.1rem'}}>Modulo {i + 1}</p>}
        <ModuleTitle isSubmodule={item.parentId}>
          {item.translations[language].title}
          <div style={{ width: '100%', borderBottom: '1px solid #bebebe', marginTop: '0.7rem' }}></div>
        </ModuleTitle>
        <Content>
          {item?.children.map((child: any, i: number) => renderStructure({item: child, i}))}
        </Content>
      </BigModule>
    )
  }, [renderLeaf, language])

  return (
    <Container id="side" isMobile={isMobile} menuState={menuState}>
      <MainContent isMobile={isMobile} menuState={menuState} maxWidth={wideOpenWidth}>

          <div
            onClick={() => openMenu()}
            style={{ color: 'black', cursor:'pointer', marginTop: '3rem', height: '3rem', display: 'flex', alignItems: 'center' }}>
            <span style={{ fontSize: '3rem', marginRight: '1rem' }}>{`<`}</span>
            <span>back</span>
          </div>

        {product.productItems &&
          product.productItems.map((module, k) => {
            return renderStructure({item: module, i: k})
          })}
      </MainContent>
    </Container>
  )
}

export default SideMenu

interface Props {
  product: Product
  menuState: boolean
  lessonId: string
  courseId: string
  openMenu: () => void
  currentTime?: number
  wideOpenWidth?: string
}

const Container = styled.div<{ menuState: boolean; isMobile: boolean }>`
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 ${(props) => (props.menuState ? '4rem' : '0px')};
  z-index: 99;
  transition: 0.8s;
`

const Content = styled.div`
  margin: 1rem 0;
`
const MainContent = styled.div<{ menuState: boolean; maxWidth?: string; isMobile: boolean }>`
  ${(props) => (props.menuState ? 'transition-delay: 3500ms;' : '')}
  width: ${(props) => (!props.isMobile ? (props.maxWidth ? props.maxWidth : '100%') : '100%')};
  ${(props) => (props.menuState ?  'opacity: 100%;' : 'opacity: 0%;')}
  transition: 0.8s;
  margin: 1rem 0;
`

const Module = styled.div`
  color: black;
  margin: 2rem 0;
`

const BigModule = styled.div<{isSubmodule: boolean}>`
  color: black;
  margin: 2.5rem 0;
  margin-bottom: 4rem;
  margin-left: ${({isSubmodule}) => isSubmodule ? '1rem' : 0};
`

const ModuleTitle = styled.div<{isSubmodule: boolean}>`
  font-size: ${({isSubmodule}) => isSubmodule ? '1.4rem' : '1.8rem'};
  font-weight: 700;
`
const SubModuleTitle = styled.div`
  font-size: 1.4rem;
  cursor: pointer;
`

const Lesson = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Time = styled.span``

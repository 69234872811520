import {Reducer} from 'redux'
import * as t from './types'
import {Languages, LanguagesSpecializations} from '../../types/rest'
import {LOGIN, RENEW_TOKEN} from '../login/types'

export const initialState: t.ApplicationState = {
  title: '',
  devices: [],
  selectedDevices: {},
  language: (localStorage.getItem('language') as Languages) || 'it',
  languageSpec: (localStorage.getItem('languageSpec') as LanguagesSpecializations) || 'it_IT'
}

type ApplicationStateReducer = Reducer<t.ApplicationState, t.ApplicationStateActionTypes>

const applicationStateReducer: ApplicationStateReducer = (
  state = initialState,
  action: t.ApplicationStateActionTypes
): t.ApplicationState => {
  switch (action.type) {
    case t.SET_TITLE:
      return {
        ...state,
        title: action.payload
      }
    case t.SET_DEVICES:
      return { ...state, devices: action.payload }
    case t.SET_SELECTED_DEVICES:
      return {
        ...state,
        selectedDevices: { ...state.selectedDevices, ...action.payload }
      }
    case RENEW_TOKEN:
    case LOGIN:
      return {
        ...state,
        language: action.payload.user.language.split('_')[0] as Languages,
        languageSpec: action.payload.user.language
      }
    default:
      return state
  }
}

export default applicationStateReducer

import { Reducer } from 'redux'
import * as t from './types'

export const initialState: t.MessagesState = []

type MessagesReducer = Reducer<t.MessagesState, t.MessagesActonTypes>

const messagesReducer: MessagesReducer = (state = initialState, action: t.MessagesActonTypes): t.MessagesState => {
  switch (action.type) {
    case t.INITIALIZE_MESSAGES:
      return action.payload
    case t.meeting_got_new_message:
      return [...state, action.payload]
    case t.meeting_message_deleted:
      return state.map((m) => (m.id === action.payload ? { ...m, deleted: true } : m))
    default:
      return state
  }
}

export default messagesReducer

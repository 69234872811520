const messageByType = {
  required: 'Obbligatorio',
  pattern: 'Non rispetta il pattern',
  maxLength: 'Superato il limite di caratteri'
}
export const getErrorMessage = (errors: any, name: string, message: string) => {

  // controllo che nell'obj errors siano presenti più livelli e di conseguenza riassegno il livello corretto ad errors
  if (name.includes('.')) {
    const newName = name.split('.')
    const nameLength = newName.length
    if (newName.length > 1) {
      errors = errors[newName[nameLength-2]]
      name = newName[nameLength-1]
    }
  }
  // @ts-ignore
  if (!errors[name]) {
    return message
  }
  // @ts-ignore
  if (!errors[name].type) return message
  // Nel caso di validatori custom il type è sempre validate e la discrimante è nel message
  if (errors[name].type === 'validate') {
    // @ts-ignore
    if (!messageByType[errors[name].message]) {
      return message
    } else {
      // @ts-ignore
      return messageByType[errors[name].message]
    }
  }
  // Altrimenti la discriminante è nel campo type per i validatori di default
  // @ts-ignore
  if (!messageByType[errors[name].type]) {
    // @ts-ignore
    console.log('******** NEW ERROR TYPE, field: ', name, 'type: ', errors[name].type)
    return message
  }

  // @ts-ignore
  return messageByType[errors[name].type]
}

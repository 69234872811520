import adapter from 'webrtc-adapter'
import EventEmitter from 'events'
import Videoroom from './videoroom'
import JanusLib, { JanusJS } from './libs/janus'

const delay = (millis: number) => new Promise((resolve) => setTimeout(resolve, millis))

class Janus extends EventEmitter {
  janus: JanusJS.Janus | null
  constructor() {
    super()
    this.janus = null
  }

  init(uri: string, turn: TurnServer, debug = false): Promise<void> {
    const initJanus = (): Promise<void> => {
      return new Promise((resolve, reject) => {
        JanusLib.init({
          debug,
          // eslint-disable-next-line
          dependencies: JanusLib.useDefaultDependencies({ adapter }),
          callback: () => {
            const initOpts: any = {
              server: uri,
              iceServers: [
                { urls: `stun:${turn.host}`, credential: turn.password, username: turn.user },
                { urls: `turn:${turn.host}`, credential: turn.password, username: turn.user }
              ],
              success: () => {
                resolve()
              },
              error: (error: any) => {
                this.emit('janus-error', error)

                reject(error)
              }
            }
            this.janus = new JanusLib(initOpts)
          }
        })
      })
    }

    return new Promise(async (resolve, reject) => {
      if (this.janus) return resolve()

      if (!window.RTCPeerConnection) {
        return reject('No WebRTC support')
      }

      for (let i = 0; i < 3; i++) {
        try {
          debug && console.log(`Janus init: Attempt n. ${i + 1}`)
          await initJanus()
          resolve()
          break
        } catch (e) {
          console.error(e)
          await delay(2000)
        }
      }

      return reject(new Error('Could not initialize Janus'))
    })
  }

  async getVideoroom(opts: GetVideoRoomOptions = {}): Promise<Videoroom> {
    if (!this.janus) throw new Error('Janus::getVideoroom janus was not initialized')
    let videoroom = new Videoroom(this.janus)
    await videoroom.init(opts)

    return videoroom
  }

  async destroy(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.janus) return reject('no janus')

      this.janus.destroy({
        unload: true,
        cleanupHandles: true,
        success: () => {
          this.janus = null
          resolve()
        }
      })
    })
  }

  attachMediaStream(element: HTMLMediaElement | null, stream: MediaStream) {
    if (!element) {
      console.error('Element is null, could not attach stream')
      return
    }
    JanusLib.attachMediaStream(element, stream)
  }

  isExtensionEnabled() {
    return JanusLib.isExtensionEnabled()
  }

  listDevices(): Promise<MediaDeviceInfo[]> {
    return new Promise((resolve) => JanusLib.listDevices(resolve))
  }

  isConnected() {
    return this.janus ? this.janus.isConnected() : false
  }

  randomString(len: number) {
    return JanusLib.randomString(len)
  }
}

export default Janus

export { Videoroom }
export { JanusLib }
export * from './videoroom/types'
export * from './common/types'
export * from './libs/janus'
//region Types

export interface TurnServer {
  host: string
  user: string
  password: string
}

export interface GetVideoRoomOptions {
  subscriberAnswerMediaAudioSend?: boolean
  subscriberAnswerMediaVideoSend?: boolean
}

//endregion

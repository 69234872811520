import React, { useEffect, useState, useMemo } from 'react'
import { User } from '../../../../types/rest'
import styled, { css } from 'styled-components'
import conf from '@configuration'
import { ReactComponent as ArrowDown } from '../../../../images/icons/arrowdown.svg'
import { logout } from '@store/actions'
import { useNavigate } from 'react-router'
import AvatarTest from '../../../../images/defaultAvatar.svg'
import { getTranslation } from '@store/selectors'
import { useDispatch, useSelector } from 'react-redux'
import Camera from "../../../../images/icons/camera.svg";
import useMediaQuery from "../../../../hooks/useMediaQuery";
import countries from '../../../../constants/countries.json'
import {small} from "../../../../constants";
type Props = {
  user: User
  hide: Function
  on: boolean
  open: Function
  top: number
}
const UserInfo: React.FC<Props> = ({ user, hide, on, open, top }) => {
  const translation = useSelector(getTranslation)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isMobile = useMediaQuery(`(max-width: ${small})`)
  const [countryName,setCountryName] = useState('')
  const initials: string = useMemo(() => {
    if (!user.name || !user.surname) return user.email.substring(0, 2)
    else return `${user.name[0]}${user.surname[0]}`
  }, [user])

    useEffect(()=>{
        countries.map((c)=> {
            if (c.code === user.country) setCountryName(c.name)
        })
    },[user])

  return (
    <Container>
      <Avatar onClick={() => (on ? hide() : open())} withOverlay={Boolean(user.avatar)}>
        {user.avatar ? (
          <img src={user.avatar ? `${conf.baseUrl}${user.avatar}` : AvatarTest} alt="avatar" />
        ) : (
          <Initials>{initials}</Initials>
        )}
      </Avatar>
      <ArrowDown
        onClick={() => (on ? hide() : open())}
        style={{ placeSelf: 'center', width: '1.5rem', fill: 'var(--primary)', cursor: 'pointer' }}
      />
      {on && (
        <div onClick={() => hide()}>
          <DropDown onClick={(e) => e.stopPropagation()} top={top}>
            <AvatarBox>
                <BigAvatar>
                    <img src={user.avatar ? `${conf.baseUrl}${user.avatar}` : AvatarTest} alt="" />
                </BigAvatar>
                <Icon isMobile={isMobile}>
                    <img src={Camera} alt="" />
                </Icon>
            </AvatarBox>
            <UserSpecs>
              <InfoRow style={{ fontWeight: 600, fontSize: 'var(--title-h2-mobile)' }}>
                {user.name} {user.surname}
              </InfoRow>
              <InfoRow style={{ fontWeight: 300, color: '#7c6eb0', textTransform: 'none', fontSize: 'var(--title-h2-mobile)' }}>{user.email}</InfoRow>
              <InfoRow style={{ fontWeight: 300 , fontSize: 'var(--title-h2-mobile)' }}>
                  <>
                      {countryName}
                      {user.cap ? `, ${user.cap}` : ''}
                  </>
              </InfoRow>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Button
                  onClick={() => {
                    hide()
                    navigate('/personalarea')
                  }}>
                  {translation.PAGES.personalArea.manageProfile}
                </Button>
              </div>
            </UserSpecs>
            <div
              style={{ color: '#000000', marginTop: '1.5rem', cursor: 'pointer' }}
              onClick={() => {
                dispatch(logout())
                hide()
                navigate('/')
              }}>
              <Logout> {translation.PAGES.personalArea.logout}</Logout>
            </div>
          </DropDown>
        </div>
      )}
    </Container>
  )
}

export default UserInfo

//region Style
const Container = styled.div`
  height: 2.3rem;
  display: flex;
  justify-content: space-between;  
`
// grid-template-columns: 1fr auto;

const Avatar = styled.div<{ withOverlay: boolean }>`
  display: grid;
  border-radius: 50%;
  position: relative;
  aspect-ratio: 1 / 1;
  height: 2.3rem;
  overflow: hidden;
  font-size: 1rem;
  cursor: pointer;
  & img {
    position: absolute;
    left: 50%;
    top: 50%;
    place-self: center;
    width: 100%;
    height: auto;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  ${(props) =>
    props.withOverlay
      ? css`
          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0.4;
            background-color: var(--primary);
          }
        `
      : ''};
`

const Initials = styled.div`
  display: grid;
  place-items: center;
  font-family: SF Pro Display, sans-serif;
  border-radius: 50%;
  border: 0.2rem var(--primary) solid;
  position: relative;
`

const DropDown = styled.div<{ top: number }>`
  transition-delay: 0.2s;
  transition: 1s;
  width: 400px;
  position: absolute;
  z-index: 99999;
  padding: 20px;
  background-color: #ffffff;
  top: ${(props) => props.top}px;
  left: calc(50% - 200px);

  border-radius: 5px;
`

const AvatarBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 30px;
  border-bottom: 1px solid #b6b6b6;
`

const BigAvatar = styled.div`
  width: 40%;
  border-radius: 50%;
  aspect-ratio: 1/1;
  overflow: hidden;
  display: flex;
  position: relative;
  & img {
    justify-self: center;
    align-self: center;
    min-width: 100%;
  }
`

const UserSpecs = styled.div`
  width: 100%;
  color: black;
  font-size: 1.5rem;
  & h4 {
    margin: 1rem 0;
  }
  border-bottom: 1px solid #b6b6b6;
`

// button

const Button = styled.div`
  width: 170px;
  border: 2px solid var(--primary);
  border-radius: 6px;
  font-size: 1rem;
  color: #fff;
  padding: 0.8rem 1.5rem;
  text-align: center;
  margin: 2rem;
  cursor: pointer;
  background-color: var(--primary);
  transition: 0.4s;
  &:hover {
    color: var(--primary);
    transition: 0.2s;
    background-color: transparent;
  }
`

const Logout = styled.div`
  transition: 0.2s;
  color: #000000;
  font-size: var(--title-h2-mobile);
  &:hover {
    color: var(--primary);
    transform: translate(5px, 0);
    transition: 0.4s;
  }
`
const InfoRow = styled.h4`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const Icon = styled.div<{isMobile: boolean }>`
  cursor: pointer;
  position: absolute;
  bottom: 30px;
  left: 110px;
  background-color: var(--primary);
  aspect-ratio: 1/1;
  ${({ isMobile }) => (isMobile ? 'width: 30px;' : 'width: 39px;')}
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 99;
  transition: 0.2s;
  & img {
    height: 100%;
  }
  &:hover {
    transform: scale(1.1);
    transition: 0.2s;
  }
`

//endregion
import { useCallback } from 'react'
import { useNavigate } from 'react-router'
import {Live, Product, ProductItem, Recommended} from 'types/rest'

function useProductNavigate() {
  const navigate = useNavigate()

  const navigateToProduct = useCallback(
    (item: Product | Recommended | ProductItem, watch?: boolean) => {
      if(watch){
        if (
            ((item as Product)?.productItems[0]?.children[0]?.live as Live)?.id ||
            ((item as Product)?.productItems[0]?.live as Live)?.id ||
            ((item as ProductItem)?.live as Live)?.id

        ) {

          if (((item as Product)?.productItems[0]?.live as Live)?.interactive) {
            navigate(`/meetings/${item.slug}`)
          } else {
            navigate(
                `/live/${
                    (item as Product)?.productItems[0].children.length === 0
                        ? (item as Product).productItems[0].slug
                        : (item as Product).productItems[0].children[0].slug
                }`
            )
          }

        }
      }
      if(!item.type && (item as any).productType ) item.type = (item as any).productType
      switch (typeof item.type === 'string' || item.type instanceof String ? item.type : item.type?.label) {
        case 'podcast':
        case 'course':
          let goTo = `/courses/`
          const navigationId = (item as ProductItem).productSlug || (item as ProductItem).product
          if (navigationId) {
            goTo += `${navigationId}/lessons/`
          }
          goTo += item.slug || item.id
          goTo += `${watch ? '/watch' : ''}`
          navigate(goTo)
          break
        case 'masterclass':
          navigate(`/classes/${(item as ProductItem).productSlug  ? (item as ProductItem).productSlug  : item.slug || item.id}/${watch ? '/watch' : ''}`)
          break
        case 'archive':
          navigate(`/classes/${(item as ProductItem).productSlug ? (item as ProductItem).productSlug  : item.slug || item.id}/${watch ? '/watch' : ''}`)
          break
        case 'website':
          navigate(`/${item.slug}`)
          break
        default:
          navigate(`/notFound`)
      }
    },
    [navigate]
  )

  return navigateToProduct
}
export default useProductNavigate

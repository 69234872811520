import { Device } from '../../libs/janus'
import { LanguagesSpecializations, Languages } from '../../types/rest'
import { LoginAction, RenewTokenAction } from '../login/types'

export const SET_TITLE = 'SET_TITLE'
export const SET_DEVICES = 'SET_DEVICES'
export const SET_SELECTED_DEVICES = 'SET_SELECTED_DEVICES'

//region Types

export type Settings = {
  audio?: Device
  webcam?: Device
  microphone?: Device
}

export interface ApplicationState {
  title: string
  devices: Device[]
  selectedDevices: Settings
  language: Languages
  languageSpec: LanguagesSpecializations
}

//endregion

//region Actions

export interface SetTitleAction {
  type: typeof SET_TITLE
  payload: string
}

export interface SetDevicesAction {
  type: typeof SET_DEVICES
  payload: Device[]
}

export interface SetSelectedDevicesAction {
  type: typeof SET_SELECTED_DEVICES
  payload: Settings
}

export type ApplicationStateActionTypes =
  | SetTitleAction
  | SetDevicesAction
  | SetSelectedDevicesAction
  | LoginAction
  | RenewTokenAction

//endregion

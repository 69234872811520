import React, {useCallback, useLayoutEffect, useRef, useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {
  faClosedCaptioning,
  faExpand,
  faMinimize,
  faPause,
  faPlay,
  faVolumeMute,
  faVolumeUp
} from "@fortawesome/free-solid-svg-icons"
import styled from "styled-components"
import {formatTime} from "./helpers"
import {AudioTracksInfo} from './VideoPlayer'
import Navigate from "../../../images/icons/navigation_menu_icon.svg"
import useMediaQuery from "../../../hooks/useMediaQuery";
import {small} from "../../../constants";
import Hls from "hls.js";


type Props = {
  playerRef: any
  isVideoPaused: boolean
  setIsVideoPaused: (isPaused: boolean) => void
  duration: string
  secondsDuration: number
  togglePlay: () => void
  toggleFullscreen: () => void
  datetimeElapsed: string
  timeElapsed: string
  showVideoCommands: boolean
  currentTime: number
  captions: AudioTracksInfo[]
  setShowLoading: () => void
  setPlayInline?: ()=>  void
  isInline: boolean
  openMenu?: Function
  isMenuOpen: boolean
  areCaptionReady: boolean
}

export const VideoPlayerControls: React.FC<Props> = (props) => {

  const {
    areCaptionReady,
    playerRef,
    isVideoPaused,
    setIsVideoPaused,
    duration,
    secondsDuration,
    togglePlay,
    toggleFullscreen,
    datetimeElapsed,
    timeElapsed,
    showVideoCommands,
    currentTime,
    captions,
    setShowLoading,
    openMenu,
    isMenuOpen
  } = props

  const seekRef = useRef<any>(null)
  const progressRef = useRef<any>(null)
  const [seekTooltipLeft, setSeekTooltipLeft] = useState<string>('0px')
  const [dataSeek, setDataSeek] = useState<number>(0)
  const [dataSeekDisplayed, setDataSeekDisplayed] = useState<string>('00:00')
  const [isMuted, setIsMuted] = useState<boolean>(!Hls.isSupported())
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false)
  const [captionsMenuOpened, setCaptionsMenuOpened] = useState<boolean>(false)

  const isMobile = useMediaQuery(`(max-width: ${small})`)

  const skipAhead = useCallback((event: any) => {
    if (!playerRef.current || !progressRef.current) return
    const skipTo = event.target.value
    playerRef.current.currentTime = skipTo
    progressRef.current.value = skipTo
    event.target.value = skipTo
    setShowLoading()
  }, [playerRef, setShowLoading])


  const openCaptionsMenu = useCallback(() => {
    setCaptionsMenuOpened((opened) => !opened)
  }, [setCaptionsMenuOpened])

  const fullScreenHandler = useCallback(async () => {
    setIsFullScreen((isFullScreen) => !isFullScreen)
  }, [setIsFullScreen])

  const handleChangeCaptions = useCallback((index: number, disable: boolean = false) => {
    playerRef.current && [...playerRef.current.textTracks].forEach((tt: TextTrack) => {
      tt.mode = 'disabled'
    })
    if (!disable) {
      playerRef.current.textTracks[index].mode = 'showing'
    }
    setCaptionsMenuOpened(false)
  }, [playerRef, setCaptionsMenuOpened])

  useLayoutEffect(() => {
    document.addEventListener('fullscreenchange', fullScreenHandler)
    document.addEventListener('webkitfullscreenchange', fullScreenHandler)
    document.addEventListener('mozfullscreenchange', fullScreenHandler)
    document.addEventListener('MSFullscreenChange', fullScreenHandler)
  }, [fullScreenHandler])


  return !isMenuOpen ? (
    <VideoControls isMobile={isMobile} id="video-controls" showVideoCommands={showVideoCommands}>
      <VideoProgress>
        <progress id="progress-bar" ref={progressRef} value={currentTime} onChange={() => {
          secondsDuration === currentTime && setIsVideoPaused(true)
        }} max={secondsDuration}></progress>
        <Seek id="seek" ref={(seek) => {
          if (!seek || seekRef.current) return
          seekRef.current = seek
          seekRef.current.addEventListener('mousemove', (event: any) => {
            const skipTo = (event.offsetX / event.target.clientWidth) * event.target.max
            setDataSeek(skipTo)
            const t = formatTime(skipTo)
            setDataSeekDisplayed(`${t.minutes}:${t.seconds}`)
            setSeekTooltipLeft(`${event.offsetX}px`)
          })
        }} value={currentTime} onChange={skipAhead} data-seek={dataSeek} min={0} max={secondsDuration} type="range"
              step={1}/>
        <SeekTooltip id="seek-tooltip" style={{left: seekTooltipLeft}}>{dataSeekDisplayed}</SeekTooltip>
      </VideoProgress>
      <BottomControls>
        <LeftControls id={"left-controls"}>
          { openMenu && IsFullScreen() && <CustomButton style={{marginRight: isMobile ? '5px' : '25px', width:isMobile ? '27px' : '44px',height: isMobile ? '27px' : '44px'}} data-title={'Navigate'}
                onClick={() => openMenu()}
           data-offset="{'top': 15}" ><img src={Navigate} alt="custom" style={{ marginRight: '23px',height: isMobile ? '27px' : '44px'}}/></CustomButton>}
          {/*bottone per indice corso*/}
          <button data-title={isVideoPaused ? "Play" : "Pause"} id="play" style={{height: '21px', width:'18px'}}  onClick={togglePlay}>
            <FontAwesomeIcon icon={isVideoPaused ? faPlay : faPause} color={'white'} style={{height: '18px', width: '21px'}}/>
          </button>

           <VolumeControls isMobile={isMobile}>
            <button data-title={!isMuted ? "Mute" : "Unmute"} id="volume-button"
                    style={{height: '19px', marginRight: '24px'}} onClick={() => {
              if (!playerRef?.current) return
              const video = playerRef?.current
              video.muted = !video.muted
              if (video.muted) {
                setIsMuted(true)
              } else {
                setIsMuted(false)
              }
            }}>
              <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} color={'white'} style={{height: '100%'}}/>
            </button>
            {!isMobile && <input id="volume" name="volume" onChange={(event) => {
              let target = event.target
              if (!playerRef?.current) return
              const video = playerRef?.current
              if (parseFloat(target.value) !== 0) {
                video.muted = false
                setIsMuted(false)
              }
              playerRef.current.volume = parseFloat(target.value) === 1 ? 1.00 : target.value
              const min: number = parseFloat(target.min)
              const max: number = parseFloat(target.max)
              const val: number = parseFloat(target.value)

              target.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'
            }} type="range" max="1" min="0" step="0.01" style={{
              height: '4px',
              backgroundImage: 'linear-gradient(var(--primary), var(--primary))',
              backgroundColor: '#474545',
              backgroundRepeat: 'no-repeat'
            }}/>}
          </VolumeControls>
        </LeftControls>
        <div className="time" style={{height: 'var(--player-icons-height)', display: 'flex', alignItems: 'center', placeSelf: 'center'}}>
          <time id="time-elapsed" dateTime={datetimeElapsed}
                style={{fontSize: '16px'}}>{timeElapsed}</time>
          <span style={{fontSize: '16px'}}> / </span>
          <time id="duration" style={{fontSize: ' 16px'}}>{duration}</time>
        </div>
        <div style={{placeSelf: "end", display: 'flex', alignItems: "center", height: '100%'}} className="right-controls">

          {!!captions?.length && areCaptionReady && <>
            <button data-title={"Captions"} onClick={openCaptionsMenu} id="captions-button"
                    style={{placeSelf: 'center',marginRight: isMobile ? '0' : '18px', height: '24.5px', width: '32px'}}>
              <FontAwesomeIcon icon={faClosedCaptioning} color={'white'} style={{height: '100%'}}/>
            </button>
            {captionsMenuOpened &&
              <CaptionsMenu>
                {captions.length && captions.map(({src, srcLang, label}, index) => {
                  return <MenuItem key={srcLang} style={{ color: [...playerRef.current.textTracks].find((track)=> track.label === label && track.mode === 'showing') ? 'var(--primary)' : '#fff'}} onClick={() => handleChangeCaptions(index)}>{label}</MenuItem>
                })}
                <MenuItem key={'disable'} onClick={() => handleChangeCaptions(0, true)}>{'No captions'}</MenuItem>
              </CaptionsMenu>
            }
          </>}
          <button data-title={isFullScreen ? "Exit full screen" : "Full screen"}
                  style={{marginRight: 0, height: '22px', width: '25px'}} onClick={()=>{
                    toggleFullscreen()
                  }}
                  id="fullscreen-button">
            <FontAwesomeIcon icon={isFullScreen ? faMinimize : faExpand} color={'white'} style={{height: '100%'}}/>
          </button>
        </div>
      </BottomControls>
    </VideoControls>
  ) : <></>
}

// region Style

const VideoControls = styled.div<{ showVideoCommands: boolean, isMobile: boolean }>`
  margin: auto;
  padding: 15px 20px;
  width: auto;
  border-radius: 17px;
  transition: opacity 0.5s ease;
  opacity: ${({showVideoCommands}) => showVideoCommands ? 1 : 0};
  & button{
    ${({showVideoCommands}) => !showVideoCommands && 'pointer-events: none;'}  
  }
  position: absolute;
  bottom: 31px;
  max-width: calc(var(--max-content-width) - 50px);
  right: 25px;
  left: 23px;
  z-index: 3;
  background: #0f0f0f9e;
  .hide {
    opacity: 0;
    pointer-events: none;
  }
`

const VideoProgress = styled.div`
  position: relative;
  height: 5px;
  margin-bottom: 10px;
  
  progress {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 2px;
    width: 100%;
    height: 6px;
    pointer-events: none;
    position: absolute;
    top: 0;
  }

  progress::-webkit-progress-bar {
    background-color: #474545;
    border-radius: 2px;
  }

  progress::-webkit-progress-value {
    background: var(--primary);
    border-radius: 2px;
  }

  progress::-moz-progress-bar {
    border: 1px solid var(--primary);
    background: var(--primary);
  }
`

const Seek = styled.input`
  position: absolute;
  top: 0;
  width: 100%;
  cursor: pointer;
  margin: 0;
  
  :hover+#seek-tooltip {
    display: block;
  }
`

const SeekTooltip = styled.div`
  display: none;
  position: absolute;
  top: -50px;
  margin-left: -20px;
  font-size: 12px;
  padding: 3px;
  content: attr(data-title);
  font-weight: bold;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
`

const BottomControls = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  height: var(--player-icons-height);
  flex-basis: calc(100% / 3);
`

const LeftControls = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  height: var(--player-icons-height);
  & button {
  margin-right: 0;
  padding:  0 !important;
  }
  
`

const VolumeControls = styled.div<{isMobile: boolean}>`
  display: flex;
  align-items: center;
  margin-right: ${({isMobile})=> isMobile ? '0' : '10px'};
  margin-left: ${({isMobile})=> isMobile ? '0' : '23px'};
  input[type='range'] {
    width: 100px;
    -webkit-appearance: none;
  height: 4px;
  border-radius: 2px;
  }
  
  
  input[type=range]::-webkit-slider-runnable-track  {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
`

const CaptionsMenu = styled.div`
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5));
  position: absolute; 
  transform: translateY(-100%);
  top: 20px;
  right: 50px;
  max-height: 200px;
  width: 150px;
  overflow: auto;
`

const MenuItem = styled.div`
  color: #fff;
  padding: 10px;
  cursor: pointer;
`

const CustomButton = styled.button`
  &:hover{
    &::before{
       content: attr(data-title);
       right: -110%;
    }
  }
`


// endregion

function IsFullScreen() {
  return !!(document.fullscreenElement || (document as any).mozFullScreenElement || (document as any).webkitFullscreenElement || (document as any).msFullscreenElement)
}

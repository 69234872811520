import styled from 'styled-components'
import React from 'react'
import { useSelector } from 'react-redux'
import {getLanguage, getTranslation} from '@store/selectors'
import conf from '@configuration'
import { Thumbnail } from '@components/common'
import useProductNavigate from "../../hooks/useProductNavigate";

interface Props {
  data: any
  bannerColor?: string
  hide?: Function
}

/** Card da inserire nello slider per la presentazione dei format */
const SearchCard: React.FC<Props> = ({ data, hide }) => {
  const language = useSelector(getLanguage)
  const navigateTo = useProductNavigate()
  const translation = useSelector(getTranslation)

  return (
    <Container
      onClick={() => {
        navigateTo(data)
         hide && hide()
      }}>
      <Thumbnail style={{ aspectRatio: '8/5', marginBottom: '13px' }} src={`${conf.baseUrl}${data.thumbnail}`} />
      <BannerContainer >
      </BannerContainer>
      <InfoBox>
          {(data.type.label === 'course' && <Badge>{translation.common.buttons.course}</Badge> )}
          {(data.type.label === 'masterclass' && <Badge>{translation.entities.AuthorContent}</Badge> )}
          <div>
            <span style={{fontSize: '19px', color: '#fff'}} >
                 {data?.translations[language] ? data.translations[language].surtitle : ''}
            </span>
          </div>
        <Title>{data.translations[language].title}</Title>
          {/*da Mockup non sono presenti nome e cognome autore nella searchCard*/}
          {/*<BannerContainer >
              {data.authors &&
                  data?.authors?.map((auth: any, i: number) => (
                      <Banner style={{ fontSize: "19px" , fontWeight: '400'}} key={i}>
                          {auth.name} {auth.surname}{i !== data?.authors.length - 1 && ', '}
                      </Banner>
                  ))}
          </BannerContainer>*/}
      </InfoBox>
    </Container>
  )
}

export default SearchCard

//region Style

const Container = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  max-width: 426px;
  min-width: 20rem;
  height: fit-content;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    transform: translateY(-5px);
    transition: 0.3s;
  }
`

const Title = styled.p`
  font-size: var(--list-title-fs);
  font-weight: 700;
  margin: 0.5rem 0;
  word-break: break-word;
  max-width: 80%; 
`

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
`

const BannerContainer = styled.div`
  z-index: 9999;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.5rem;
  color: #000000;
`

/*const Banner = styled.div<{ color?: string }>`
  padding: 1px 0;
  color: ${(prop) => prop.color};
  border-radius: 5px;
  font-family: Helvetica Neue;
  font-size: 1.2rem;
  font-weight: 300;
`*/

/*const Duration = styled.div`
  color: grey;
`*/

const Badge = styled.div`
  background-color: #fff;
  color: var(--primary);
  margin: 0.5rem 0;
  width: fit-content;
  text-align:center;
  padding:0.3rem;
  font-size:var(--badge-font-size);
  font-weight:600;
`

//endregion

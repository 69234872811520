import { Languages } from 'types/rest'

const getTranslation = async (language: Languages) => {
  try {
    return await import(`../translations/${language}.json`)
  } catch (e) {
    return await import(`../translations/en.json`)
  }
}

export default getTranslation

import React, {useCallback, useEffect, useRef, useState} from 'react'
import {PromoAreaButton, VideoPlayer} from '@components/common'
import useRest from '../../hooks/useRest'
import {Product, PromoArea} from '../../types/rest'
import {logger} from '@helpers'
import {useSelector} from 'react-redux'
import {getLanguage, getTranslation} from '@store/selectors'
import styled from 'styled-components'
import conf from '@configuration'
import {ReactComponent as PlayIcon} from '@images/icons/play.svg'
import {ReactComponent as PauseIcon} from '@images/icons/pause.svg'
import useMediaQuery from '../../hooks/useMediaQuery'
import {small} from '../../constants'
import {VideoJsPlayer} from 'video.js'


export const PromoAreaComponent: React.FC<Props> = (props) => {
  const { id, infoType = 'normal', infoText, withoutCta = false  } = props
  const [isAlreadyBought, setIsAlreadyBought] = useState<boolean>(false)
  const [isLoading, setIsLoading]= useState(true)
  const [muted, setMuted] = useState<boolean>(true)

  const player = useRef<VideoJsPlayer | null>(null)
  const language = useSelector(getLanguage)
  const translation = useSelector(getTranslation)
  const { result: promoArea, getById } = useRest<PromoArea>('promo-areas')
  const { get } = useRest<Product>('payments/products', {lazy: true})
  const [ready, setReady] = useState(false)
  const isMobile = useMediaQuery(`(max-width: ${small})`)

  const onPlayerReady = useCallback((videoPlayer: VideoJsPlayer) => {
    if(!videoPlayer) return
    player.current = videoPlayer
  }, [isLoading])
  const onPlayerDispose = useCallback(() => {}, [])

  useEffect(() => {
    getById({ id })
      .then(() => setReady(true))
      .catch((e) => logger.error(e))
  }, [id, getById])
  useEffect(() => {
    if (!promoArea && isAlreadyBought) return
    const lastChunkUrl = promoArea.url?.split('/').pop()
    get().then((res)=>{
      const productBought = (res as unknown as any[])?.find((e)=>e.slug === lastChunkUrl || e.id === lastChunkUrl)
      productBought && setIsAlreadyBought(true)
    })
  }, [get, promoArea, isAlreadyBought])


  return ready && promoArea && (promoArea.video || promoArea.image) ? (
    <Mask>
      <ContentContainer withoutCta={withoutCta} infoPosition={promoArea.textPosition || 'right'} withVideo={!!promoArea.video} isMobile={isMobile} sideBgColor={promoArea.mobileBackgroundColor || '#ffffff'}>
          {(!promoArea.video && !promoArea.mobileVideo) || !ready ? (<BannerThumb infoPosition={promoArea.textPosition || 'right'} isMobile={isMobile}>
            <img
              src={`${conf.baseUrl}${promoArea.image}`}
              alt="thumb"
            />
          </BannerThumb>)
            :
              <div style={{ position: 'relative', width:'100%'}}>
                <VideoPlayer
                  onPlayerReady={onPlayerReady}
                  setIsLoading={(state: boolean) => setIsLoading(state)}
                  onPlayerDispose={onPlayerDispose}
                  entityName="trailers"
                  options={{
                  autoplay: true,
                  controls: false,
                  loop: true,
                  muted: true,
                  fill: true,
                  sources: [
                    {
                      src: `${conf.wowzaUrl}${isMobile ? promoArea.mobileVideo || promoArea.video : promoArea.video}${conf.wowzaOption}`
                    }
                  ]
                  }}
                  style={{
                  margin: '0 auto',
                  zIndex: 1,
                  width: '100%',
                  maxWidth: '1920px',
                  position: 'relative'
                }}
                  videoStyle={{
                  width: '100%',
                  maxWidth: '1920px'
                }}
                  poster={promoArea?.image}
                  />
                {promoArea.video && (
                  <SoundContainer
                    onClick={() => {
                      if (!player.current) return
                      setMuted((prev)=>!prev);
                      (player.current as any).muted = !muted;
                    }}>
                    {muted ? <PlayIcon /> : <PauseIcon />}
                    <div style={{ placeSelf: 'center' }}>{translation?.common?.buttons.sound}</div>
                  </SoundContainer>
                )}
                </div>}
       {promoArea?.translations[language].title  && !withoutCta &&
              <BannerCta infoPosition={promoArea.textPosition || 'right'} withVideo={!!promoArea.video} isMobile={isMobile}>
            <InfoBox infoType={infoType} position={isMobile ? 'center' : (promoArea.textPosition || 'right')}>
              <h2
                style={{
                  fontWeight: 'bold',
                  fontSize: isMobile ? '1.5rem' : 'var(--title-h2)',
                  marginTop: '1rem',
                  color: promoArea?.theme === 'light' ? 'white' : 'black',
                  textAlign: 'left'
                }}>
                {infoText && promoArea?.translations[language]?.title ? promoArea?.translations[language]?.title : ''}
              </h2>
              {infoText && (
                <>
                  <InfoText isMobile={isMobile} style={{textAlign: 'left', lineHeight: '1.4'}} fontColor={promoArea?.theme === 'light' ? 'white' : 'black'}>{promoArea.translations[language]?.description || ''}</InfoText>
                </>
              )}
              {promoArea.action && (
                <ButtonContainer isMobile={isMobile} infoPosition={promoArea.textPosition || 'right'}>
                  <PromoAreaButton promoArea={promoArea}/>
                </ButtonContainer>
              )}
            </InfoBox>
          </BannerCta>}
        </ContentContainer>
    </Mask>
  ) : null
}

type Props = {
  fontColor?: string
  id: string
  infoType?: 'normal' | 'top'
  color?: string
  infoText?: boolean
  title?: string
  renderInside?: (props: { isMobile: boolean; purchased: boolean; promoArea: PromoArea;}) => React.ReactNode
  buttonType?: "secondary-inverted" | "primary" | "secondary" | "ghost" | "ghost-variant" | "promo-area-style"
  withoutCta?: boolean
}

//region Style
const Mask = styled.div`
  position: relative;
  overflow: hidden; 
  width: 100%; 
  margin: 0 auto;  
  max-width: 1920px;  
`
const InfoText = styled.div<{ fontColor?: string, isMobile?: boolean }>`
  color: ${(props) => props.fontColor};
  margin: 0 0 40px 0;
  font-size: ${({isMobile}) => isMobile ? 'var(--parag-body-fs-mobile)' : 'var(--big-parag-fs)'};
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
`
const InfoBox = styled.div<{ infoType: 'normal' | 'top', position: 'right' | 'left' | 'center' }>`
  display: grid;
  ${(props) => (props.infoType === 'normal' ? '' : 'margin-top: 4rem;')};
  grid-row: ${(props) => (props.infoType === 'normal' ? '2' : '1')};
  align-self: center;
  z-index: 2;
  align-items: center;
  ${(props) => (props.position === 'center' ? 'grid-column: 2;' : '')}
  ${(props) => (props.position === 'left' ? 'grid-column: 2;' : '')}
  ${(props) => (props.position === 'right' ? 'grid-column: 2;' : '')}
  text-align: ${(props) => (props.position === 'center' ? 'center' : 'left')};
  width: 100%;
`
const SoundContainer = styled.div`
  position: absolute;
  color: var(--text-over-color);
  bottom: 1rem;
  right: 2rem;
  border: 1px solid var(--text-over-color);
  padding: 0 0.2rem;
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 0.2rem;
  font-size: 0.8rem;
  cursor: pointer;
  z-index: 9;
  & svg {
    place-self: center;
    width: 0.7rem;
    height: 0.7rem;
    fill: #ffffff;
  }
`
const ButtonContainer = styled.div<{infoPosition: 'left' | 'right' | 'center' , isMobile: boolean}>`
  display: flex;
  justify-content:${({infoPosition, isMobile})=> !isMobile ? (infoPosition === 'center' ? 'center':  'start' ) : 'start'};
  width: 100%;
  margin-bottom: 3rem;
`
const ContentContainer = styled.div<{ sideBgColor?: string, isMobile: boolean , withVideo: boolean, infoPosition: 'left' | 'center' | 'right', withoutCta: boolean}>`
  width: 100%;
  max-height:700px;
  max-width: 1920px;
  overflow: hidden;
  background-color: ${({sideBgColor, isMobile, withoutCta}) => isMobile && !withoutCta ? (sideBgColor ? sideBgColor : '#ffffff') : 'transparent'};
  ${({withVideo})=> !withVideo && 'display: flex;'}
  ${({isMobile})=> isMobile ? 'flex-direction: column; ' : ''}
  position: relative;
  align-items: center;
  ${({ isMobile, infoPosition }) => !isMobile &&  infoPosition === 'center' ? 'justify-content: center;' : ( infoPosition === 'left' ? 'justify-content: flex-start' : 'justify-content: flex-end;')}
`
const BannerThumb = styled.div<{isMobile: boolean , infoPosition: 'right' | 'left' | 'center'}>`
  width:100%;
  ${({infoPosition}) => infoPosition === 'left' && 'direction: rtl;'}
  overflow: hidden;
  height: 100%;
  &>img{
    width: 100%;
    ${({isMobile})=> isMobile && 'height: 100%; width: 160%!important; max-width: 160vw;'}
    object-fit: cover!important;
  }
`
const BannerCta = styled.div<{isMobile: boolean, withVideo: boolean, infoPosition: string}>`
  ${({withVideo, infoPosition, isMobile}) => withVideo && !isMobile && `height: 100%; position: absolute;\n  top: 0;\n  ${infoPosition === 'center' ? 'left' : infoPosition}: calc((100vw - var(--max-content-width)) / 2 - 30px);`}
  ${({isMobile})=> isMobile ? "width: 100%; " : "width: 45%;"}
  justify-self: ${({infoPosition})=> infoPosition === 'center' ? 'center' : (infoPosition === 'left' ? 'start' : 'end')};
  order: 0;
  ${({isMobile})=> !isMobile &&  'position: absolute;'}
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-height: min-content;
  @media(min-width: 1340px){
    ${({ infoPosition}) => infoPosition && `${infoPosition === 'left' ? 'margin-left: 60px' : ''}`}
  }
  @media(max-width: 1340px){
    ${({withVideo, infoPosition}) => withVideo && `${infoPosition === 'center' ? 'left' : infoPosition}: 0;`}
  }
`
//endregion

import styled from 'styled-components'
import {VideoCard} from './VideoCard'
import React from 'react'

interface Props {
  title?: string
  data?: any[]
  style?: {
    height?: string
  }
}

/**
 * Componente per mostrare una slider di presentazione
 * @param props.title {string} titolo della sezione
 * @param props.data {any} ???
 * @param props.style.height {string}
 * @constructor
 */
export const Slider: React.FC<Props> = (props) => {
  const { title, data, style } = props

  return (
    <Container title={title} height={style?.height || '25rem'}>
      {title && <Title>{title}</Title>}
      <List data={data ? data.length : 0}>
        {data?.map((_, i) => (
          <VideoCard key={i} videoUrl={_} thumbnail={''} />
        ))}
      </List>
    </Container>
  )
}

const Container = styled.div<{ title?: string; height: string }>`
  height: ${({ height }) => height};
  display: grid;
  gap: 1rem;
  grid-template-rows: ${({ title }) => title && 'max-content'} auto;
`

const Title = styled.div``
const List = styled.div<{ data: number }>`
  display: grid;
  grid-template-columns: repeat(${({ data }) => data}, 1fr);
  gap: 1rem;
`

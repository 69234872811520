import styled from 'styled-components'
import React from "react"
import conf from '@configuration'
import {Product, Recommended} from 'types/rest'
import useProductNavigate from '../../../hooks/useProductNavigate'
import {getLanguage, getTranslation} from '@store/selectors'
import {useSelector} from 'react-redux'
import {Thumbnail} from '@components/common'
import useMediaQuery from "../../../hooks/useMediaQuery"
import {small} from "../../../constants"

interface Props {
  data: any
  categories?: boolean
  wall?: boolean
}

const RecommendedCard: React.FC<Props> = ({ data, categories = false, wall}) => {
  const navigateTo = useProductNavigate()
  const language = useSelector(getLanguage)
  const translation = useSelector(getTranslation)
  const isMobile = useMediaQuery(`(max-width: ${small})`)

  return (
    <>
      {data && (
        <Container isMobile={isMobile} isWall={wall} key={data.id} onClick={() => navigateTo(data as Product | Recommended)}>
          <Thumbnail style={{aspectRatio: '16/10', marginBottom: 'var(--max-distance-slider)'}} src={`${conf.baseUrl}${data.thumbnail}`}/>
          {data.categories &&
            categories && <BannerContainer>
            {data.categories?.map((cat: any, i: number) => (
                <Banner key={i}>
                  {`· ${cat.label.toUpperCase()} `}
                </Banner>
              ))}
          </BannerContainer>}
          {((data.type.label === 'course' || data.type === 'course') && <Badge contentType={(data.type as any).label || data.type}>{translation.common.buttons.course}</Badge> )}
          {((data.type.label === 'masterclass' || data.type === 'masterclass') && <Badge contentType={(data.type as any).label || data.type}>{translation.entities.AuthorContent}</Badge> )}
          {data?.translations && (
              <div>
                {(data as any)?.translations[`${language}`]?.surtitle && <Surtitle>
                  {`${(data as any)?.translations[`${language}`]?.surtitle || ''}`}
                </Surtitle>}
                  <p
                    style={{
                      maxWidth: '80vw',
                      fontSize: '25px',
                      fontWeight: '600',
                      lineHeight: '30px',
                      margin: 0,
                      marginBottom: 'var(--min-distance-slider)'
                    }}>
                  {`${(data as any)?.translations[`${language}`]?.title}  `}
                  </p>
                  {(data as any)?.translations[`${language}`]?.subtitle && <Subtitle >
                  {`${(data as any)?.translations[`${language}`]?.subtitle}  `}
                </Subtitle>}
              </div>
          )}
        </Container>
      )}
    </>
  )
}

export default RecommendedCard

const Container = styled.div<{isWall?: boolean, isMobile?:boolean, width?: any}>`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  ${({isWall})=> !isWall ? 'max-width: 427px;':''}
  width: var(--card-width-responsive);
  @media (max-width: 1024px){
    ${({isWall})=> !isWall ? 'width: calc(var(--card-width-responsive) - 50px);':''}
  }
  height: fit-content;
  position: relative;
  cursor: pointer;
  min-width: ${({isWall, isMobile})=> isWall && isMobile ? '5rem':'15rem'};
  &:hover {
    opacity: 0.7;
  }
`

const Badge = styled.div<{contentType?: string}>`
  width: fit-content;
  background-color: ${({contentType}) =>  contentType === 'course' ? '#fff' : 'var(--primary)'};
  color: ${({contentType}) =>  contentType === 'course' ? 'var(--primary)' : '#fff'};
  font-weight: ${({contentType}) =>  contentType === 'course' ? '600' : '500'};
  display: inline-block;
  padding: 6px 12px;
  line-height: 1;
  text-align:center;
  margin-bottom: var(--max-distance-slider);
  font-size:var(--badge-font-size);
`

const BannerContainer = styled.div`
  z-index: 9999;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.5rem;
  color: #ffffff;
  & > div:first-child {
    padding: 0;
  }
  & > div {
    padding: 0 0.3rem;
  }
`

const Banner = styled.div<{ color?: string }>`
  color: ${(prop) => prop.color};
  border-radius: 5px;
  font-family: Helvetica Neue;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: var(--min-distance-slider);
  
  
  
  
`

const Surtitle = styled.span`
  font-size: var(--surtitle-fs);
  color: #fff;
  margin-bottom: var(--min-distance-slider);
  display: block;
`

const Subtitle = styled.span`
  display: block;
  font-size: var(--subtitle-fs); 
  color: #fff;
`

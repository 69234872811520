import { RootState } from '@store/types'
import { initialState as applicationStateInitial } from './applicationState/reducer'
import { initialState as localizationInitial } from './localization/reducer'
import { initialState as loginInitial } from './login/reducer'
import { initialState as meetingInitial } from './meeting/reducer'
import { initialState as messagesInitial } from './messages/reducer'

const initialState: RootState = {
  applicationState: applicationStateInitial,
  localization: localizationInitial,
  login: loginInitial,
  meeting: meetingInitial,
  messages: messagesInitial
}

export default initialState

import React, { useCallback, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import useVideoToken from '../../../hooks/useVideoToken'
import {useNavigate} from 'react-router'
import {logger} from '../../../helpers'
import Hls from "hls.js"
import useRest from '../../../hooks/useRest'
import conf from '@configuration'
import {Language, Product, ProductItem} from '../../../types/rest'
import {VideoPlayerControls} from "./VideoPlayerControls"
import {formatTime} from "./helpers"
import {faPause, faPlay} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import useMediaQuery from "../../../hooks/useMediaQuery";
import {small} from "../../../constants";
import SideMenu from "../../pages/course/SideMenu";
import notFoundImg from '../../../images/icons/kh-notFound.svg'

interface VideoPlayerProps {
  src?: string
  options: any
  productId?: number
  onPlayerReady?: (player: any) => any
  onPlayerDispose?: (player: any) => any
  time?: number
  background?: string
  style?: React.CSSProperties
  entityName: string
  poster?: string
  entityId?: number
  videoStyle?: React.CSSProperties
  videoClassName?: string
  subtitleCodes?: string[]
  openMenu?: Function
  isMenuOpen?: boolean
  setIsLoading?: Function
  isLoading?: boolean
  isInline?:boolean
  setPlayInline?: ()=> void
  reloadContent?: ()=> void
  item?: ProductItem
  product?:  Product
}

let mouseTimeout: NodeJS.Timeout | null = null
let loadTimeout: NodeJS.Timeout | null = null

/** Componente Wrapper Per la visualizzazzione di video */
export const VideoPlayer: React.FC<VideoPlayerProps> = (props) => {
  const {
    item,
    product,
    options,
    onPlayerReady,
    onPlayerDispose,
    poster,
    style = {},
    entityName,
    entityId,
    productId,
    videoStyle = {},
    subtitleCodes = ['it','en'],
    setIsLoading,
    isLoading,
    isInline,
    setPlayInline,
    reloadContent,
    src
  } = props

  const tokenRef = useRef('')
  const isMobile = useMediaQuery(`(max-width: ${small})`)

  const playerRef = useRef<any>(null)
  const containerRef = useRef<any>(null)
  const playbackAnimationRef = useRef<any>(null)
  const [captions, setCaptions] = useState<AudioTracksInfo[]>([])
  const [isVideoPaused, setIsVideoPaused] = useState<boolean>(false)
  const [showVideoCommands, setShowVideoCommands] = useState<boolean>(true)
  const [showLoading, setShowLoading] = useState<boolean>(true)
  const [duration, setDuration] = useState<string>('00:00')
  const [secondsDuration, setSecondsDuration] = useState<number>(0)
  const [currentTime, setCurrentTime] = useState<number>(0)
  const [timeElapsed, setTimeElapsed] = useState<string>('00:00')
  const [datetimeElapsed, setDatetimeElapsed] = useState<string>('00m 00s')
  const [captionReqDone, setCaptionReqDone] = useState<null | number>(null)
  const [areCaptionReady, setCaptionReady] = useState(false)
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false)
  const [videoUrl, setVideoUrl] = useState('')
  const navigate = useNavigate()

  const {videoToken, notAllowed} = useVideoToken(entityName, productId)

  const {get: getLanguages, results: languages} = useRest<Language>('languages', {lazy: true})
  const {getById: getSubtitles } = useRest<BlobPart>('videos')


  const createCaptions = useCallback(() => {
    if(captionReqDone && ((languages.length >= captionReqDone) || (captionReqDone !== null))) return
    return Promise.all(languages.map((l)=> l.code).map(async (subtitleCode, index) => {
      let res

      try {
        //prendo il file dal server
        res = await getSubtitles({notFound: false, id: entityId, afterPath: `/subtitles/${subtitleCode}`})
      } catch (e) {
        logger.error(e)
      }
      setCaptionReqDone((prev) => prev && prev > index ? prev : index)
      if (!res) return <></>
      const label = languages.find((l: any) => l.code === subtitleCode)?.name || subtitleCode
      //genero l'url
      const url = window.URL.createObjectURL(
        new Blob([res], {
          type: 'text/vtt'
        })
      )
      setCaptions((oldCaptions) => {
        if(oldCaptions.find((captionElem)=> captionElem.srcLang === subtitleCode))return oldCaptions
        else return oldCaptions.concat([{src: url, srcLang: subtitleCode, label}])
      })
      setTimeout(()=> setCaptionReady(true), 1000)
    }))
  }, [languages, getSubtitles, entityId, captionReqDone, src])

  const togglePlay = useCallback(() => {
    const video = playerRef.current
    setIsVideoPaused((isVideoPaused) => {
      playbackAnimationRef.current.animate([
        {
          opacity: 1,
          transform: "scale(1)",
        },
        {
          opacity: 0,
          transform: "scale(1.3)",
        }], {
        duration: 500,
      })

      if (isVideoPaused) {
        video.play()
        return false
      } else {
        video.pause()
        return true
      }
    })
  }, [setIsVideoPaused])
  const showCommands = useCallback(() => {
    setShowVideoCommands(true)
    mouseTimeout && clearTimeout(mouseTimeout)
    mouseTimeout = setTimeout(() => setShowVideoCommands(false), 2500)
  }, [setShowVideoCommands])

  const initializeVideoDuration = useCallback(() => {
    if (isNaN(playerRef.current?.duration)) return
    const videoDuration = Math.round(playerRef.current.duration)
    setSecondsDuration(videoDuration)
    const time = formatTime(videoDuration)
    setDuration(`${time.minutes}:${time.seconds}`)
    if(!Hls.isSupported()) {
      loadTimeout && clearTimeout(loadTimeout)
      loadTimeout = null
      setShowLoading(false)
      setIsLoading && setIsLoading(false)
      playerRef.current.muted = true
      playerRef.current.play()
    }
    playerRef.current && [...playerRef.current.textTracks].forEach((tt: TextTrack) => {
      tt.mode = 'disabled'
    })
  }, [setDuration])

  useEffect(() => {
    if(languages.length !== 0) return
    getLanguages().catch((e: any) => logger.error(e))
  }, [getLanguages, languages])

  const updateTimeElapsed = useCallback(() => {
    const time = formatTime(Math.round(playerRef.current.currentTime))
    setTimeElapsed(`${time.minutes}:${time.seconds}`)
    setDatetimeElapsed(`${time.minutes}m ${time.seconds}s`)
    setCurrentTime(Math.floor(playerRef.current.currentTime))
  }, [setTimeElapsed, setDatetimeElapsed, setCurrentTime])


/*  utilizzare la ref del container*/
  const toggleFullscreen = useCallback(async ()=>{
    if(Hls.isSupported()){
      if (document.fullscreenElement) {
        await document.exitFullscreen()
      } else if ((document as any).webkitFullscreenElement) {
        // Need this to support Safari
        (document as any).webkitExitFullscreen()
      } else if (containerRef.current.webkitRequestFullscreen) {
        // Need this to support Safari
        containerRef.current.webkitRequestFullscreen()
      } else {
        containerRef.current.requestFullscreen()
      }

    }else{
      if (playerRef.current.requestFullscreen) playerRef.current.requestFullscreen()
      else if (playerRef.current.webkitRequestFullscreen) playerRef.current.webkitRequestFullscreen()
      else if (playerRef.current.mozRequestFullScreen) playerRef.current.mozRequestFullScreen()
      else if (playerRef.current.msRequestFullscreen) playerRef.current.msRequestFullscreen()
      else if (playerRef.current.webkitEnterFullscreen) playerRef.current.webkitEnterFullscreen()
      togglePlay()
    }

  },[containerRef.current, playerRef.current])



  useEffect(() => {
    if(captionReqDone === subtitleCodes.length - 1 || isLoading) return
    setCaptions([])
    try{
      createCaptions()
    }catch(e){
      logger.error(e)
    }
  }, [createCaptions, isLoading, src])

  useEffect(() => {
    if (notAllowed) navigate('/')
  }, [notAllowed, navigate])

  useEffect(() => {
    tokenRef.current = videoToken
  }, [videoToken])

  useEffect(() => {
    if (!videoToken) return
    if (!playerRef.current) return
    const video = playerRef.current
    const hls = new Hls()
    if (options.sources && videoUrl.split('?')[0].replace('mp3:','') !== options.sources[0].src.split('?')[0]) {
      options.sources.forEach(
        (source: any) => {
          const entityUrlChunks = source.src.split('/')
          if (entityUrlChunks[entityUrlChunks.length - 2].includes('.mp3')) {
            entityUrlChunks[entityUrlChunks.length - 2] = `mp3:${entityUrlChunks[entityUrlChunks.length - 2]}`
          }
          const newSource = entityUrlChunks.join('/')
          source.src = (newSource.includes('?') ? newSource + '&' : newSource + '?') + `token=${videoToken}`
          setVideoUrl(source.src)
          if(Hls.isSupported()){
            hls.loadSource(source.src)
          }else{
            video.setAttribute('src', source.src)
          }

          if (playerRef.current.currentTime) {
            if (reloadContent) reloadContent()
            if (!isInline && setPlayInline) setPlayInline()

          }
        }
      )
      hls.attachMedia(video)
    }
  }, [options.sources, videoToken, options.autoplay, item, options])

  useEffect(() => {
      const video = playerRef.current
      if (!video ) return
      onPlayerReady && onPlayerReady(video)

  }, [onPlayerReady, playerRef.current])

  useEffect(() => {
    return () => {
      onPlayerDispose && onPlayerDispose(playerRef.current);
        playerRef.current && [...playerRef.current.textTracks].forEach((tt: TextTrack) => {
        tt.mode = 'disabled'
      })
      setCaptionReqDone(null)
      setCaptions([])
      setCaptionReady(false)
    }
  }, [onPlayerDispose])

  useEffect(()=>{
    if(!playerRef.current && entityName === 'trailers') return
    const video = playerRef.current
    if(!video) return
    video.addEventListener('dblclick', ()=> toggleFullscreen())
    return video.removeEventListener('dblclick', () => toggleFullscreen())
  },[playerRef.current, toggleFullscreen])

  return videoToken ? (
    <Container
      style={style}
      ref={containerRef}
      isCursorVisible={showVideoCommands}
      onMouseMove={showCommands}
    >
      <video
        playsInline
        ref={player => {
          if (!player) return
          playerRef.current = player
          playerRef.current.addEventListener('loadedmetadata', initializeVideoDuration)
          !Hls.isSupported() ? playerRef.current.addEventListener('timeupdate',()=> {
            updateTimeElapsed()
          }) : playerRef.current.addEventListener('timeupdate', updateTimeElapsed)
          playerRef.current.onended = ()=> {
            setTimeout( ()=> {
              if (reloadContent) reloadContent()
            }, 1000)
          }
          playerRef.current.addEventListener('canplay', () => {
            if(Hls.isSupported()){
              loadTimeout && clearTimeout(loadTimeout)
              loadTimeout = null
              setShowLoading(false)
              setIsLoading && setIsLoading(false)
            }
          })
        }}
        style={{maxHeight: '100vh', width: '100%', position: 'relative', ...videoStyle}}
        poster={poster ? `${conf.baseUrl}${poster}` : notFoundImg}
        onClick={() => isMobile ? showCommands() : togglePlay()}
        loop={options.loop || false}
        muted={options.muted || false}
        autoPlay={Hls.isSupported()}
        controls={!Hls.isSupported() && isMobile && !!document.fullscreenElement}
      >
        {!!captions.length && captions.map(({src, srcLang, label}) => {
          return <track src={src} key={srcLang} kind="subtitles" srcLang={srcLang} label={label}/>
        })}
      </video>

      { item && product && IsFullScreen() && <div style={{display: 'flex', alignItems: 'center', position: 'absolute', left: 0, top: 0, height: '100%'}}>
        <SideZone style={{left: '0', transition: isMenuOpen ? 'all 0.5s ease-in' : 'all 0.5s ease-out'}}>
          <SideBox state={isMenuOpen || false}>
            <SideMenu

              currentTime={item.time}
              openMenu={()=>setMenuOpen((prev)=> !prev)}
              product={product}
              menuState={isMenuOpen || false}
              lessonId={String(item.id) || ''}
              courseId={String(productId) || ''}
            />
          </SideBox>
        </SideZone>
      </div>}
      {
        !!options.controls && <VideoPlayerControls
          toggleFullscreen={()=> toggleFullscreen()}
          isInline={isInline ? isInline :  false}
          playerRef={playerRef}
          setIsVideoPaused={setIsVideoPaused}
          isVideoPaused={isVideoPaused}
          showVideoCommands={showVideoCommands}
          duration={duration}
          secondsDuration={secondsDuration}
          togglePlay={togglePlay}
          currentTime={currentTime}
          openMenu={product && item ? ()=> setMenuOpen(prev=> !prev) : undefined}
          isMenuOpen={isMenuOpen}
          timeElapsed={timeElapsed}
          datetimeElapsed={datetimeElapsed}
          captions={captions}
          setPlayInline={setPlayInline}
          areCaptionReady={areCaptionReady}
          setShowLoading={() => {
            loadTimeout && clearTimeout(loadTimeout)
            loadTimeout = setTimeout(() => setShowLoading(true), 500)
          }}
        />
      }

      <PlaybackAnimation ref={playbackAnimationRef}>
        <FontAwesomeIcon icon={!isVideoPaused ? faPlay : faPause} color={'white'}/>
      </PlaybackAnimation>
      {showLoading && <Spinner style={{opacity: 1}}>
        <i className="fa fa-circle-o-notch fa-spin" style={{fontSize: '50px', color: "white"}}></i>
      </Spinner>}
    </Container>
  ) : null
}

export interface AudioTracksInfo {
  src: string,
  srcLang: string,
  label: string
}

// region Style
const Container = styled.div<{ isCursorVisible: boolean }>`

  height: auto;
  width: 100%;
  max-width: var(--max-content-width);
  position: relative;
  display: flex;
  cursor: ${({isCursorVisible}) => isCursorVisible ? 'auto' : 'none'};
  transition: cursor 0.5s ease;

  button {
    cursor: pointer;
    position: relative;
    margin-right: 7px;
    font-size: 12px;
    padding: 3px;
    border: none;
    outline: none;
    background-color: transparent;
  }
  
  button * {
    pointer-events: none;
  }
  
  button::before {
    content: attr(data-title);
    position: absolute;
    display: none;
    right: 0;
    top: -50px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    font-weight: bold;
    padding: 4px 6px;
    word-break: keep-all;
    white-space: pre;
  }
  
  button:hover::before {
    display: inline-block;
  }

  input[type=range] {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 8.4px;
    background: transparent;
    cursor: pointer;
  }

  input[type=range]:focus {
    outline: none;
  }

  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    cursor: pointer;
    border-radius: 1.3px;
    -webkit-appearance: none;
    transition: all 0.4s ease;
  }

  input[type=range]::-webkit-slider-thumb {
    height: 16px;
    width: 16px;
    border-radius: 16px;
    background: transparent;
    cursor: pointer;
    -webkit-appearance: none;
    margin-left: -1px;
  }

  input[type=range]:focus::-webkit-slider-runnable-track {
    background: transparent;
  }

  input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    border: 1px solid transparent;
    background: transparent;
    border-radius: 1.3px;
  }

  input[type=range]::-moz-range-thumb {
    height: 14px;
    width: 14px;
    border-radius: 50px;
    border: 1px solid var(--primary);
    background: var(--primary);
    cursor: pointer;
    margin-top: 5px;
  }

  input[type=range]:focus::-moz-range-track {
    outline: none;
  }

  .hidden {
    display: none;
  }

  svg {
    width: 28px;
    height: 28px;
    fill: #fff;
    stroke: #fff;
    cursor: pointer;
  }
`

const PlaybackAnimation = styled.div`
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  background-color: var(--page-background);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
`

const Spinner = styled.div`
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
`

const SideZone = styled.div`
  position: absolute;
  left: 0;
  bottom: 0px;
  z-index: 2;
  height: 100%;
  width: 45vw;
  display: flex;
  align-items: center;
  & #side {
    height: 100vh;
  }
  #button {
    transition: 0.4s;
    opacity: 0%;
  }
  @media (max-width: 720px) {
    width: 90vw;
  }
  &:hover #button {
    transition: 0.4s;
    opacity: 100%;
  }
`

const SideBox = styled.div<{ state: boolean }>`
  width: ${(props) => (props.state ? '100%' : '0px')};
  overflow-x: hidden;
  transition: 0.8s;
`
// endregion


function IsFullScreen() {
  return !!(document.fullscreenElement || (document as any).mozFullScreenElement || (document as any).webkitFullscreenElement || (document as any).msFullscreenElement)
}

import styled from 'styled-components'
import {useNavigate} from 'react-router-dom'
import React, {useEffect, useRef, useState} from 'react'
import ExpandedMenu from './ExpandedMenu'
import useRest from '../../../../hooks/useRest'
import {Category} from '../../../../types/rest'
import {ReactComponent as LogoFull} from '../../../../images/logo_full.svg'
import {ReactComponent as LogoMaxxi} from '../../../../images/logo_maxxi.svg'
import MenuIcon from '../../../../images/icons/menu-icon.svg'
import useMediaQuery from "../../../../hooks/useMediaQuery"
import {small} from "../../../../constants"

/** Componente TopBar in versione mobile */
const TopbarMobile: React.FC = () => {
  const [expanded, setExpanded] = useState(false)
  const topbar = useRef<HTMLDivElement>(null)

    const isMobile = useMediaQuery(`(max-width: ${small})`)

  const navigate = useNavigate()

  const {results: categories} = useRest<Category>('categories', {lazy: false})

  useEffect(() => {
    const closeOpenMenus = (e: MouseEvent) => {
      if (topbar && topbar?.current && !topbar?.current.contains(e.target as Node)) {
        setExpanded(false)
      }
    }
    document.addEventListener('mousedown', closeOpenMenus)
    return () => document.removeEventListener('mousedown', closeOpenMenus)
  }, [])

  return (
    <Container ref={topbar} isMobile={isMobile}>
      <LogoFull
        onClick={() => {
          setExpanded(false)
          navigate('/')
        }}
        style={{gridArea: 'multiverse', placeSelf: 'start center', height: 51, width:83, paddingLeft:'20px',cursor: 'pointer'}}
      />
      <LogoAndLanguageContainer>
        <LogoMaxxi style={{ height: 32, width: 94, marginRight: '15px'}}/>
      </LogoAndLanguageContainer>
      {!expanded ? <img
          src={MenuIcon}
          onClick={() => {
            setExpanded((old) => !old)
          }}
          alt={'menu-icon'}
          style={{height: 30, width: 35, alignSelf: 'center', justifySelf: 'flex-end', marginRight: '25px', cursor:'pointer'}}
        />
        :
        <svg
          onClick={() => {
            setExpanded((old) => !old)
          }}
          viewBox="0 0 24 24"
          width={30}
          height={30}
          style={{alignSelf: 'center', justifySelf: 'flex-end', marginRight: '25px', cursor:'pointer'}}>
          <circle r={8} cx={12} cy={12} stroke="var(--primary)" strokeWidth={4}/>
        </svg>}
      {/*<MenuIcon*/}
      {/*  expanded={expanded}*/}
      {/*  user={user}*/}
      {/*  onClick={() => {*/}
      {/*    setExpanded((old) => !old)*/}
      {/*  }}*/}
      {/*/>*/}
      {topbar.current && (
        <ExpandedMenu
          hide={() => setExpanded(false)}
          on={expanded}
          top={topbar.current.offsetHeight}
          categories={categories.filter((category: Category) => category.products && category.products.length > 0)}
        />
      )}
    </Container>
  )
}
//

export default TopbarMobile

//region Style
const Container = styled.div<{ isMobile:boolean }>`
  position: ${({isMobile}) => isMobile ? 'fixed' : 'absolute'};
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  grid-area: topbar;
  color: #ffffff;
  background-color: var(--page-background);
  display: grid;
  grid-template-columns: auto 1fr 60px;
  grid-template-areas: 'multiverse maxxi menu';
  place-content: center;
  --topbar-height: 85px;
  height: 85px;
  font-size: 1.5rem;
  
`
const LogoAndLanguageContainer = styled.div`
  grid-area: maxxi;
  justify-self: flex-end;
  align-self: center;
  display: flex;
  align-items: center;
`
//endregion

import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import conf from '../constants/configuration'
import axios, { AxiosResponse } from 'axios'
import { AnyObject } from './types'
import { formatValues, makeHeader } from './helpers'
import log from '../helpers/logger'
import logger from '../helpers/logger'

import queryString from 'query-string'
import { useNavigate } from 'react-router-dom'

interface Options<F> {
  lazy?: boolean
  initialFilters?: F
}

interface Parameters<F> {
  filters?: F
  id?: number | string
  afterPath?: string
  withoutNotification?: boolean
  notFound?: boolean
}

export interface CallsOptions {
  afterPath?: string
  withoutNotification?: boolean
}

interface HookObject<E = AnyObject, F = AnyObject> {
  result: E
  results: E[]
  total: number
  setResult: Dispatch<SetStateAction<E>>
  setResults: Dispatch<SetStateAction<E[]>>
  get: (args?: Parameters<F>) => Promise<{ payload: E[]; count: number }>
  getById: (args?: Parameters<F>) => Promise<E>
  post: (values?: any, opts?: CallsOptions) => Promise<AnyObject>
  put: (id: any, values?: any, opts?: CallsOptions) => Promise<AnyObject>
  remove: (id: any, opts?: CallsOptions) => Promise<AxiosResponse<E[] | E>>
  download: (id: any, fileName: string, opts?: CallsOptions) => Promise<void>
  loading: boolean
  error: string
}

function useRest<E = AnyObject, F = AnyObject>(url: string, options: Options<F> = {}): HookObject<E, F> {
  const { lazy = true } = options
  const [total, setTotal] = useState(0)
  const [result, setResult] = useState<E>({} as E)
  const [results, setResults] = useState<E[]>([] as E[])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const navigate = useNavigate()

  //region METHODS
  const get = useCallback(
    ({ filters, afterPath }: Parameters<F> = {}): Promise<{
      payload: E[]
      count: number
    }> => {
      let completeUrl = `${conf.apiUrl}/${url}`
      if (afterPath) completeUrl += afterPath
      if (filters) {
        completeUrl += '?'
        const queryFilters = queryString.stringify(formatValues(filters))
        if (queryFilters) {
          completeUrl += queryFilters
        }
      }
      log.silly(`GET: ${completeUrl}`)
      setLoading(true)
      return axios
        .get(completeUrl, makeHeader())
        .then(({ data }) => {
          setTotal(data.total || data.length)
          setResults(data.payload || data)
          return data
        })
        .catch((error) => {
          setLoading(false)
          setError(error.message)
        })
        .finally(() => setLoading(false))
    },
    [url]
  )
  const getById = useCallback(
    ({ filters, id, afterPath, notFound = true }: Parameters<F> = {}): Promise<E> => {
      let completeUrl = `${conf.apiUrl}/${url}`
      if (id) completeUrl += `/${id}`
      if (afterPath) completeUrl += afterPath
      if (filters) {
        completeUrl += '?'
        const queryFilters = queryString.stringify(formatValues(filters))
        if (queryFilters) {
          completeUrl += '&'
          completeUrl += queryFilters
        }
      }
      log.silly(`GET: ${completeUrl}`)
      setLoading(true)
      return axios
        .get(completeUrl, makeHeader())
        .then(({ data }) => {
          setResult(data)
          return data
        })
        .catch((error) => {
          setLoading(false)
          setError(error.message)

          //404 redirect
          if (error.response?.status === 404 && notFound) {
            navigate('/notFound')
          }
        })
        .finally(() => setLoading(false))
    },
    [url, navigate]
  )
  /**
   * Funzione per il Post su un endpoint preimpostato
   *
   * @param values {any} oggetto contenente i valori da inviare nella richiesta
   * @param opts {CallsOptions} opzioni di personalizzazzione per la richiesta default {}
   * @param opts.afterPath {string} path addizionale da aggiungere in coda all'url
   * @param opts.withoutNotification {boolean} parametro per nascondere la notifica di riuscita/errore della richiesta
   *
   * @returns Promise<AxiosResponse> in caso di errore fa il throw dell'errore
   */
  const post = useCallback(
    (values?: any, opts: CallsOptions = {}): Promise<AxiosResponse> => {
      let completeUrl = `${conf.apiUrl}/${url}`
      if (opts.afterPath) completeUrl += opts.afterPath
      setLoading(true)
      log.silly(`POST: ${completeUrl}`)
      return axios
        .post(completeUrl, values ? formatValues(values) : null, makeHeader())
        .then(({ data }) => {
          return data
        })
        .catch((error) => {
          setLoading(false)
          setError(error.message)

          throw error
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [url]
  )
  /**
   * Funzione per il Put su un endpoint preimpostato
   *
   * @param id {any} id dell'entita da modificare
   * @param values {any} oggetto contenente i valori da inviare nella richiesta
   * @param opts {CallsOptions} opzioni di personalizzazzione per la richiesta default {}
   * @param opts.afterPath {string} path addizionale da aggiungere in coda all'url
   * @param opts.withoutNotification {boolean} parametro per nascondere la notifica di riuscita/errore della richiesta
   *
   * @returns Promise<AxiosResponse> in caso di errore fa il throw dell'errore
   */
  const put = useCallback(
    (id: any, values?: any, opts: CallsOptions = {}): Promise<AxiosResponse> => {
      let completeUrl = `${conf.apiUrl}/${url}`
      if (id) completeUrl += `/${id}`
      if (opts.afterPath) completeUrl += opts.afterPath
      setLoading(true)
      log.silly(`PUT: ${completeUrl}`)
      return axios
        .put(completeUrl, values ? formatValues(values) : null, makeHeader())
        .then(({ data }) => {
          return data
        })
        .catch((error) => {
          setLoading(false)
          setError(error.message)

          throw error
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [url]
  )
  /**
   * Funzione per il Delete su un endpoint preimpostato
   *
   * @param id {any} id dell'entita da cancellare
   * @param opts {CallsOptions} opzioni di personalizzazzione per la richiesta default {}
   * @param opts.afterPath {string} path addizionale da aggiungere in coda all'url
   * @param opts.withoutNotification {boolean} parametro per nascondere la notifica di riuscita/errore della richiesta
   *
   * @returns Promise<AxiosResponse> in caso di errore fa il throw dell'errore
   */
  const remove = useCallback(
    (id: any, opts: CallsOptions = {}): Promise<AxiosResponse> => {
      let completeUrl = `${conf.apiUrl}/${url}`
      if (id) completeUrl += `/${id}`
      if (opts.afterPath) completeUrl += opts.afterPath
      setLoading(true)
      log.silly(`DELETE: ${completeUrl}`)
      return axios
        .delete(completeUrl, makeHeader())
        .then(({ data }) => {
          return data
        })
        .catch((error) => {
          setLoading(false)
          setError(error.message)

          throw error
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [url]
  )
  /**
   * Funzione per il Download su un endpoint preimpostato
   *
   * @param id {any} id dell'entita da cancellare
   * @param fileName {string} nome del file da salvare
   * @param opts {CallsOptions} opzioni di personalizzazzione per la richiesta default {}
   * @param opts.afterPath {string} path addizionale da aggiungere in coda all'url
   * @param opts.withoutNotification {boolean} parametro per nascondere la notifica di riuscita/errore della richiesta
   *
   * @returns Promise<AxiosResponse> in caso di errore fa il throw dell'errore
   */
  const download = useCallback(
    (id: any, fileName: string, opts: CallsOptions = {}): Promise<void> => {
      let completeUrl = `${conf.apiUrl}/${url}`
      if (id) completeUrl += `/${id}`
      if (opts.afterPath) completeUrl += opts.afterPath

      return axios
        .get(completeUrl, makeHeader('blob'))
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${fileName}`)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          setTotal(1)
        })
        .catch((error) => {
          setLoading(false)
          setError(error.message)

          throw error
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [url]
  )
  //endregion

  useEffect(() => {
    if (lazy) return
    get({ filters: options.initialFilters }).catch((err) => logger.error(err))
  }, [get, lazy, options.initialFilters])

  return {
    get,
    getById,
    post,
    put,
    remove,
    download,
    result,
    results,
    total,
    setResult,
    setResults,
    loading,
    error
  }
}

export default useRest

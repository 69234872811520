import React from 'react'
import styled from 'styled-components'
import {useSelector} from "react-redux";
import {getTranslation} from "@store/selectors";
import {useNavigate} from "react-router-dom";
import useMediaQuery from "../../hooks/useMediaQuery";
import {small} from "../../constants";

const Footer: React.FC = () => {
  const translation = useSelector(getTranslation)
  const navigate = useNavigate()
  const isMobile = useMediaQuery(`(max-width: ${small})`)
  return <Container isMobile={isMobile}>
    <Box isMobile={isMobile}>
      <Wrapper isMobile={isMobile}>
        <LeftSide>
          <CopyRight>{translation.footer.copyright}</CopyRight>
        </LeftSide>
      </Wrapper>
      <RightSide>
        <Utils isMobile={isMobile}>
          <Util onClick={()=> navigate('/legal-notices')}> {translation.footer.legalNotes}</Util>
          <Util onClick={()=> navigate('/privacy')}> {translation.footer.privacy}</Util>
          <Util onClick={()=> navigate('/cookies')}>{translation.footer.cookies}</Util>
          <Util onClick={()=> navigate('/credits')}>{translation.footer.credits}</Util>
        </Utils>
      </RightSide>
    </Box>
  </Container>
}

export default Footer

const Container = styled.div<{isMobile: boolean}>`
  padding: 1rem ${({isMobile})=> isMobile ? '15px': '0'};
  background-color: var(--page-background);  
  width: 100%;
  max-width: var(--max-content-width);
  margin: 3rem auto 0 auto;
  font-size: 2rem;
  @media (max-width: 1340px) {
    padding: 0 15px;
  }
`

const Box = styled.div<{isMobile: boolean}>`
  border-top: 1px solid #fff;
  margin: ${({isMobile})=> isMobile ? '0': '1rem auto'};
  width: 100%;
  padding : ${({isMobile})=> isMobile ? '0': '1rem 0'};
  display: grid;
  grid-template-columns: 7fr 4fr;
  &:first-child{
  }
  @media (max-width: 1200px) {
     grid-template-columns: auto;
     &:first-child{ order: 2; }
    }
  margin-bottom: 3rem;
`

const Wrapper = styled.div<{isMobile: boolean}>`
  width: 100%;
  display: grid;   
  grid-template-columns:${({isMobile})=> isMobile ? '': '4fr 3fr'}; 
  gap: 1rem; 
  @media (max-width: 1200px) {
    /* order: 0; la parte con i loghi sopra  */
    /* order: 2; la parte con i loghi sotto  */
    order: 0;
    margin-top: 1rem;
  }
`

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 1.2rem;
  font-weight: 300;
  @media (max-width: 1024px) {
    flex-direction: row;
    justify-content:center;
    align-items: center;
  }
`

const RightSide = styled.div`
  display: grid;
  grid-template-rows: 10fr auto;
`

const CopyRight = styled.div`
  margin-top: auto;
  font-size: var(--footer-text);
`

const Utils = styled.div<{isMobile: boolean}>`
height: auto;
  display: flex;
  justify-content: ${({isMobile}) => isMobile ? 'space-evenly' : 'space-between'};
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
`

const Util = styled.div`
font-size: var(--footer-text);
  font-weight: 300;
  place-self: end;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
  @media (max-width: 1200px) {
    margin-top: 1rem;
  }
`

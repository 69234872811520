import { RootState } from '@store/types'
import { ThunkDispatch } from 'redux-thunk'
import meetingManager from './meeting'

export const connectRedux = (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
  meetingManager.reduxDispatcher = dispatch

  meetingManager.reduxGetState = getState
}

export { meetingManager }

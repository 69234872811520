import styled from 'styled-components'
import {useLocation, useNavigate} from 'react-router-dom'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import ExpandedMenu from './ExpandedMenu'
import useRest from '../../../../hooks/useRest'
import { Category, Live } from '../../../../types/rest'
import LogoFull from '../../../../images/logo_full.svg'
import NewLogoFull from '../../../../images/new_logo_full.svg'
import { ReactComponent as LogoMaxxiFull } from '../../../../images/logo_maxxi_full.svg'
import {ReactComponent as LogoMaxxiSticky} from '../../../../images/logo_maxxi.svg'
import { useSelector } from 'react-redux'
import { getLogin, getTranslation } from '@store/selectors'
import { ReactComponent as SearchIcon } from '../../../../images/icons/search.svg'
import UserInfo from './UserInfo'
import SearchBox from './SearchBox'
import ChangeLanguageButton from './ChangeLanguageButton'
import useMediaQuery from "../../../../hooks/useMediaQuery";

/**
 * Componente TopBar contenente il menù e le info utente
 * @constructor
 */
const Topbar: React.FC<Props> = ({ height, isInTemplate }) => {
  const [open, setOpen] = useState<null | 'search' | 'explore' | 'user'>(null)
  const [openForRender, setOpenForRender] = useState<null | 'search' | 'explore' | 'user'>(null)
  const [isSticky, setIsSticky] = useState<boolean>(false)
  const topbar = useRef<HTMLDivElement>(null)

  const isMobile = useMediaQuery(`(max-width: 1275px)`)

  const { user, isAuthenticated } = useSelector(getLogin)
  const translation = useSelector(getTranslation)
  const navigate = useNavigate()
  const location = useLocation()

  const { results: categories } = useRest<Category>('categories', { lazy: false })
  const { result: currentLive, getById } = useRest<Live>('lives')

  // click away listener per i menu
  useEffect(() => {
    const closeOpenMenus = (e: MouseEvent) => {
      if (topbar && topbar?.current && !topbar?.current.contains(e.target as Node)) {
        setOpen(null)
        setOpenForRender(null)
      }
    }
    document.addEventListener('mousedown', closeOpenMenus)
    return () => document.removeEventListener('mousedown', closeOpenMenus)
  }, [])

  // caricamento live in corso se presente
  useEffect(() => {
    if (!isAuthenticated) return
    getById({ id: '/check-started' }).catch((e) => console.error(e))
  }, [getById, isAuthenticated])

  const navigateToLive = () => {
    if (!currentLive?.id) return
    if (currentLive.interactive) navigate(`/meetings/${currentLive.id}`)
    else navigate(`/live/${currentLive.productItemId}`)
  }

  const handleMenuStateChange = useCallback((state: 'explore' | 'search' | 'user' | null)=>{
    setTimeout(()=>setOpenForRender(state), 300)
    setTimeout(()=>setOpen(state), 0)
  }, [setOpen, setOpenForRender])

  useEffect(()=>{
    if(topbar?.current?.offsetHeight && topbar?.current?.offsetHeight <= window.scrollY) {
      setIsSticky(false)
      return
    }
    setIsSticky(true)
  },[topbar?.current?.offsetHeight, window.scrollY])

  return (
    <Wrapper>
    <Container height={height} ref={topbar}>
      {
        isSticky && !isMobile ? (
            <img
                style={{ cursor : 'pointer'}}
                id={'new-logo-know-how'}
                src={NewLogoFull}
                alt="logo-know-how"
                onClick={() => {
                  handleMenuStateChange(null)
                  navigate('/')
                }}
            />
            ) : (
            <img
                style={{ cursor : 'pointer', width: '77px', height: '48px'}}
                id={'logo-know-how'}
                src={LogoFull}
                alt="logo-know-how"
                onClick={() => {
                  handleMenuStateChange(null)
                  navigate('/')
                }}
            />
        )
      }
      <Menu>
        {currentLive?.id && (
          <LiveBanner onClick={() => navigateToLive()}>
            <div style={{ borderRadius: '50%', backgroundColor: '#ffffff', width: 10, height: 10 }} /> live
          </LiveBanner>
        )}
        <Explore onClick={() => (open !== 'explore' ? handleMenuStateChange('explore') : handleMenuStateChange(null))}>
          {translation.topBar.explore}
        </Explore>

        {!user && (
          <>
            <SeparatorCircle />
            <div
              onClick={()=>{
              handleMenuStateChange(null)
              navigate('/get-started')
              }}
              style={{ font: 'inherit', color: '#ffffff', whiteSpace: 'nowrap', cursor: 'pointer'}}>
                {translation.topBar.how}
            </div>
          </>
        )}
        <SeparatorCircle />
        {user && topbar.current ? (
          <UserInfo
            top={topbar.current.offsetHeight}
            on={openForRender === 'user'}
            open={() => handleMenuStateChange('user')}
            hide={() => handleMenuStateChange(null)}
            user={user}
          />
        ) : (
          <>
            <div style={{ font: 'inherit', color: '#ffffff', cursor: 'pointer' }} onClick={() => navigate(`/login?redirectTo=${encodeURIComponent(location.pathname)}`)}>
              {translation.topBar.login}
            </div>
            <SeparatorBar />
            <div style={{ font: 'inherit', color: '#ffffff', cursor: 'pointer' }} onClick={() => navigate('/signup')}>
              {translation.topBar.signup}
            </div>
          </>
        )}

        <SeparatorCircle />

        <ChangeLanguageButton/>

        <SearchIcon
          style={{ cursor: 'pointer', height: '23px', width: '23px', marginLeft: '34px'}}
          onClick={() => (open !== 'search'  ? handleMenuStateChange('search') : handleMenuStateChange(null))}
        />
      </Menu>
      {
        isSticky && !isMobile  ? <LogoMaxxiFull id='logo-maxxi-full' style={{width: '310px', height: '52px'}}/> : <LogoMaxxiSticky id='sticky-logo-maxxi-full'/>
      }
      {topbar.current &&
        ((openForRender === 'explore' &&
          <ExpandedMenu
            isInTemplate={isInTemplate}
            hide={() => handleMenuStateChange(null)}
            on={open === 'explore'}
            top={topbar.current.offsetHeight}
            categories={categories.filter((category: Category) => category.products && category.products.length > 0)}
          />
        ) ||
          (openForRender === 'search' &&
            <SearchBox
              isInTemplate={isInTemplate}
              hide={() => handleMenuStateChange(null)}
              top={topbar.current.offsetHeight}
              on={open === 'search'}
            />
          ))}

    </Container>
</Wrapper>
  )
}

export default Topbar

//region Style
type Props = {
  height: number
  isInTemplate: boolean
}

type ContainerProps = {
  height: number
}

const Container = styled.div<ContainerProps>`
  max-width: var(--max-content-width);
  margin: 0 auto;
  grid-area: topbar;
  color: #ffffff;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'multiverse menu maxxi';
  grid-column-gap: 0.5rem;
  place-content: center;
  height: ${(props) => `${props.height}px`};
  font-size: 21px;
  #logo-know-how {
    grid-area: multiverse;
    height: 70%;
    justify-self: start;
    align-self: center;
    cursor: pointer;
    min-height: 44px;
    max-height: ${(props) => `${props.height - 30}px`};
  }
  #new-logo-know-how {
    grid-area: multiverse;
    justify-self: start;
    align-self: center;
    cursor: pointer;
    /*min-height: 50px;
    max-height: ${(props) => `${props.height - 40}px`};*/
  }
  #logo-maxxi-full {
    grid-area: maxxi;
    justify-self: end;
    align-self: center;
    height: 68%;
    max-height: 116px;
    min-height: 40px;
    width: min-content;
  }
  #sticky-logo-maxxi-full {
    grid-area: maxxi;
    justify-self: end;
    align-self: center;
    height: 0%;
    max-height: 55px;
    min-height: 40px;
    width: min-content;
  }
`

const Wrapper = styled.div`
  z-index: 9999;
  margin: 0 auto;
  width: 100%;
  position: fixed;
  background-color: var(--page-background);
  top: 0;
  left: 0;
  right: 0;
  /* inserita media query per evitare che si attacchi l'header al bordo della pagina, avendo la maxWidth a 1340px */
  @media (min-width: 1025px) and (max-width: 1355px) {
    padding: 0 1rem;
  }
`

const Menu = styled.div`
  grid-area: menu;
  place-self: center;
  place-items: center;
  display: flex;
  font-weight: 500;
  max-width: 604px;
`

const SeparatorCircle = styled.div`
  background: var(--primary);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  place-self: center;
  margin: 0 25px;
`

const SeparatorBar = styled.div`
  place-self: center;
  width: 2px;
  height: 1.3rem;
  background-color: var(--primary);
  opacity: 0.5;
  margin: 0 9px;
`

const Explore = styled.div`
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`

const LiveBanner = styled.div`
  width: 85px;
  height: 30px;
  border-radius: 5px;
  background-color: #ff0b44;
  font-weight: 600;
  font-size: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-right: 1rem;
  cursor: pointer;
`
//endregion

import * as t from './types'

export const setTranslation = (payload: any): t.LocalizationActionTypes => ({
  type: t.SET_TRANSLATION,
  payload
})

export const setLanguage = (payload: any): t.LocalizationActionTypes => ({
  type: t.SET_LANGUAGE,
  payload
})

import React from 'react'
import styled, {CSSObject} from "styled-components"

type Props = {
  disabled?: boolean
  label: string
  rightImg?: string
  style?: CSSObject
}

export const SubmitButton: React.FC<Props> = (props) => {
  const { disabled, label, rightImg, style } = props

  return <Button type="submit" disabled={disabled} style={style}>
    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px', height: '100%'}}>
      <div style={{height: 'min-content'}}>{label}</div>
      {rightImg && <img src={rightImg} alt={'rightImg'} style={{height: '100%', padding: '8px 0'}}/>}
    </div>
  </Button>
}

const Button = styled.button`
  width: 250px;
  height: 70px;
  background-color: var(--primary);
  border-radius: 6px;
  font-weight: 600;
  font-size: 1.4rem;
  color: #ffffff;
  cursor: pointer;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`
import React, {useEffect} from 'react'
import useRest from '../../hooks/useRest'
import conf from '@configuration'
import {useSelector} from 'react-redux'
import {getLanguage, getTranslation, getUser} from '@store/selectors'
import styled from 'styled-components'
import {BigButton} from './BigButton'
import {Options, Splide, SplideSlide} from '@splidejs/react-splide'
import {useNavigate} from 'react-router'
import Dash from '../../images/icons/dash.svg'
import useMediaQuery from '../../hooks/useMediaQuery'
import {small} from '../../constants'

/**
 * Componente per la visualizzazione in homepage dei trailer selezionati dal frontend di amministrazione
 * @param id: id dell' HighlightTrailer
 * @constructor
 */
interface Props {
  title?: string
  width?: string
  arrow?: boolean
  loop?: boolean
  id: string
  layover?: string
  showMore?: boolean
  noDesc?: boolean
  botButton?: boolean
  overflowStyle?: boolean
  height?: string
  suggestSubscription?: boolean
  suggestBuy?: boolean
  contentMaxWidth?: string
  contentPadding?: string
  contentHeight?: string
  slideBreakpoints?: Options['breakpoints']
  descriptionFontSize?: string
}

export const Slideshow: React.FC<Props> = (props) => {
  const {
    layover,
    title,
    width,
    arrow = false,
    loop,
    id,
    noDesc = false,
    botButton = false,
    overflowStyle = false,
    height,
    // suggestSubscription = false,
    suggestBuy = false,
    contentMaxWidth,
    contentPadding,
    contentHeight,
    slideBreakpoints,
    descriptionFontSize
  } = props
  const language = useSelector(getLanguage)
  const translation = useSelector(getTranslation)
  // const user = useSelector(getUser)

  const navigate = useNavigate()

  const isMobile = useMediaQuery(`(max-width: ${small})`)

  const { result: slideSet, getById } = useRest('slideshows')

  useEffect(() => {
    if (id) getById({ id: id }).catch((err) => console.error(err))
  }, [id, getById])

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          width: '100%',
          alignItems: 'center'
        }}>
          <div style={{ width: '100%', display: 'flex',flexWrap: 'wrap', justifyContent: 'space-between'}}>
              {title && (
                  <TitleBox isMobile={isMobile}>
                      <img src={Dash} style={{ filter: 'invert(100%)' }} alt="" />
                      <Title isMobile={isMobile}>{title}</Title>
                  </TitleBox>
              )}

          </div>
      </div>

      <SliderContainer>
        <Splide
          style={{ height: '100%', width: '100% ' }}
          aria-label="My Favorite Images"
          options={{
            type: loop ? 'loop' : 'slide',
            start: 2,
            autoWidth: true,
            height: height || '40rem',
            padding: !isMobile ? (width ? ` calc(((100% - ${width}) /2) + 30px)` : ' calc((100% - 80vw) /2)') : '',
            autoplay: false,
            gap: '50px',
            arrows: arrow,
            interval: 3000,
            breakpoints: slideBreakpoints || {},
          }}>
          {slideSet?.items?.map((item: any, k: number) => {
            return (
              <SplideSlide
                style={{
                  overflowX: 'hidden',
                  margin: overflowStyle ? '0 -30px' : '0',
                  width: width ? width : '80vw',
                  minWidth: isMobile? '' : '500px',
                  height: '100%',
                  aspectRatio: '1/2'
                }}
                key={k}>
                <Background>
                  <Image
                    style={{
                      borderRadius: '40px',
                      objectFit: 'cover'
                    }}
                    src={`${conf.baseUrl}${item.image}`}
                    alt=""
                  />
                  {layover && <Layover layover={layover} />}
                </Background>
                <Content maxWidth={contentMaxWidth} padding={contentPadding} height={contentHeight}>
                  <StyledH2 isMobile={isMobile} height={height}>
                    {item?.translations[language].title}
                  </StyledH2>
                  <img src={Dash} style={{ filter: 'invert(100%)', marginBottom: '10px' }} alt="" />
                  {!noDesc && <Description fontSize={descriptionFontSize}>{item?.translations[language].description}</Description>}
                  <CtaWrap style={botButton ? {display: 'flex', flexWrap: 'wrap', bottom: '2rem', left: '3.5rem' } : {}}>
                    <BigButton
                      background={'#ffffff'}
                      color={'var(--primary)'}
                      onClick={() => navigate(`${item.url}`)}
                      width={isMobile ? '13rem': '15rem'}
                      label={translation.common?.buttons.discover}
                    />
                   {/* {!user?.hasSubscription && suggestSubscription && (
                      <BigButton
                        background={'transparent'}
                        color={'#ffffff'}
                        width={isMobile ? '10rem': '15rem'}
                        style={{ border: '2px solid white' }}
                        label={translation.common.buttons.buttonSides.subscribe}
                        onClick={() => navigate('/get-started')}
                      />
                    )}*/}
                    {suggestBuy && (
                      <BigButton
                        background={'transparent'}
                        color={'#ffffff'}
                        width={isMobile ? '10rem': '15rem'}
                        style={{ border: '2px solid white' }}
                        label={translation.common.buttons.buy}
                        onClick={() => navigate(`/buy/${item.id}`)}
                      />
                    )}
                  </CtaWrap>
                </Content>
              </SplideSlide>
            )
          })}
        </Splide>
      </SliderContainer>
    </Container>
  )
}

//region Style
const StyledH2 = styled.div<{ isMobile?: boolean, height?: string }>`
 font-weight: 700;
 max-width: 85vw; 
 font-size: ${props => props.height && props.isMobile ? '1.6rem' : '3.5rem'};
 margin: 1rem 0;
 @media (max-width: 1441px) {
  font-size: 3rem;
 }
`
const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
  position: relative;
`
const SliderContainer = styled.div``
const TitleBox = styled.div<{isMobile?:boolean}>`
  text-align: left;
  margin: 25px 3rem;
  width: 50%;
  display: flex;
  width: ${({isMobile}) => isMobile ? '100%' : '50%'};
`

const Title = styled.h2<{isMobile?:boolean}>`
  margin-left: 20px;
  font-size: 3rem;
  font-size: ${({isMobile}) => isMobile ? '2rem' : ''};
`

const Content = styled.div<{maxWidth?: string, padding?: string, height?: string}>`
  max-width: ${props => props.maxWidth ? props.maxWidth : '60%'};
  padding: ${props => props.padding ? props.padding : '50px 0 0 40px'};
  height: ${props => props.height ? props.height : '80%'};
  display: grid;
  grid-template-rows: 0.5fr 0fr 1fr;
  position: absolute;
  top: 0;
  left: 0;
`

const Background = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`
const CtaWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const Layover = styled.div<{ layover: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 40px;
  ${({ layover }) =>
    layover
      ? layover !== 'default'
        ? layover
        : 'background: rgb(106, 54, 139); background: linear-gradient(90deg, rgba(106, 54, 139, 0.8547794117647058) 35%, rgba(105, 57, 137, 0.29) 100%);'
      : ''}
`
const Image = styled.img`
  min-width: 100%;
  min-height: 100%;
`

const Description = styled.h5<{fontSize?: string}>`
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  max-width: 99vw;
  font-size: ${props => props.fontSize ? props.fontSize : '2vw'};
  font-weight: 400;
  margin: 0;
  @media (max-width: 720px) {
    max-width: 85vw;
  }
`
//endregion

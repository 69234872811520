import React, { useEffect, useState } from 'react'
import './index.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Template from './components/template/Template'
import * as pages from '@components/pages'
import { AuthManager } from '@components/managers'
import 'video.js/dist/video-js.css'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { invlerp, lerp } from './helpers/interpolations'
import useMediaQuery from './hooks/useMediaQuery'
import { smaller } from './constants'
import getTranslation from './helpers/getTranslation'
import { useDispatch, useSelector } from 'react-redux'
import { getLanguage } from '@store/selectors'
import { setTranslation } from '@store/actions'
import socketManager from './api/socket'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import conf from './constants/configuration'
import PayPalCurrencyManager from './components/managers/PayPalCurrencyManager'

sessionStorage.setItem('startPath', window.location.pathname)

const App = () => {
  const isMobile = useMediaQuery(`(max-width: ${smaller})`)

  const [topbarHeight, setTopbarHeight] = useState(isMobile ? 92 : 130)
  const [loading, setLoading] = useState(true)

  const language = useSelector(getLanguage)
  const dispatch = useDispatch()

  useEffect(() => {
    getTranslation(language)
      .then((translation) => {
        dispatch(setTranslation(translation.default))
      })
      .finally(() => setLoading(false))
  }, [language, dispatch])

  useEffect(() => {
    if (isMobile) return
    const handler = () => {
      const actualPosition = window.scrollY

      if (actualPosition < 92) return setTopbarHeight(130)
      setTopbarHeight(Math.ceil(lerp(92, 130, invlerp(130, 92, actualPosition))))
    }
    const beforeUnload = () => {
      sessionStorage.setItem('lastPath', window.location.pathname)
    }
    window.addEventListener('beforeunload', beforeUnload)
    window.addEventListener('scroll', handler)
    return () => {
      window.removeEventListener('scroll', handler)
      window.removeEventListener('beforeunload', beforeUnload)
    }
  }, [isMobile])

  useEffect(() => {
    // aggiunge un listener per salvare l'ultima posizione prima di un refresh
    const beforeUnload = async () => {
      await socketManager.disconnect()
      sessionStorage.setItem('lastPath', window.location.pathname)
    }
    window.addEventListener('beforeunload', beforeUnload)
    return () => {
      window.removeEventListener('beforeunload', beforeUnload)
    }
  }, [])

  //TODO: aggiungere loader fintanto che non ci sono le traduzioni
  return loading ? null : (
    <React.Suspense fallback={<pages.Loading />}>
      <PayPalScriptProvider options={{ 'client-id': conf.paypalClientId, vault: true, currency: 'EUR' }}>
        <BrowserRouter>
          <AuthManager />
          <PayPalCurrencyManager />
          <Routes>
            <Route path={'/login'} element={<pages.Login />} />
            <Route path={'/reset-password'} element={<pages.ResetPassword />} />
            <Route path={'/signup/'} element={<pages.Signup />} />
            <Route path={'/signup/:mail'} element={<pages.Signup />} />
            <Route path={'/change-password'} element={<pages.ChangePassword />} />
            {/*<Route path={'/signup-success'} element={<pages.SignupSuccess />} />*/}
            {/*<Route path={'/subscribe'} element={<pages.Subscribe />} />*/}
            <Route path={'/account-verification'} element={<pages.AccountVerification />} />
            <Route path={'/buy/:id'} element={<pages.Pay />} />
            <Route path={'/meetings/:id'} element={<pages.Meeting />} />
            <Route path={'/live/:id'} element={<pages.LiveContent />} />
            <Route path={'/'} element={<Template topbarHeight={topbarHeight} />}>
              <Route path={'/aldo-rossi'} element={<pages.AldoRossi />} />
              <Route path={'/legal-notices'} element={<pages.LegalNotices />} />
              <Route path={'/privacy'} element={<pages.Privacy />} />
              <Route path={'/cookies'} element={<pages.Cookies />} />
              <Route path={'/credits'} element={<pages.Credits />} />
              <Route path={'/contacts'} element={<pages.Contacts />} />
              <Route path={'/contract'} element={<pages.Contract />} />
              <Route path={'/get-started'} element={<pages.HowDoesItWork />} />
              <Route path={'/'} element={<pages.Home />} />
              <Route path={'/authors'} element={<pages.AuthorAgg />} />
              <Route path={'/authors/:id'} element={<pages.AuthorBio />} />
              <Route path={'/personalarea'} element={<pages.PersonalArea />} />
              <Route path={'/courses/:id'} element={<pages.Course />} />
              <Route path={'/courses/:courseId/lessons/:lessonId'} element={<pages.Lesson />} />
              <Route path={'/classes/:id'} element={<pages.Masterclass />} />
              <Route path={'/maxxi-original'} element={<pages.Series />} />
              <Route path={'/maxxi-original/:id'} element={<pages.Serie />} />
              {/*<Route path={'/info'} element={<pages.Info />} />*/}
              <Route path={'/categories/:id'} element={<pages.Categories />} />
              <Route path={'/categories'} element={<pages.CategoriesAgg />} />
              <Route path={'/courses'} element={<pages.CoursesAgg />} />
              <Route path={'/maxxi-library'} element={<pages.ArchiveAgg />} />
              <Route path={'/faq'} element={<pages.Faq />} />
              <Route path={'*'} element={<pages.NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </PayPalScriptProvider>
    </React.Suspense>
  )
}

export default App

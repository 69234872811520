import React from 'react'

const AldoRossi = React.lazy(() => import('./course/AldoRossi'))
const Home = React.lazy(() => import('./home/Home'))
const Meeting = React.lazy(() => import('./meeting/Meeting'))
const Contract = React.lazy(() => import('./contract/Contract'))
const Course = React.lazy(() => import('./course/Course'))
const InfoMasterclass = React.lazy(()=> import('./classes/InfoMasterclass'))
const Masterclass = React.lazy(() => import('./classes/Masterclass'))
const PersonalArea = React.lazy(() => import('./personalArea/PersonalArea'))
const Info = React.lazy(() => import('./info/Info'))
const Login = React.lazy(() => import('./login/Login'))
const ResetPassword = React.lazy(() => import('./login/ResetPassword'))
const Lesson = React.lazy(() => import('./course/Lesson'))
const Series = React.lazy(() => import('./classes/Series'))
const Serie = React.lazy(() => import('./classes/Serie'))
const Signup = React.lazy(() => import('./signup/Signup'))
// const SignupSuccess = React.lazy(() => import('./signup/SignupSuccess'))
const Content = React.lazy(() => import('./content/Content'))
// const LessonContent = React.lazy(() => import('./content/LessonContent'))
const LiveContent = React.lazy(() => import('./content/LiveContent'))
const HowDoesItWork = React.lazy(() => import('./howDoesItWork/HowDoesItWork'))
const Categories = React.lazy(() => import('./categories/Categories'))
// const Subscribe = React.lazy(() => import('./subscribe/Subscribe'))
const Pay = React.lazy(() => import('./pay/Pay'))
const CategoriesAgg = React.lazy(() => import('./categories/CategoriesAgg'))
const ArchiveAgg = React.lazy(() => import('./archive/ArchiveAgg'))
const CoursesAgg = React.lazy(() => import('./course/CoursesAgg'))
const Loading = React.lazy(() => import('./Loading'))
const AuthorBio = React.lazy(() => import('./AuthorBio'))
const AuthorAgg = React.lazy(() => import('./AuthorAgg'))
const NotFound = React.lazy(() => import('./NotFound'))
const ChangePassword = React.lazy(() => import('./login/ChangePassword'))
const AccountVerification = React.lazy(()=>import('./accountVerification/AccountVerification'))
const LegalNotices = React.lazy(() => import('./legalNotices/LegalNotices'))
const Privacy = React.lazy(() => import('./privacy/Privacy'))
const Cookies = React.lazy(() => import('./cookies/Cookies'))
const Credits = React.lazy(() => import('./credits/Credits'))
const Contacts = React.lazy(() => import('./contacts/Contacts'))
const Faq = React.lazy(() => import('./Faq'))

export {
  AldoRossi,
  Meeting,
  Home,
  Contract,
  Course,
  Masterclass,
  InfoMasterclass,
  PersonalArea,
  Info,
  Login,
  ResetPassword,
  Lesson,
  Series,
  Serie,
  Signup,
  Content,
  // LessonContent,
  LiveContent,
  HowDoesItWork,
  Categories,
  CategoriesAgg,
  CoursesAgg,
  ArchiveAgg,
  AccountVerification,
  // SignupSuccess,
  // Subscribe,
  Pay,
  Loading,
  AuthorBio,
  AuthorAgg,
  NotFound,
  LegalNotices,
  Privacy,
  Cookies,
  Credits,
  ChangePassword,
  Faq,
  Contacts
}

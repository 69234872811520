import React, { PropsWithChildren } from 'react'
import { VideoPlayer } from './VideoPlayer'
import conf from '@configuration'
import playImage from '@images/icons/play.svg'
import styled from 'styled-components'

//TODO: title e description 'statici' o dal server ma sopratutto traduzione
interface Props {
  videoUrl: string
  soundController?: boolean
  childrenPosition?: 'left' | 'right' | 'center'
  style?: React.CSSProperties
}

/**
 * Componente contenente un video in background con info e link
 * @param props.videoUrl{string} url del video
 * @param props.soundController{boolean} se presente aggiunge un bottone per il controllo del suono
 * @param props.childrenPosition{'left' | 'right'} posizione delle info
 * @constructor
 */
//TODO:non fa l'onclick e non mette il cursor :pointer sul bottone per l'audio
export const VideoPresentation: React.FC<PropsWithChildren<Props>> = (props) => {
  const { videoUrl, children, soundController = true, childrenPosition = 'left', style = {} } = props

  return (
    <Container style={style}>
      <VideoPlayer
        entityName="trailers"
        options={{
          autoplay: true,
          controls: false,
          muted: true,
          loop: true,
          sources: [
            {
              src: `${conf.wowzaUrl}${videoUrl}${conf.wowzaOption}`
            }
          ]
        }}
        style={{ margin: '0', maxWidth: '100%' }}
      />
      <Info position={childrenPosition}>
        <div style={{ alignSelf: 'center', gridColumn: childrenPosition === 'left' ? 1 : 2 }}>{children}</div>
      </Info>
      {soundController && (
        <SoundContainer onClick={() => {}}>
          <ButtonImage src={playImage} alt="sound" />
          <div style={{ placeSelf: 'center' }}>Sound</div>
        </SoundContainer>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
`
const Info = styled.div<{ position: 'right' | 'left' | 'center' }>`
  position: absolute;
  color: var(--text-over-color);
  background-color: rgba(0, 0, 0, 0.4);
  display: grid;
  grid-template-columns: 1fr 1fr;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  height: 100%;
`

const SoundContainer = styled.div`
  position: absolute;
  color: var(--text-over-color);
  bottom: 3%;
  right: 1%;
  border: 1px solid var(--text-over-color);
  padding: 0 0.2rem;
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 0.2rem;
  font-size: 0.8rem;
  cursor: pointer;
`
const ButtonImage = styled.img`
  place-self: center;
  width: 0.8rem;
  height: 0.8rem;
`

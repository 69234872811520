import styled from 'styled-components'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import React, {useRef, useEffect, useState, useMemo} from 'react'
import { useSelector } from 'react-redux'
import { getTranslation } from '@store/selectors'
import useMediaQuery from "../../hooks/useMediaQuery";
import {small} from "../../constants";
import {useNavigate} from "react-router";
import Dash from "../../images/icons/dash.svg";

interface Props<DataType> {
  title?: string
  data: DataType[]
  style?: React.CSSProperties
  CardComponent: React.ComponentType<{ data: DataType; categories?: boolean, authors?: boolean }>
  NoDataComponent?: React.ReactNode
  arrows?: boolean
  categories?: boolean
  sliderCardPosition?: string
  slug?: string
  id?: number
  authorSlider?: boolean
  leftOffset?: boolean
  withoutDash?: boolean
  titleColor?: string
  onTitleClickUrl?: string
  fontSize?: string
  noPadding?: boolean
  cardWidth?: string
}

/**
 * Componente per mostrare una slider di presentazione
 */
export function ProductsSlider<DataType = Array<any>>(props: Props<DataType>) {
  const {
    noPadding,
    arrows = true,
    style = {},
    title,
    data = [],
    CardComponent,
    NoDataComponent,
    categories,
    titleColor,
    sliderCardPosition = 'center',
    withoutDash,
    leftOffset,
    onTitleClickUrl,
    fontSize,
    authorSlider,
    cardWidth
  } = props
  const [_refresh,setRefresh] = useState<boolean>(true)
  const [index,setIndex] = useState<number>(0)
  const translation = useSelector(getTranslation)
  const isMobile = useMediaQuery(`(max-width: ${small})`)
  const navigate = useNavigate()

  const containerRef = useRef(null)
  const sliderRef = useRef(null)

  const hasScroll = useMemo(() => {
    if(window.innerHeight){
      return document.body.offsetHeight> window.innerHeight;
    }
    else {
      return  document.documentElement.scrollHeight >
        document.documentElement.offsetHeight ||
        document.body.scrollHeight>document.body.offsetHeight;
    }
  }, [window, document, document.body.clientHeight])


  const getScrollbarWidth = useMemo(() => {
    return(window.innerWidth - document.body.clientWidth) ;
  }, [window, document.body.clientHeight])

  // useEffect per stabilire indice della slide con il focus
  useEffect(()=>{
    if(!sliderRef.current) return
    (sliderRef.current as any).splide.on('moved', function ( data: any ) {
      setIndex(data)
    });
    return () => {
      sliderRef.current && (sliderRef.current as any).splide.off('pagination:updated')
    }
  },[sliderRef.current, setIndex])

  useEffect(()=>{
    setRefresh((prev)=> !prev)
  },[data, containerRef.current, setRefresh])

  const isLastElem: boolean = useMemo(()=>{
    if(index >= data.length - 3) return true
    return false
  },[data,index])

  return (
      <Wrapper containerHeight={(containerRef?.current as any)?.offsetHeight}>
        <Container authorSlider={authorSlider} style={style} withoutDash={withoutDash} title={title} isMobile={isMobile} leftOffset={leftOffset} ref={containerRef}>
          {title &&
              <div style={{ maxWidth: 'var(--max-content-width)', margin: '0 auto',width: '100%', display: 'flex',flexWrap: 'wrap', justifyContent: 'space-between'}}>
                {title && (
                  <TitleBox withoutDash={withoutDash} isMobile={isMobile}>
                    {(!withoutDash || !isMobile) && <img src={Dash} style={{filter: 'invert(100%)'}} alt=""/>}
                    <Title onTitleClickUrl={onTitleClickUrl} onClick={!onTitleClickUrl ? ()=> null : ()=> navigate(`${onTitleClickUrl}`)} withoutDash={withoutDash} fontSize={fontSize} color={titleColor} isMobile={isMobile}>{title}</Title>
                  </TitleBox>
                )}
              </div>}
          {data.length > 0 ? (
              <SplideContainer cardWidth={cardWidth} isLastElem={isLastElem}>
                <Splide
                    ref={sliderRef}
                    style={{ width: '100%' }}
                    aria-label="My Favorite Images"
                    options={{
                      arrows: arrows && data.length > 3,
                      perPage: 1,
                      autoWidth: true,
                      autoHeight: true,
                      padding: noPadding ? '' : (!isMobile ? (` calc((100vw - var(--max-content-width)) /2  ${hasScroll ? `- ${(getScrollbarWidth / 2)}px` : '' })`) : '20px'),
                      releaseWheel: true,
                      interval: 3000,
                      gap: '30px',
                      pagination: false,
                      overflowX: 'hidden',
                      omitEnd: true
                    }}>
                  {data.map((item, k) => (
                      <SplideSlide style={{ display: 'flex', justifyContent: sliderCardPosition }} key={k}>
                        {<CardComponent authors categories={categories || false} data={item} key={`${k}-component`}/>}
                      </SplideSlide>
                  ))}
                </Splide>
              </SplideContainer>
          ) : (
              <SplideContainer>
                <h4 style={{ height: '10rem', textAlign: 'center', lineHeight: '10rem', fontSize: '2rem' }}>
                  {translation.PAGES.home.noContent}
                </h4>
              </SplideContainer>
          )}
          {NoDataComponent && data.length === 0 && <NoDataContainer>{NoDataComponent}</NoDataContainer>}
        </Container>
      </Wrapper>
  )
}

//region Style

const Wrapper = styled.div<{containerHeight?: number}>`
  width: 100%;
  position: relative;
  ${({containerHeight}) => containerHeight ? `height: calc(${containerHeight}px + 50px )` : '100%'};
`

const Container = styled.div<{isMobile?: boolean, leftOffset?:boolean, withoutDash?: boolean, authorSlider?: boolean}>`
  ${({color})=> color ? `color: ${color};` : ''}
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute; 
  left: 0;
  right:0;
  width: ${({authorSlider})=> authorSlider ? '80vw': '100%'};
  ${({isMobile}) => isMobile && 'margin-top: 1rem'};
  ${({leftOffset}) => leftOffset && 'padding: 3rem 0'};
`

const Title = styled.div<{isMobile: boolean, color?: string, withoutDash?: boolean, fontSize?: string, onTitleClickUrl?: string}>`
  font-weight: bold;
  font-size: ${({ isMobile, fontSize }) => isMobile ? (fontSize ? fontSize : 'var(--section-fs-mobile)') : (fontSize ? fontSize : 'var(--section-fs)')};  
  width: max-content;
  ${({color})=> color ? `color: ${color};` : ''}
  &:hover{
    ${({onTitleClickUrl})=> onTitleClickUrl ? 'text-decoration: underline;' : ''}
  }
   ${({onTitleClickUrl})=> onTitleClickUrl ? 'cursor: pointer;' : ''}
`

const SplideContainer = styled.div<{cardWidth?: string, isLastElem?: boolean}>`
  overflow-x: hidden;
  width: 100%;
     & > div > div > ul {
         max-width: var(--max-content-width);
         margin: 0 auto;
      }
     & > div > div > button {
        opacity: 100%;  
        & > svg {
         fill: var(--primary);
        } 
     } 
    @media (min-width: 1340px) {
      & > div > div > button:first-child {

         top:  calc((((${({cardWidth})=> cardWidth ? cardWidth : 'var(--card-width-responsive)'}) / 16) * 5 ) + 3.5px );
         left:  calc((100vw - var(--max-content-width)) /2 - 34px);
         &>svg{
            transform: scale(1.5) rotate(180deg);
         } 
      } 
      & > div > div > button:nth-child(2) {
         display: ${({isLastElem})=> isLastElem ? 'none;' : 'flex;'}
         top:  calc((((${({cardWidth})=> cardWidth ? cardWidth : 'var(--card-width-responsive)'}) / 16) * 5 ) + 3.5px );
         right:  calc((100vw - var(--max-content-width)) /2 - 34px);
         &>svg{
            transform: scale(1.5);
         } 
      } 
    }
    
    @media (min-width: 1025px) and (max-width: 1340px){
      & > div > div > button:first-child {

         top:  calc((((${({cardWidth})=> cardWidth ? cardWidth : 'var(--card-width-responsive)'}) / 16) * 5 ) + 3.5px );
         left:  15px;
         
      } 
      & > div > div > button:nth-child(2) {

         top:  calc((((${({cardWidth})=> cardWidth ? cardWidth : 'var(--card-width-responsive)'}) / 16) * 5 ) + 3.5px );
         right: 15px;
      
      } 
    }
`

const TitleBox = styled.div<{isMobile?:boolean, withoutDash?: boolean}>`
  text-align: left;
  display: flex;
  width: 100%;
  max-width: calc(var(--max-content-width) - 20px);
  ${({isMobile})=> !isMobile && 'transform:translateX(-40px);'}
  ${({isMobile})=> (isMobile) && 'padding-left: 21px;'}
  ${({isMobile, withoutDash})=> (!isMobile && withoutDash) && 'padding-left: 40px;'}
  & > img {
    display: ${ ({isMobile, withoutDash})=> isMobile || withoutDash ? 'none' : 'block' };
    ${({withoutDash, isMobile})=> (withoutDash || isMobile) && 'opacity: 0'};
    margin-right: 10px;
    @media (max-width:1460px) {
      opacity: 0;
    }
  }
`

const NoDataContainer = styled.div`
  display: flex;
  place-content: center;
`
//endregion

import React  from 'react'
import styled from 'styled-components'
import { blue, green, red } from '../../constants'

export interface ButtonProps {
  text: string | React.ReactNode
  image?: string
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  rippleEffect?: boolean
  form?: string
  type?: 'button' | 'submit' | 'reset'
  buttonType?: 'primary' | 'secondary' | 'ghost' |'ghost-variant' | 'secondary-inverted' | 'promo-area-style'
  style?: {
    background?: string | 'green' | 'red' | 'blue'
    minWidth?: string
    width?: string
    height?: string
    margin?: string[] | string
    fontSize?: string
    padding?: string[] | string
    borderRadius?: string
    placeSelf?: string
    position?: string
    top?: string | number
  }
}

//TODO:Ripple non funzionante

/**
 * Componente bottone
 * @param props.text {string} testo del bottone
 * @param props.image {string} src dell'immagine da mostrare
 * @param props.disabled {boolean}
 * @param props.onClick {(React.MouseEvent<HTMLButtonElement, MouseEvent>)=>void} funzione da eseguire sul click del bottone
 * @param props.rippleEffect {boolean} WIP
 * @param props.form {string} id della form a cui è collegato il bottone usare solo se type= submit
 * @param props.type {'button' | 'submit' | 'reset'} tipo del bottone
 * @param props.style {Object} oggetto contenente le personalizzazione per il css
 * @param props.style.background {string | 'green' |'red'| 'blue'} sfondo del bottone
 * @param props.style.minWidth {string}
 * @param props.style.width {string}
 * @param props.style.height {string}
 * @param props.style.margin {string[] | string}
 * @param props.style.fontSize {string}
 * @param props.style.padding {string[] | string}
 * @param props.style.borderRadius {string}
 * @constructor
 */
export const Button = (props: ButtonProps) => {
  const { text, image, disabled = false, onClick, type = 'button', style , buttonType } = props

  //funzione che gestisce l'onclick con l'effetto ripple
  const onClickButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (disabled) return
    if (onClick) {

      onClick(e)
    }
  }

  /*region Ripple*/

  /*endregion*/

  /*region Utils*/
  const getSpacing = (type: string, value?: string[] | string, init?: string): string => {
    if (!value)
      if (!init) return ''
      else return `${type}: ${init}`
    if (typeof value === 'string') return `${type} : ${value}`
    if (value.length > 0) return `${type} : ${value.join(' ')}`
    else return `${type}: ${init}`
  }
  /*endregion */

  return (
    <ButtonStyled
      buttonType={buttonType}
      disabled={disabled}
      width={style?.width}
      height={style?.height}
      padding={getSpacing('padding', style?.padding, '8px 10px')}
      minWidth={style?.minWidth}
      fontSize={style?.fontSize || '20px'}
      onClick={(e) => onClickButton(e)}
      type={type}>
      <ButtonText hasImage={!!image}>{text}</ButtonText>
    </ButtonStyled>
  )
}

type ButtonStyledProps = {
  buttonType?: string | undefined
  minWidth?: string
  background?: string
  margin?: string
  width?: string
  height?: string
  type: string
  fontSize: string
  padding: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  placeSelf?: string
}

const ButtonStyled = styled.button<ButtonStyledProps>`
   ${({placeSelf})=> placeSelf ? `place-self:${placeSelf};`: ''};
  position: relative;
  border: 0;
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}` : '20px'};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '178px')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  font-weight: 600;
  color: #ffffff;
  padding: 20px 25px !important;
  ${({ margin }) => margin};
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  width:${({ width }) => width ? `${width}` : 'min-content'};
  line-height: 1;
  border-radius: 6px;
  transition: 0.4s;
  ${({buttonType}) => {
    switch(buttonType){
      case 'promo-area-style': return  'background-color: var(--black-base); color: #ffffff; border: 1px solid #000000; &:hover{ color: var(--primary); border: 1px solid var(--primary); background-color: transparent;}'
      case 'secondary-inverted': return  'background-color: var(--black-base); color: #ffffff; border: 1px solid #000000; &:hover{ color: #ffffff; border: 1px solid #ffffff; background-color: transparent;}'
      case 'secondary': return 'background-color: #ffffff; color: #000000; &:hover{ color: #000000; background-color: #848484; }'
      case 'ghost': return 'background-color: transparent; color: var(--primary); border: 1px solid var(--primary); &:hover{  color: #ffffff; border: 1px solid #ffffff;}'
      case 'ghost-variant': return 'background-color: transparent; color: #ffffff; border: 1px solid #ffffff; &:hover{  color: var(--primary); border: 1px solid var(--primary);}'
      default: return 'background-color: var(--primary); color: #ffffff; &:hover{ background-color: #564c78; }'
    }
}}
  &:hover{
    transition: 0.2s;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    outline: 0;
  }

  @media print {
    display: none;
  }
`


type ButtonTextProps = {
  marginText?: string
  hasImage?: boolean
}

const ButtonText = styled.div<ButtonTextProps>`
  ${({ hasImage }) => hasImage && 'margin-left: 15px'};
  flex: 1;
  font-weight: hinerit;
`

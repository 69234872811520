// Core
export const baseUrl = 'https://maxxi-video.bytewise.it'
export const apiUrl = `${baseUrl}/api`
export const adminApi = `${apiUrl}/admin`

export const wowzaUrl = 'https://wowza-maxxi-dev.bytewise.it/vod/MAXXI/'
export const wowzaOption = '/playlist.m3u8'
export const wowzaLiveUrl = 'https://wowza-maxxi-dev.bytewise.it'

export const thumbnailUrl = `${baseUrl}/thumbnails/`
export const avatarsUrl = `${baseUrl}/avatars/`

// Tranlations
export const translations = `${baseUrl}/translations`

// Janus
export const janusUrl = 'wss://maxxi-video.bytewise.it/janus'
export const turnServer = {
  host: 'turn02.bytewise.it:443?transport=tcp',
  user: 'bytewise',
  password: 'MZkaoq01'
}

// Sockets
export const socketCoordinates = {
  path: '/socket',
  url: baseUrl
}

// PayPal
export const paypalClientId = 'AWo9XM7CI8JYeor1sfZchcwgWsqJnYI0-nILdrc_ZCtYY6QlNNOoQ3ChQx6sMtWnBvYJvqnkI3_fezke'
// Subscriptions
// Ad oggi c'è una sola sottoscrizione possibile nel sito, dunque non essendoci una scelta
// i link devono portare all'unica disponibile, ma i valori sono comunque dinamici
export const defaultSubscriptionId = 90

export const defaultAldoRossiId = 1

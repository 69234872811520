// Core
export const baseUrl = 'https://knowhow.maxxi.art'
export const apiUrl = `${baseUrl}/api`
export const adminApi = `${apiUrl}/admin`

export const wowzaUrl = 'https://videomaxxi.bytewise.it/vod/MAXXI/'
export const wowzaOption = '/playlist.m3u8'
export const wowzaLiveUrl = 'https://videomaxxi.bytewise.it'

export const thumbnailUrl = `${baseUrl}/thumbnails/`
export const avatarsUrl = `${baseUrl}/avatars/`

// Tranlations
export const translations = `${baseUrl}/translations`

// Janus

export const turnServer = {
  host: 'turn02.bytewise.it:443?transport=tcp',
  user: 'bytewise',
  password: 'MZkaoq01'
}

// se deploy prod
export const janusUrl = 'wss://knowhow.maxxi.art/janus'

// Sockets
export const socketCoordinates = {
  path: '/socket',
  url: baseUrl
}

// PayPal
export const paypalClientId = 'AQ8EaEKsGPhsjGQRhrDuyv3qMAuKqnzhaRAZXzfjZcG-zkZ5jdfg30xrRz1xgcP_7hjKRYsdccJ1QpYs'
// Subscriptions
// Ad oggi c'è una sola sottoscrizione possibile nel sito, dunque non essendoci una scelta
// i link devono portare all'unica disponibile, ma i valori sono comunque dinamici
export const defaultSubscriptionId = 62

export const defaultAldoRossiId = 1

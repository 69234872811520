import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { Provider } from 'react-redux'
import store from './store'
import Modal from 'react-modal'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import './fonts/Maison Neue/MaisonNeue-Black.otf'
import './fonts/Maison Neue/MaisonNeue-Bold.otf'
import './fonts/Maison Neue/MaisonNeue-Book.otf'
import './fonts/Maison Neue/MaisonNeue-Demi.otf'
import './fonts/Maison Neue/MaisonNeue-ExtraBold.otf'
import './fonts/Maison Neue/MaisonNeue-ExtraThin.otf'
import './fonts/Maison Neue/MaisonNeue-Light.otf'
import './fonts/Maison Neue/MaisonNeue-Medium.otf'
import './fonts/Maison Neue/MaisonNeue-Thin.otf'
import './fonts/Maison Neue/MaisonNeue-BlackItalic.otf'
import './fonts/Maison Neue/MaisonNeue-BoldItalic.otf'
import './fonts/Maison Neue/MaisonNeue-BookItalic.otf'
import './fonts/Maison Neue/MaisonNeue-DemiItalic.otf'
import './fonts/Maison Neue/MaisonNeue-ExtraBoldItalic.otf'
import './fonts/Maison Neue/MaisonNeue-ExtraThinItalic.otf'
import './fonts/Maison Neue/MaisonNeue-LightItalic.otf'
import './fonts/Maison Neue/MaisonNeue-MediumItalic.otf'
import './fonts/Maison Neue/MaisonNeue-ThinItalic.otf'
import './fonts/SF Pro Display/SF-Pro-Display-Bold.otf'
import './fonts/SF Pro Display/SF-Pro-Display-Light.otf'
import './fonts/SF Pro Display/SF-Pro-Display-Regular.otf'
import './fonts/SF Pro Display/SF-Pro-Display-Semibold.otf'
import './fonts/SF Pro Display/SF-Pro-Display-BoldItalic.otf'
import './fonts/SF Pro Display/SF-Pro-Display-LightItalic.otf'
import './fonts/SF Pro Display/SF-Pro-Display-RegularItalic.otf'
import './fonts/SF Pro Display/SF-Pro-Display-SemiboldItalic.otf'
import './fonts/Helvetica Neue/Helvetica-Neue.ttf'
import './style/videojs-custom-theme.css'
import CookieBot from 'react-cookiebot'
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'

const instance = createInstance({
  urlBase: 'https://stats.maxxi.art/',
  siteId: 14,
})

Modal.setAppElement('#root')
Modal.defaultStyles = {
  overlay: { ...Modal.defaultStyles.overlay, zIndex: 10000, backgroundColor: 'var(--page-background)' },
  content: {
    ...Modal.defaultStyles.content,
    zIndex: 10001,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'var(--page-background)'
  }
}
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  // @ts-ignore
  <MatomoProvider value={instance}>
    <Provider store={store}>
      <CookieBot domainGroupId={'56dfb660-84ed-4522-9275-18f20706963f'} />
      <App />
    </Provider>
  </MatomoProvider>
)

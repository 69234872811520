import * as t from './types'
import axios from 'axios'
import conf from '@configuration'
import {logger} from '@helpers'
import {shuffleToken} from '../../helpers/shuffleToken'
import {NavigateFunction} from 'react-router'
import {ThunkDispatch} from 'redux-thunk'
import {User} from '../../types/rest'
import {RootState} from '@store/types'
import {getUser} from '@store/selectors'

export const login =
  (payload: t.LoginInfo, navigate: NavigateFunction, redirectTo?: string | null) => async (dispatch: any) => {
    const url = `${conf.apiUrl}/login`
    //chiedo al server per l'autenticazione mandando username e password
    await axios
      .post(url, payload)
      .then(({ data }) => {
        if (data.user.resetPassword) {
          dispatch(loginSuccess(data))
          navigate('/reset-password')
        } else {
          dispatch(loginSuccess(data))
          navigate(redirectTo || '/')
        }
      })
      .catch((err) => {
        logger.error(err)
        if(err.response.status === 403 && err.response.data.message === "NotAllowedError: User not verified") {
          navigate('/account-verification', {state: {email: payload.email}})
        }
        dispatch(setLoginError(err.message))
        setTimeout(() => dispatch(setLoginError('')), 2000)
      })
  }
export const loginSuccess = (payload: t.LoginPayload): t.LoginActionTypes => {
  if (payload.token) {
    localStorage.setItem('token', shuffleToken(payload.token))
  }
  return { type: t.LOGIN, payload }
}
export const setToken = (payload: string): t.LoginActionTypes => {
  localStorage.setItem('token', shuffleToken(payload))
  return { type: t.SET_TOKEN, payload }
}

export const tokenRenewed = (payload: t.LoginPayload): t.LoginActionTypes => {
  localStorage.setItem('token', shuffleToken(payload.token))

  return { type: t.RENEW_TOKEN, payload }
}
export const logout = (): t.LoginActionTypes => {
  localStorage.removeItem('token')
  return { type: t.LOGOUT }
}
export const setLoginError = (payload: string): t.LoginActionTypes => ({
  type: t.LOGIN_ERROR,
  payload
})

export const changeAvatar = (payload: string | null): t.LoginActionTypes => ({
  type: t.CHANGE_AVATAR,
  payload
})

export const editUser = (payload: Partial<User>) => (dispatch: ThunkDispatch<any, any, any>, getState: () => RootState) => {
  const user = getUser(getState())
  if (!user) return

  const action = (payload: any): any => {
    return {
      type: t.EDIT_USER,
      payload
    }
  }

  dispatch(action({ ...user, ...payload }))
}

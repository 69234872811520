import React, {PropsWithChildren} from 'react'
import styled, {css} from 'styled-components'

type Props = {
  style?: React.CSSProperties
  template?: {
    rows?: string
    columns?: string
  }
  noPadding?: any
  noRadius?: any
}
/**
 * Componente per la divisione in sezioni
 * @param props.style {React.CSSProperties}
 * @param props.template.rows {string}
 * @param props.template.columns {string}
 * @param props.padding {boolean} controlla il padding del container
 * @constructor
 const isMobile = useMediaQuery(`(max-width: ${smaller})`)
 */
export const Section: React.FC<PropsWithChildren<Props>> = (props) => {
  const { style = {}, template, children } = props
  return (
    <Container style={style} template={template}>
      {children}
    </Container>
  )
}

//region Style

const Container = styled.div<Props>`
  ${({ template }) =>
    template?.columns &&
    css`
      grid-template-columns: ${template.columns};
    `};
  ${({ template }) =>
    template?.rows &&
    css`
      grid-template-rows: ${template.rows};
    `};
  max-width: clamp(400px, 80%, var(--max-content-width));
  margin: 0 auto;
`

//endregion

import initialState from '../store/initialState'
import { RootState } from '@store/types'
import { ThunkDispatch } from 'redux-thunk'
import EventEmitter from 'events'

export abstract class ApiWithRedux extends EventEmitter {
  private _reduxDispatcher: ThunkDispatch<any, any, any> | null
  private _reduxGetState: () => RootState

  constructor() {
    super()
    this._reduxDispatcher = null
    this._reduxGetState = function () {
      return initialState
    }
  }

  set reduxDispatcher(value: ThunkDispatch<any, any, any> | null) {
    this._reduxDispatcher = value
  }

  set reduxGetState(value: () => RootState) {
    this._reduxGetState = value
  }

  dispatch(action: any) {
    if (!this._reduxDispatcher) return
    this._reduxDispatcher(action)
  }

  get state(): RootState {
    return this._reduxGetState()
  }
}

import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import login from './login/reducer'
import meeting from './meeting/reducer'
import messages from './messages/reducer'
import applicationState from './applicationState/reducer'
import localization from './localization/reducer'
import { connectRedux } from '../api'

const rootReducer = combineReducers({
  login,
  meeting,
  messages,
  applicationState,
  localization
})

const composeEnhancers = (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

const middlewares = []
middlewares.push(thunk)

if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({ collapsed: true })
  middlewares.push(logger)
}

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)))

connectRedux(store.dispatch, store.getState)

export default store

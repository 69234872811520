import {ApiWithRedux} from '../ApiWithRedux'
import {Live} from '../../types/rest'
import {logger} from '@helpers'
import socketManager, {meeting_join, meeting_leave} from '../socket'
import {MeetingFromServer} from '../../store/meeting/types'
import {setMeeting} from '@store/actions'
import moment from 'moment'
import {getMeeting, getSelectedDevices, getUser} from '@store/selectors'
import conf from '@configuration'
import Janus, {RenegotiateOptions, Videoroom} from '../../libs/janus'

class MeetingManager extends ApiWithRedux {
  private janus: Janus | null = null
  private live: Live | null = null
  private pin: string = ''
  private room: number = 0
  private publisherSession: Videoroom | null = null
  private subscriberSessions: Map<number, Videoroom> = new Map()
  private talking: Talking[] = []
  private lastTalking: Talking | null = null
  private streams: RemoteStreams = {}

  constructor() {
    super()
    window.addEventListener('beforeunload', this.leave)
  }
  // Inizializza janus e fa il join al meeting
  async startMeeting(live: Live) {
    if (this.janus) {
      logger.debug('Not starting meeting since Janus was already initialized')
      return
    }
    logger.silly('Starting meeting')
    const user = getUser(this.state)
    if (!user) {
      logger.error('Could not start meeting becase there is no user logged in')
      throw new Error('no_user')
    }
    this.checkLive(live)
    this.live = live
    this.janus = new Janus()

    // provo ad inizializzare janus, in caso di errore ritento ogni 3s
    try {
      await this.janus.init(conf.janusUrl, conf.turnServer, false)
    } catch (e) {
      logger.error(e)
      await delay(3)
      this.reset()
      await this.startMeeting(live)
    }

    // se la connessione a janus decade, resetta tutto e riprova a connettersi
    this.janus.once('janus-error', async () => {
      logger.error('janus disconnected')
      await this.janus?.destroy()
      this.reset()
    })

    this.pin = live.pin!
    this.room = live.room!
    // Inizializzo l'oggetto sessione da publisher
    try {
      logger.silly('Janus initialized, creating publisher session')
      this.publisherSession = await this.janus.getVideoroom()
    } catch (e) {
      logger.error(e)
      return
    }

    // Intercetto gli eventi che si verificheranno sulla sessione
    this.bindJanusPublisherSessionEvents()
    // Entro nella room appena creata come publisher
    try {
      await this.publisherSession.join('publisher', {
        room: live.room || 0,
        pin: live.pin || '',
        displayName: String(user.id)
      })
    } catch (e) {
      logger.error(e)
      return
    }

    socketManager.send(meeting_join, live.id, (meeting: MeetingFromServer | null) => {
      logger.debug('meeting_join event feedback received', meeting)
      if (meeting) this.dispatch(setMeeting(meeting))
    })
  }

  // Gestione degli eventi relativi alla publisher session
  bindJanusPublisherSessionEvents() {
    if (!this.publisherSession) {
      logger.debug('Could not bind publisher session events, missing publisher session')
      return
    }
    logger.silly('Binding publisher session events')
    this.publisherSession.on('joined', async (info: any) => {
      logger.debug('JOINED: ', info)
      if (this.publisherSession) this.publisherSession.private_id = info.private_id

      // await this.publisherSession.sendStream({ audio: true })
      // Se nella videoroom ci sono già dei publisher, sottoscrivo ogni flusso come subscriber
      await Promise.all(
        info.publishers.map((publisher: any) => {
          publisher.streams.forEach((s: any) => {
            s.id = publisher.id
            s.display = publisher.display
          })
          return this.createSubscriberSession(publisher)
        })
      )
    })

    this.publisherSession.on('leaving', (publisherID: number) => {
      logger.debug('LEAVING: ', publisherID)
      this.subscriberSessions.delete(publisherID)
      delete this.streams[publisherID]
      this.emit('streams-change', this.streams)
    })

    this.publisherSession.on('publishers', async (publishers: any) => {
      logger.debug('PUBLISHERS: ', publishers)
      await Promise.all(
        publishers.map((publisher: any) => {
          publisher.streams.forEach((s: any) => {
            s.id = publisher.id
            s.display = publisher.display
          })
          return this.createSubscriberSession(publisher)
        })
      )
    })

    this.publisherSession.on('talking', ({ id, audio }: { id: number; audio: number }) => {
      logger.silly('STARTTALKING: ', { id, audio })

      const key = this.getKeyByPublisherId(id)
      this.talking.push({ id, audio, key })
      this.lastTalking = { id, audio, key }
      this.emit('talking-change', this.talking, this.lastTalking)
    })

    this.publisherSession.on('stopped-talking', ({ id, audio }: { id: number; audio: number }) => {
      logger.silly('STOPTALKING: ', { id, audio })
      this.talking.filter((t) => t.id !== id)
      this.emit('talking-change', this.talking, this.lastTalking)
    })

    this.publisherSession.on('already_exists', async () => {
      await this.leave()
      window.setTimeout(() => window.location.reload(), 2000)
    })
  }

  // Creazione e gestione eventi relativi alla subscriber session
  async createSubscriberSession(publisher: any) {
    if (!this.janus) {
      logger.debug('Could not create subscriber session, janus is null')
      return
    }

    // Ho già sottoscritto la sessione per questo publisher, non va fatto nulla
    const session = this.subscriberSessions.get(publisher.id)
    if (session) {
      session.removeAllListeners()
    }

    let subscriberSession: Videoroom

    logger.silly('Creating subscriber session', publisher)

    try {
      subscriberSession = await this.janus.getVideoroom({
        subscriberAnswerMediaAudioSend: true,
        subscriberAnswerMediaVideoSend: true
      })
    } catch (e) {
      logger.error(e)
      return
    }

    // Memorizzo la subscriberSession per questo publisher
    this.subscriberSessions.set(publisher.id, subscriberSession)

    logger.debug('Joined as subscriber for ' + publisher.id)

    // Aggiungo i lister per gli eventi che si verificano sulla subscriber session
    subscriberSession.on('remotetrack', async (track: MediaStreamTrack, mid: string, on: boolean) => {
      if (on) {
        logger.silly('Remote track added', track)
        // La traccia è stata aggiunta
        let remoteStream
        if (this.streams[publisher.id]) {
          // Crea una copia per aggiornare il riferimento e scaturire un rerender
          remoteStream = {
            ...this.streams[publisher.id],
            stream: new MediaStream(this.streams[publisher.id].stream)
          }
          logger.silly('Stream found', remoteStream)
        } else {
          remoteStream = {
            key: publisher.display,
            stream: new MediaStream()
          }
          logger.silly('Stream created', remoteStream)
        }
        this.streams[publisher.id] = remoteStream

        remoteStream.stream.addTrack(track.clone())
        this.emit('streams-change', this.streams)
      } else {
        logger.silly('Remote track removed', track)
        let remoteStream = this.streams[publisher.id]
        if (!remoteStream) {
          logger.silly('Stream not found for ' + publisher.id)
          // Deve esserci altrimenti non si deve togliere da nessuno stream
          return
        }
        // Crea una copia per aggiornare il riferimento e scaturire un rerender
        remoteStream = { ...remoteStream, stream: new MediaStream(remoteStream.stream) }
        this.streams[publisher.id] = remoteStream
        logger.silly('Stream found', remoteStream, remoteStream.stream.getTracks())
        remoteStream.stream.getTracks().forEach((t) => {
          if (t.kind === track.kind && t.label === track.label) {
            logger.debug('removing track', t.id)
            remoteStream.stream.removeTrack(t)
          }
        })
        if (remoteStream.stream.getTracks().length === 0) delete this.streams[publisher.id]
        this.emit('streams-change', this.streams)
      }
    })

    subscriberSession.on('cleanup', () => {
      this.subscriberSessions.delete(publisher.id)
    })
    // Prepare the streams to subscribe to, as an array: we have the list of
    // streams the feed is publishing, so we can choose what to pick or skip
    const subscriptions = publisher.streams.map((s: any) => ({
      feed: s.id,
      mid: s.mid
    }))

    // Mi unisco alla room come subscriber per il publisher corrente
    try {
      await subscriberSession.join('subscriber', {
        room: this.room || 0,
        pin: this.pin || '',
        publisher: publisher.id,
        subscriber: this.publisherSession?.private_id,
        streams: subscriptions
      })
    } catch (e) {
      logger.error(e)
      return
    }
  }

  // Rinegozia l'offerta con i nuovi dispositivi selezionati
  changeDevices() {
    if (!this.publisherSession) return
    const selectedDevices = getSelectedDevices(this.state)
    return this.publisherSession
      .renegotiate({
        replaceVideo: false,
        replaceAudio: true,
        audio: selectedDevices.microphone ? { deviceId: selectedDevices.microphone.deviceId } : true
      })
      .catch((e) => logger.error('Could not renegotiate session for new device', e))
  }

  // Rinegozia la sessione webrtc per cambiare stream o device utilizzati
  renegotiate(opts: RenegotiateOptions) {
    if (!this.publisherSession) throw new Error('MeetingManager::renegotiate publisher session not defined')
    return this.publisherSession.renegotiate(opts)
  }

  // Esce dalla room
  leave() {
    if (this.publisherSession && this.live) {
      this.publisherSession.leave().catch((e) => logger.error(e))
      this.janus?.destroy().catch((e) => logger.error(e))
      socketManager.send(meeting_leave, this.live.id!)
      this.reset()
    }
  }

  // Resetta tutte le informazioni del meeting per reinizzializzarlo
  reset() {
    this.janus = null
    this.live = null
    this.pin = ''
    this.room = 0
    this.publisherSession = null
    this.subscriberSessions = new Map()
    this.talking = []
    this.lastTalking = null
    this.streams = {}
  }

  getKeyByPublisherId(publisherId: number): string {
    return this.streams[publisherId]?.key || ''
  }

  // Recupera lo stream dalla chiave (ovvero id utente o username staff)
  getStreamByKey(key: string) {
    let result = new MediaStream()
    for (let [_, value] of Object.entries(this.streams)) {
      if (value.key === key) {
        result = new MediaStream(value.stream)
        break
      }
    }
    return result
  }

  // Recupera il display name corretto dalla chiave (ovvero id utente o username staff)
  getDisplayNameByKey(key: string) {
    const meeting = getMeeting(this.state)
    const parsedKey = parseInt(key)
    if (!isNaN(parsedKey)) {
      // si tratta di uno user
      const user = meeting.users.get(parsedKey)
      return `${user?.name || 'U'} ${user?.surname || 'U'}`
    } else {
      // è un membro dello staff
      const staff = meeting.staff.get(key)
      return `${staff?.name || 'U'} ${staff?.surname || 'U'}`
    }
  }

  getDisplayNameByPublisherId(publisherId: number) {
    const key = this.streams[publisherId]?.key
    if (key) {
      return this.getDisplayNameByKey(key)
    } else {
      return 'U U'
    }
  }

  // Controlla se la live è pronta
  checkLive(live: Live) {
    logger.silly('Checking if the live is in the right state')
    if (!live.room) {
      // Se la live non è ancora iniziata, controllo il motivo
      const start = moment(live.start)
      const today = moment()
      if (start.isSame(today, 'day')) {
        logger.silly('Live will be today but has not been started yet')
        // Verrà tenuta oggi, ma non è ancora iniziata
        throw new Error('not_started')
      } else if (start.isBefore(today)) {
        logger.silly('Live already finished')
        // è stata tenuta un giorno passato
        throw new Error('already_finished')
      } else {
        logger.silly('Live will not be today')
        // sarà tenuta un giorno futuro
        throw new Error('not_today')
      }
    }
  }
}

export default new MeetingManager()

export interface RemoteStreams {
  [id: number]: {
    key: string
    stream: MediaStream
  }
}

export interface Talking {
  id: number
  key: string
  audio: number
}

const delay = (sec: number) => new Promise((resolve) => setTimeout(resolve, sec * 1000))

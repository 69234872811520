import styled from 'styled-components'
import { VideoPlayer } from './VideoPlayer'
import conf from '@configuration'
import React from 'react'

interface Props {
  thumbnail: string
  videoUrl: string
}

export const VideoCard: React.FC<Props> = ({ videoUrl, thumbnail }) => {
  return (
    <Container>
      <Thumbnail />
      <VideoPlayer
        entityName="trailers"
        options={{
          autoplay: true,
          controls: false,
          muted: true,
          loop: true,
          sources: [
            {
              src: `${conf.wowzaUrl}${videoUrl}${conf.wowzaOption}`
            }
          ]
        }}
        style={{ margin: '0', maxWidth: '100%' }}
        poster={thumbnail}
      />
      <Info>TESTO</Info>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-rows: 6fr 4fr;
  height: 100%;
  gap: 2rem;
`

const Thumbnail = styled.div`
  border: 1px solid black;
  width: 100%;
  height: 100%;
`

const Info = styled.div``

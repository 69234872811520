import React from 'react'
import styled from 'styled-components'

const SimpleCircle: React.FC<Props> = ({ width, height, hovered, color }) => {
  return (
    <Container color={color} width={width || 40} height={height || 40}>
      {hovered && <InnerCircle color={color} width={width || 40} height={height || 40} />}
    </Container>
  )
}

export default SimpleCircle

type Props = {
  width?: number
  height?: number
  hovered?: boolean
  color?: string
}
//region Style

const Container = styled.div<{ width: number; height: number; color?: string }>`
  border-radius: 50%;
  border: 5px ${(props) => (props.color ? props.color : 'var(--primary)')} solid;
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  place-self: center;
  display: grid;
  place-content: center;
`

const InnerCircle = styled.div<{ width: number; height: number; color?: string }>`
  width: ${(props) => `${props.width - props.width / 2}px`};
  height: ${(props) => `${props.height - props.height / 2}px`};
  background: ${(props) => (props.color ? props.color : 'linear-gradient(#6353a9, #7c6ab1)')};
  border-radius: 50%;
`

//endregion

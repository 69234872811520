import {defaultAldoRossiId} from './local'

export type Configuration = {
  baseUrl: string
  apiUrl: string
  adminApi: string
  wowzaUrl: string
  wowzaOption: string
  wowzaLiveUrl: string
  thumbnailUrl: string
  avatarsUrl: string
  translations: string
  janusUrl: string
  turnServer: {
    host: string
    user: string
    password: string
  }
  socketCoordinates: {
    path: string
    url: string
  }
  paypalClientId: string
  defaultSubscriptionId: number
  defaultAldoRossiId: number
  maxWidth: number
  maxHeight: number
}
let configuration: Configuration

switch (process.env.REACT_APP_ENV) {
  case 'local':
    configuration = require('./local')
    break
  case 'devel':
    configuration = require('./devel')
    break
  default:
    configuration = require('./prod')
}

export default configuration

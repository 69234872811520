import React from 'react'
import styled from 'styled-components'
import { Category } from '../../../../types/rest'
import { useDispatch, useSelector } from 'react-redux'
import {getLanguage, getLogin, getTranslation, getUser} from '@store/selectors'
import {useLocation, useNavigate} from 'react-router-dom'
import { useSpring, animated, config } from 'react-spring'
import { BigButton } from '@components/common'
import SimpleCircle from '../../../common/animated-icons/SimpleCircle'
import { logout } from '@store/actions'
import useMediaQuery from "../../../../hooks/useMediaQuery"
import {small} from "../../../../constants"
import ChangeLanguageButton from './ChangeLanguageButton'
/**
 * Componente per la visualizzazione espansa del Menu Mobile
 * @param top: pixel da distanziare dall'alto per non coprire il menu
 * @param hide: funzione per la chiusura eseguita sul click away o quando si naviga altrove
 * @param categories: categorie recuperate tramite api
 */
const ExpandedMenu: React.FC<Props> = ({ hide, categories, on }) => {
  const language = useSelector(getLanguage)
  const translation = useSelector(getTranslation)
  const user = useSelector(getUser)
  const { isAuthenticated } = useSelector(getLogin)
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery(`(max-width: ${small})`)

  const slideInStyles = useSpring({
    config: { ...config.stiff },
    from: { height: '0vh' },
    to: {
      height: on ? '80vh' : '0vh'
    }
  })

  if (categories[categories.length - 1]?.id && categories[categories.length - 1]?.id !== 10000)
    categories.push({ id: 10000, label: 'tutti', color: '', translations: { it: 'vedi tutti', en: 'show more' } })

  return (
    <Container onClick={() => hide()} style={{ ...slideInStyles }}>
      <Menu onClick={(e) => e.stopPropagation()}>
        <>
          <ButtonContainer>
            {!user ? (
              <BigButton
                onClick={() => navigate(`/login?redirectTo=${encodeURIComponent(location.pathname)}`)}
                style={{ display: 'grid', placeSelf: 'center', padding: '0 0.4rem', height: '50px' }}
                labelStyle={{ fontSize: '1.4rem', fontWeight: 500, placeSelf: 'center' }}
                background={'var(--page-background)'}
                color="#ffffff"
                label={`${translation.topBar.login} / ${translation.topBar.signup}`}
                icon={(hovered) => <SimpleCircle hovered={hovered} color={'#ffffff'} />}
              />
            ) : (
              <BigButton
                onClick={() => {
                  navigate(0)
                  dispatch(logout())
                }}
                style={{ display: 'grid', placeSelf: 'center', padding: '0 0.4rem', height: '50px' }}
                labelStyle={{ fontSize: '1.4rem', fontWeight: 500, placeSelf: 'center' }}
                background={'var(--page-background)'}
                color="#ffffff"
                label={`${translation.PAGES.personalArea.logout}`}
                icon={(hovered) => <SimpleCircle hovered={hovered} color={'#ffffff'} />}
              />
            )}
          </ButtonContainer>
          <Links>
            <h3 style={{ margin: 0, fontSize: 'var(--list-title-fs-mobile)', fontWeight: 700 }}>{translation.topBar.explore}</h3>
            <div style={{fontWeight: 400, fontSize: isMobile ? '22.5px' : 'inherit'}} onClick={() => {
              hide()
              navigate('/maxxi-original')
            }}>
              {translation.entities.AuthorContent}
            </div>
            <div style={{fontWeight: 400, fontSize: isMobile ? '22.5px' : 'inherit'}} onClick={() => {
              hide()
              navigate('/courses')
            }}>
              {translation.entities.masterclass}
            </div>
            <div style={{fontWeight: 400, fontSize: isMobile ? '22.5px' : 'inherit'}} onClick={() => {
              hide()
              navigate('/maxxi-library')
            }}>
              {translation.entities.archive}
            </div>
          </Links>
          <Categories>
            <p style={{ padding: '0 1rem 0 10%', margin: '1rem 0 0 0', fontWeight: 700 }}>{translation.topBar.categories}</p>
            <CategoriesList categories={categories.length}>
              {categories.map((c, k) => (
                <CategoryCard onClick={() => {
                  hide()
                   navigate(`/categories/${c.id === 10000 ? '' : c.slug || c.id}`)
                }} key={k}>
                  <CategoryLabel>{c.translations[language]}</CategoryLabel>
                </CategoryCard>
              ))}
            </CategoriesList>
          </Categories>
          <Separator />
          <Links>
            {!isAuthenticated ? (
              <>
                <ThinLink onClick={() => {
                  hide()
                  navigate('/faq')
                }} style={{ fontWeight: 400, fontSize: '22.5px' }}>
                  {translation.topBar.FAQ}
                </ThinLink>
                <ChangeLanguageButton style={{marginBottom: '0.5rem'}}/>
              </>
              )
              : (
                <>
                  <ThinLink
                    onClick={() => {
                      hide()
                      navigate('/personalarea')
                    }}
                    style={{ fontWeight: 400, fontSize: '22.5px' }}>
                    {translation.topBar.profile}
                  </ThinLink>
                  <ThinLink onClick={() => {
                    hide()
                    navigate('/get-started')
                  }} style={{ fontWeight: 400, fontSize: '22.5px' }}>
                    {translation.topBar.how}
                  </ThinLink>
                  <ThinLink onClick={() => {
                    hide()
                    navigate('/faq')
                  }} style={{ fontWeight: 400, fontSize: '22.5px'}}>
                    {translation.topBar.FAQ}
                  </ThinLink>
                  <ChangeLanguageButton style={{marginBottom: '0.5rem'}}/>
                </>
            )}
          </Links>
        </>
      </Menu>
    </Container>
  )
}

export default ExpandedMenu

type Props = {
  top: number
  hide: Function
  categories: Category[]
  on: boolean
}
//region Style

const Container = styled(animated.div)`
  position: absolute;
  top: var(--topbar-height);
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: scroll;
  overflow-x: hidden;
`

const Menu = styled.div`
  display: flex;
  justify-self: flex-end;
  flex-direction: column;
  place-content: center;
  background-color: #7c6ab1;
  color: #fff;
  width: 90%;
  gap: 1rem;
  font-weight: 600;
  font-size: var(--list-title-fs-mobile);
`

const ButtonContainer = styled.div`
  display: flex;
  place-content: center;
  padding: 2rem 1rem 1rem 1rem;
`

const Separator = styled.div`
  align-self: flex-end;
  height: 1px;
  background-color: white;
  width: 90%;
`

const Categories = styled.div`
  display: grid;
  padding: 0;
  cursor: pointer;
`

const CategoriesList = styled.div<{ categories: number }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px 1rem 1rem 10%;
  gap: 10px;
`

const CategoryCard = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  gap: 5px;
  font-weight: 400;
`

const CategoryLabel = styled.div`
  place-self: center start;
  font-size: 22.5px;
  width: max-content;
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: flex-end;
  font-weight: 400;
  gap: 10px;
  margin: 1rem 0;
  margin-left: 10%;
`

const ThinLink = styled.div`
  font-weight: 400;
  cursor: pointer;
`
//endregion

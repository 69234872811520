import React, { CSSProperties, useEffect, useRef, useState, VideoHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { logger } from '@helpers'
import { ControlButton } from './ControlButton'
import { SliderInput } from './SliderInput'
import { ReactComponent as ImgVolumeOff } from '../../images/icons/volume_off.svg'
import { ReactComponent as ImgVolumeDown } from '../../images/icons/volume_down.svg'
import { ReactComponent as ImgVolumeUp } from '../../images/icons/volume_up.svg'
import { ReactComponent as ImgVolumeMute } from '../../images/icons/volume_mute.svg'

interface Settings {
  volume: number
  isFullscreen: boolean
  muted: boolean
}

type Props = VideoHTMLAttributes<HTMLVideoElement> & {
  stream: MediaStream
  displayName?: string
  style?: CSSProperties
  videoStyle?: CSSProperties
  noVideoComponent?: () => any
  withoutSettings?: boolean
  width?: string
  height?: string
}

export const Video: React.FC<Props> = (props) => {
  const {
    stream,
    displayName,
    noVideoComponent,
    style,
    videoStyle,
    withoutSettings = false,
    width = 'unset',
    height = '100%',
    ...rest
  } = props
  const refVideo = useRef<HTMLVideoElement>(null)
  const [hasAudio, setHasAudio] = useState(true)
  const [hasVideo, setHasVideo] = useState(true)
  const [showSettings, setShowSettings] = useState(false)
  const [showVolume, setShowVolume] = useState(false)
  const [settings, setSettings] = useState<Settings>({
    muted: !!props.muted,
    isFullscreen: false,
    volume: 1
  })

  useEffect(() => {
    if (!refVideo.current) return
    if (!stream) {
      setHasVideo(false)
      setHasAudio(false)
      return
    }
    // Quando il publisher cambia le sue impostazioni, non viene mandato un nuovo stream ma viene
    // direttamente mutato lo stesso stream, quindi per accorgersi dei cambiamenti sfruttiamo questi
    // event listeners sull'oggetto stream.
    function onTrackChange(this: MediaStream) {
      setHasVideo(!!this.getVideoTracks()[0])
      setHasAudio(!!this.getAudioTracks()[0])
    }
    stream.addEventListener('addtrack', onTrackChange)
    stream.addEventListener('removetrack', onTrackChange)
    const newHasVideo = !!stream.getVideoTracks()[0]
    const newHasAudio = !!stream.getAudioTracks()[0]
    setHasVideo(newHasVideo)
    setHasAudio(newHasAudio)
    if (!newHasAudio) setSettings({ ...settings, muted: !!rest.muted })

    try {
      refVideo.current.srcObject = stream
    } catch (e) {
      try {
        refVideo.current.src = URL.createObjectURL(stream as any)
      } catch (e) {
        logger.error('Error attaching stream to element')
      }
    }

    return () => {
      stream.removeEventListener('addtrack', onTrackChange)
      stream.removeEventListener('removetrack', onTrackChange)
    }
  }, [stream])

  useEffect(() => {
    if (!refVideo.current) return
    refVideo.current.volume = settings.volume
  }, [settings])

  const getIconForVolume = () => {
    if (settings.volume === 0) return ImgVolumeMute
    if (settings.volume <= 0.5) return ImgVolumeDown

    return ImgVolumeUp
  }

  return (
    <Container
      style={style || {}}
      onMouseEnter={() => !withoutSettings && setShowSettings(true)}
      onMouseLeave={() => !withoutSettings && setShowSettings(false)}
      fullscreen={settings.isFullscreen}>
      <VideoElement
        style={videoStyle ? { ...videoStyle, width, height } : { width, height }}
        show={hasVideo}
        ref={refVideo}
        muted={settings.muted}
        {...rest}
      />
      {!withoutSettings && showSettings && (
        <SettingsContainer>
          <VolumeContainer>
            {showVolume && (
              <SliderInput
                value={settings.volume}
                onChange={(volume: number) => setSettings({ ...settings, volume })}
                onMouseLeave={() => setShowVolume(false)}
              />
            )}
            <ControlButton
              iconActive={getIconForVolume()}
              onClick={() => {
                if (!hasAudio) return
                setShowVolume(settings.muted)
                setSettings({ ...settings, muted: !settings.muted })
              }}
              isActive={!settings.muted}
              iconDisactive={ImgVolumeOff}
              containerStyle={{ zIndex: 9003, marginTop: 10 }}
              onMouseEnter={() => !settings.muted && setShowVolume(true)}
            />
          </VolumeContainer>
        </SettingsContainer>
      )}
      <NoVideo show={!hasVideo}>{noVideoComponent ? noVideoComponent() : 'No Video'}</NoVideo>
    </Container>
  )
}

//region style

const Container = styled.div<{ fullscreen: boolean }>`
  ${({ fullscreen }) =>
    fullscreen
      ? css`
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: grid;
          background-color: var(--page-background);
          place-items: center;
        `
      : css`
          position: relative;
          width: fit-content;
          display: grid;
          place-items: center;
        `};
  place-self: center;
`

type VideoElementProps = {
  show: boolean
}
const VideoElement = styled.video<VideoElementProps>`
  ${({ show }) => !show && 'display: none;'};
  aspect-ratio: 16 / 9;
  border-radius: 10px;
  box-sizing: border-box;
`

const NoVideo = styled.div<{ show: boolean }>`
  ${({ show }) => (!show ? 'display: none;' : 'display: grid')};
  aspect-ratio: 16 / 9;
  background-color: transparent;
  font-size: 2rem;
  place-items: center;
`

const SettingsContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9002;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 10px;
`

const VolumeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  z-index: 9003;
`
//enregion

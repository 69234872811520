import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {getLogin} from '@store/selectors'
import React, {useEffect, useState} from 'react'
import {logout, tokenRenewed} from '@store/actions'
import conf from '@configuration'
import axios from 'axios'
import {logger} from '@helpers'
import {LoginPayload} from '../../store/login/types'
import socketManager from '../../api/socket'
import {shuffleToken} from '../../helpers/shuffleToken'

/**
 * Manager per l'autenticazione
 */
export const AuthManager: React.FC = (): null => {
  const navigate = useNavigate()
  const { isAuthenticated } = useSelector(getLogin)
  const dispatch = useDispatch()

  const [renewTimeout, setRenewTimeout] = useState<number | null>(null)

  useEffect(() => {
    if (isAuthenticated) return
    const tokenToUse = shuffleToken(localStorage.getItem('token'))

    if (tokenToUse) {
      getNewToken(tokenToUse)
        .then((loginPayload) => {
          dispatch(tokenRenewed(loginPayload))
          const lastPath = sessionStorage.getItem('lastPath')
          const pathOnStart = sessionStorage.getItem('startPath')
          sessionStorage.removeItem('lastPath')
          sessionStorage.removeItem('pathOnStart')
          if (lastPath === pathOnStart) navigate(lastPath || '/')
          else if (pathOnStart === '/login') navigate(lastPath || '/')
          else navigate(pathOnStart || '/')
        })
        .catch((e) => {
          console.error('Could not renew token', e)
          dispatch(logout())
          navigate('/')
        })
    }
  }, [isAuthenticated, dispatch, navigate])

  // Gestione del renewTimeout
  useEffect(() => {
    if (!isAuthenticated && renewTimeout) {
      clearTimeout(renewTimeout)
      setRenewTimeout(null)
    }
  }, [isAuthenticated, renewTimeout])

  // Gestore della connessione / disconnessione alla socket
  useEffect(() => {
    if (isAuthenticated) {
      socketManager
        .connect()
        .then(() => logger.debug('Socket connected'))
        .catch((e) => logger.error(e))
    } else {
      socketManager
        .disconnect()
        .then(() => logger.debug('Socket disconnected'))
        .catch((e) => logger.error(e))
    }
  }, [isAuthenticated])

  // Gestore della logica di autenticazione
  useEffect(() => {
    const renewToken = () => {
      const tokenToUse = shuffleToken(localStorage.getItem('token'))
      if (!tokenToUse) {
        dispatch(logout())
        navigate('/')
      }
      // Intervallo random tra 30 e 50 minuti
      const randomInterval = (Math.random() * 20 + 30) * 60000
      const timeout = window.setTimeout(() => {
        getNewToken(tokenToUse)
          .then((renewPayload) => {
            dispatch(tokenRenewed(renewPayload))
            renewToken()
          })
          .catch(() => {
            dispatch(logout())
            navigate('/')
          })
      }, randomInterval)
      setRenewTimeout(timeout)
    }

    if (isAuthenticated) {
      renewToken()
    }
  }, [isAuthenticated, dispatch, navigate])

  return null
}

// region helpers

//richiede un nuovo token al server
const getNewToken = async (token: string) => {
  try {
    logger.debug('Getting new token from server')
    const { data } = await axios.get(`${conf.apiUrl}/renew-token`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    logger.debug('Got response from server')
    return data as LoginPayload
  } catch (e) {
    logger.error(e)
    throw e
  }
}

// endregion

import { User } from '../../types/rest'

export const LOGIN = 'LOGIN'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGOUT = 'LOGOUT'
export const SET_TOKEN = 'SET_TOKEN'
export const RENEW_TOKEN = 'RENEW_TOKEN'
export const CHANGE_AVATAR = 'CHANGE_AVATAR'
export const EDIT_USER = 'EDIT_USER'

export interface LoginInfo {
  email: string
  password: string
}

export interface ResetPasswordInfo {
  email: string
}

export interface LoginState {
  user: User | null
  token: string | null
  error: string | null
  isAuthenticated: boolean
}

//region Types

export interface LoginPayload {
  user: User
  token: string
}

//endregion

//region Actions

export interface LoginAction {
  type: typeof LOGIN
  payload: LoginPayload
}

export interface LoginSuccessAction {
  type: typeof LOGIN
  payload: LoginState
}

export interface SetTokenAction {
  type: typeof SET_TOKEN
  payload: string
}

export interface LogoutAction {
  type: typeof LOGOUT
}

export interface LoginErrorAction {
  type: typeof LOGIN_ERROR
  payload: string
}

export interface RenewTokenAction {
  type: typeof RENEW_TOKEN
  payload: LoginPayload
}

export interface ChangeAvatarAction {
  type: typeof CHANGE_AVATAR
  payload: string | null
}

export interface EditUserAction {
  type: typeof EDIT_USER
  payload: User
}

export type LoginActionTypes =
  | LoginAction
  | LogoutAction
  | SetTokenAction
  | LoginErrorAction
  | RenewTokenAction
  | LoginSuccessAction
  | ChangeAvatarAction
  | EditUserAction

//endregion

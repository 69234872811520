import {InputLegacy} from "./InputLegacy"
import {ErrorMessage} from "@hookform/error-message"
import React, {useEffect, useRef, useState} from "react"
import styled from "styled-components"
import {useForm} from "react-hook-form"
import {useSelector} from "react-redux"
import {getTranslation} from "@store/selectors"
import Check from '../../images/icons/check.svg'
import Arrow from '../../images/icons/arrowdown.svg'
import {PriceInfo, User} from '../../types/rest'

interface Props {
  onSubmit:( data: FormValues) => void
  user?: User | null
  cannotPay: ()=> void
}

export const BillingForm: React.FC<Props> = ({onSubmit, user,cannotPay}) => {
  // Se l'utente si è registrato come azienda, la fattura è obbligatoria
  // Quindi setto il default della variabile withBill a true e non mostro la spunta per richiedere la fattura
  const [withBill, setWithBill] = useState(!!user?.isCompany)
  const [displayForm, setDisplayForm] = useState(false)
  const [displayContract, setDisplayContract] = useState(false)
  const [acceptedContract, setAcceptedContract] = useState(false)
  const [readyToPay, setReadyToPay] = useState(false)
  const [rerender, setRerender] = useState(false)

  const translation = useSelector(getTranslation)

  const ref = useRef(null)
  const contractRef = useRef(null)
  const {handleSubmit, register, setValue, getValues, formState: { errors }} = useForm<FormValues>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues
  })

  useEffect(()=>{ setRerender((prev)=> !prev) },[displayForm, displayContract, withBill])
  useEffect(()=>{
    setValue('isInvoice', withBill)
    setReadyToPay(false)
    cannotPay()
    setDisplayForm(true)
  },[withBill, setReadyToPay, setDisplayForm])
  useEffect(()=>{
    if(!user) return
    user.name && setValue('name', user.name)
    user.surname && setValue('surname', user.surname)
    user.cap && setValue('zipCode', user.cap)
    user.email && setValue('email', user.email)
  },[user, setValue])

  const beforeSubmit = (data: FormValues) =>{
    if(!acceptedContract) return
    setReadyToPay(true)
    onSubmit(data)
    setDisplayForm(false)
    setDisplayContract(false)
  }

  return <Container onSubmit={handleSubmit(beforeSubmit)}>
    {
      !user?.isCompany &&
        <BillCheckBox>
          <Toggle active={withBill} onClick={() => setWithBill((prev) => !prev)}>
            {withBill && <img src={Check} alt={'bill checkbox'}/>}
          </Toggle>
          <span style={{height: 'min-content'}}>
            {translation.PAGES.pay.wantBill}
          </span>
        </BillCheckBox>
    }
    <Data active={displayForm} height={displayForm && (ref?.current as any).offsetHeight ? (ref?.current as any)?.offsetHeight : 0}>
      <DataBanner active={displayForm} onClick={()=>setDisplayForm((prev)=> !prev)}>
        <Title>form di inserimento dati {readyToPay && <Done src={Check}/> } </Title><span ><img style={{height: '100%',filter: 'invert(100%)' ,transform: displayForm ? '  transform: rotate(180deg)': 'rotate(360deg)'}} alt="arrow" src={Arrow}/></span>
      </DataBanner>
      <div style={{paddingTop: '2rem'}} ref={ref}>
        <div>
          <InputLegacy
            input={register('name', {
              required: {value: getValues().company?.trim() === ''  , message: translation.common.errors.required}
            })}
            label={`${translation.common.fields.name}${getValues().company !== ''? '' : '*'}`}
            style={{labelColor: '#bebebe'}}
            error={<ErrorMessage name="name" errors={errors}/>}
          />
          <InputLegacy
            input={register('surname', {
              required: {value: getValues().company?.trim() === '', message: translation.common.errors.required}
            })}
            label={`${translation.common.fields.surname}${getValues().company !== ''? '' : '*'}`}
            style={{labelColor: '#bebebe'}}
            error={<ErrorMessage name="surname" errors={errors}/>}
          />
          {withBill && <InputLegacy
            input={register('company', {
              required: {value: !(getValues().name.trim() || getValues().surname.trim() !== '') , message: translation.common.errors.required}
            })}
            label={`${translation.common.fields.company}${getValues().name || getValues().surname !== '' ? '' :  '*' }`}
            style={{labelColor: '#bebebe'}}
            error={<ErrorMessage name="company" errors={errors}/>}
          />}
          <InputLegacy
            input={register('address', {
              required: {value: true, message: translation.common.errors.required}
            })}
            label={`${translation.common.fields.billingAddress}*`}
            style={{labelColor: '#bebebe'}}
            error={<ErrorMessage name="address" errors={errors}/>}
          />
          <InputLegacy
            input={register('city', {
              required: {value: true, message: translation.common.errors.required}
            })}
            label={`${translation.common.fields.billingCity}*`}
            style={{labelColor: '#bebebe'}}
            error={<ErrorMessage name="city" errors={errors}/>}
          />
          <InputLegacy
            input={register('zipCode', {
              required: {value: true, message: translation.common.errors.required},
              pattern: { value: zipCodeRegex , message: 'zipcode non conforme' }

            })}
            label={`${translation.common.fields.billingCap}*`}
            style={{labelColor: '#bebebe'}}
            error={<ErrorMessage name="zipCode" errors={errors}/>}
          />
          <InputLegacy
            input={register('taxCode', {
              required: {value: getValues().vatNumber?.trim() === '', message: withBill ? translation.common.errors.vatNumberOrTaxCodeMandatory : translation.common.errors.required},
              pattern:{value:  codiceFiscaleRegEx, message: 'codice fiscale non valido'}
            })}
            label={`${translation.common.fields.billingCodePers}${getValues().vatNumber?.trim() !== '' ? '' : '*'}`}
            style={{labelColor: '#bebebe'}}
            error={<ErrorMessage name="taxCode" errors={errors}/>}
          />
          {withBill && <InputLegacy
            input={register('vatNumber', {
              required: {value: getValues().taxCode.trim() === '', message: translation.common.errors.vatNumberOrTaxCodeMandatory},
            })}
            label={`${translation.common.fields.billingVatNumber}${getValues().taxCode?.trim() !== '' ? '' : '*'}`}
            style={{labelColor: '#bebebe'}}
            error={<ErrorMessage name="vatNumber" errors={errors}/>}
          />}
          {withBill && <InputLegacy
            input={register('sdi', {
              required: {value: withBill, message: translation.common.errors.required}
            })}
            label={`${translation.common.fields.billingECode}*`}
            style={{labelColor: '#bebebe'}}
            error={<ErrorMessage name="sdi" errors={errors}/>}
          />}
          <InputLegacy
            input={register('email', {
              required: {value: true, message: translation.common.errors.required},
              pattern: {value: mailRegEx ,message:'mail non conforme'}
            })}
            label={`${translation.common.fields.billingEmail}*`}
            style={{labelColor: '#bebebe'}}
            error={<ErrorMessage name="email" errors={errors}/>}
          />
        </div>
      </div>
    </Data>
    <BillCheckBox>
      <Toggle active={acceptedContract} onClick={()=> setAcceptedContract((prev)=> !prev)}>{acceptedContract && <img alt="check" src={Check}/>}</Toggle>
      <span style={{height: 'min-content'}}>{translation.PAGES.pay.acceptTermsContract} <a href={'/contract'} target='_blank' style={{color: 'var(--primary)'}}>{translation.PAGES.pay.contract}</a></span>
    </BillCheckBox>
    <div style={{display: 'flex', justifyContent: "center"}} ><SubmitButton type='submit' disabled={!acceptedContract } onClick={()=> setDisplayForm(true)}>sottoscrivi dati</SubmitButton></div>
  </Container>
}

// region Style
const Container = styled.form`
  margin: 0 auto;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
const BillCheckBox = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 1rem 10px;
  gap: 1.5rem;
  border-radius: 5px;
  border: 1px solid grey;
`
const Toggle = styled.span<{active: boolean}>`
  width: 1.5rem;
  height: 1.5rem;
  background-color: ${({active})=> active ? 'var(--page-background)': 'var(--page-background)'};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  & > img{
    opacity: ${({active})=> active ? '1': '0'};
    transition: 0.3s;
    width: 1rem;
    height: 1rem;
  }
  border-radius: 5px;
  border: 1px solid grey;
`
const Data = styled.div<{height: number, active: boolean}>`
  width: 100%;
  border-radius: 5px;
  border: 1px solid grey;
  height: ${({height, active})=> height !== 0 && active ? `calc(${height}px + 100px)`:'50px'};
  transition: 0.7s;
  & > div > div > div {
    ${({active})=> active ? ` `:''}
    transition-delay: 0.5s;
    transition: 1s;
  }
  overflow: hidden;
`
const Contract = styled.div<{height: number, active: boolean}>`
  max-width: 100%;
  border-radius: 5px;
  border: 1px solid grey;
  height: ${({height, active})=> height !== 0 && active ? `calc(${height}px + 50px)`:'50px'};
  transition: 0.7s;
  overflow: hidden;
`
const Content = styled.div<{active: boolean, width?: number}>`
  transition-delay: ${({active})=> !active ? `0.5s`:'0'};
  transition: 1s;
  width: ${({width})=> width !== 0 ?  `calc(${width} - 20px)` : '2rem'};
`
const DataBanner = styled.div<{active: boolean}>`
  padding: 1rem 10px;
  padding-right: 2rem;
  ${({active})=> active ? 'border-bottom: 1px solid grey;' : ''} 
  display: flex;
  justify-content: space-between;
  transition: 1s;
`
const Title = styled.span`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const SubmitButton = styled.button<{disabled: boolean}> `
  background-color: ${({disabled}) => disabled ? 'grey': 'transparent'};
  ${({disabled}) => disabled ? '': 'cursor: pointer;'}
  width: 40%;
  color: white;
  padding: 1rem 2rem;
  border: 1px solid grey;
  border-radius: 5px;
  transition: 0.4s;
  ${({disabled}) => disabled ? '': '&:hover{ background-color: var(--primary); border: 1px solid transparent; transition: 0.4s; }'};
`
const Done = styled.img`
  width: 1rem;
`
// endregion

// region RegEx
const mailRegEx = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
const codiceFiscaleRegEx = /^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})|([0-9]{11})$/
const partitaIvaRegEx = /((DK|FI|HU|LU|MT|SI)(-)?\d{8})|((BE|EE|DE|EL|LT|PT)(-)?\d{9})|((PL|SK)(-)?\d{10})|((IT|LV)(-)?\d{11})|((LT|SE)(-)?\d{12})|(AT(-)?U\d{8})|(CY(-)?\d{8}[A-Z])|(CZ(-)?\d{8,10})|(FR(-)?[\dA-HJ-NP-Z]{2}\d{9})|(IE(-)?\d[A-Z\d]\d{5}[A-Z])|(NL(-)?\d{9}B\d{2})|(ES(-)?[A-Z\d]\d{7}[A-Z\d])/
const zipCodeRegex = /^\d{5}(-\d{4})?$/
const phoneNumberRegEx = /^([0-9]*\-?\ ?\/?[0-9]*)$/
//endregion

const defaultValues = {
  name: '',
  surname: '',
  address: '',
  zipCode: '',
  taxCode: '',
  sdi: '',
  email: '',
  city: '',
  vatNumber: '',
  company: ''
}

export default interface FormValues {
  name: string
  surname: string
  address: string
  zipCode: string
  taxCode: string
  sdi?: string
  city: string
  email: string
  vatNumber?: string
  company?: string
  isInvoice?: boolean
  productPrice?: PriceInfo
}

import { Translation } from 'translations/type'
import { LanguagesSpecializations, Languages } from '../../types/rest'
import { EditUserAction, LoginAction, RenewTokenAction } from '../login/types'

export const SET_TRANSLATION = 'SET_TITLE'
export const SET_LANGUAGE = 'SET_LANGUAGE'

//region Types

export interface LocalizationState {
  language: Languages
  languageSpec: LanguagesSpecializations
  translation: Translation
}

//endregion

//region Actions

export interface SetTranslationAction {
  type: typeof SET_TRANSLATION
  payload: Translation
}

export interface SetLanguageAction {
  type: typeof SET_LANGUAGE
  payload: Languages
}

export type LocalizationActionTypes = SetTranslationAction | EditUserAction | RenewTokenAction | LoginAction | SetLanguageAction

//endregion

import { Socket, io } from 'socket.io-client'
import conf from '@configuration'
import { logger } from '../../helpers'
import { ready } from './types'
import EventEmitter from 'events'
import store from '../../store'
import * as events from './types'
import {
  joinAfterDisconnection,
  setMeeting,
  staffJoined,
  staffLeft,
  userJoined,
  userLeft
} from '@store/actions'


/*
import {
  joinAfterDisconnection,
  setMeeting,
  staffJoined,
  staffLeft,
  userJoined,
  userLeft
} from '../../store/meeting/actions'

* */



import { ThunkDispatch } from 'redux-thunk'
import { Staff, User } from '../../types/rest'
import { logout, removeMessage } from '@store/actions'
import { MeetingFromServer } from '../../store/meeting/types'
import { confirmAlert } from 'react-confirm-alert'
import { shuffleToken } from '../../helpers/shuffleToken'

// Eventi da gestire in onAny, non devono apparire negli on sopra altrimenti
// vengono intercettati due volte
const eventsToDispatchOnly = [
  events.meeting_user_raised_hand,
  events.meeting_user_lowered_hand,
  events.meeting_user_can_talk_now,
  events.meeting_user_is_muted,
  events.meeting_got_new_message,
  events.meeting_end
]

export class SocketManager extends EventEmitter {
  socket: Socket
  dispatch = store.dispatch as ThunkDispatch<any, any, any>
  gotDisconnection: boolean

  constructor() {
    super()

    this.socket = io(conf.socketCoordinates.url, {
      autoConnect: false,
      path: conf.socketCoordinates.path,
      query: {
        token: shuffleToken(localStorage.getItem('token'))
      }
    })
    this.gotDisconnection = false
    this.addEventsListeners()
    window.addEventListener('beforeunload', () => this.socket.disconnect())
  }

  connect() {
    this.socket = io(conf.socketCoordinates.url, {
      autoConnect: false,
      path: conf.socketCoordinates.path,
      query: {
        token: shuffleToken(localStorage.getItem('token'))
      }
    })
    this.addEventsListeners()
    return new Promise<void>((resolve, reject) => {
      const handleError = () => reject(new Error('Could not open the socket'))
      this.socket.once(ready, () => {
        this.socket.off('error', handleError)
        resolve()
      })
      this.socket.once('error', handleError)
      this.socket.open()
    })
  }

  disconnect() {
    return new Promise<void>((resolve, reject) => {
      const handleError = () => reject(new Error('Could not close the socket'))
      this.socket.once('disconnect', () => {
        this.socket.off('error', handleError)
        resolve()
      })
      this.socket.once('error', handleError)
      this.socket.close()
    })
  }

  isConnected() {
    return this.socket.connected
  }

  addEventsListeners() {
    this.socket
      .on('disconnect', () => {
        logger.debug('DISCONNECT')
        this.gotDisconnection = true
      })
      .on('connect', () => {
        logger.debug('websocket open')
      })
      .on('reconnect_attempt', () => {
        //@ts-ignore
        this.socket.io.opts.query = `token=${shuffleToken(localStorage.getItem('token'))}`
      })
      .on('error', (e: Error) => {
        logger.error('Socket error', e)
      })
      .on(events.ready, () => {
        if (this.gotDisconnection) {
          this.gotDisconnection = false
          this.dispatch(joinAfterDisconnection(this.socket))
        }
      })
      .on(events.meeting_user_joined, (payload: User) => {
        this.dispatch(userJoined(payload))
      })
      .on(events.meeting_staff_joined, (payload: Staff) => {
        this.dispatch(staffJoined(payload))
      })
      .on(events.meeting_user_left, (payload: number) => this.dispatch(userLeft(payload)))
      .on(events.meeting_staff_left, (payload: string) => this.dispatch(staffLeft(payload)))
      .on(events.meeting_message_deleted, (payload: string) => this.dispatch(removeMessage(payload)))
      .on(events.meeting_now_online, (payload: MeetingFromServer) => this.dispatch(setMeeting(payload)))
      .on(events.already_connected, () => {
        const stayHere = () => {
          this.socket.emit(events.continue_here)
        }
        const leave = () => {
          this.dispatch(logout())
          this.socket.emit(events.logout)
          window.location.replace('/')
        }
        confirmAlert({
          title: 'Altra sessione',
          message: 'Sei già collegato su un altro dispositivo con questa utenza, vuoi proseguire qui?',
          buttons: [
            {
              label: 'Si',
              onClick: stayHere
            },
            {
              label: 'No',
              onClick: leave
            }
          ],
          closeOnClickOutside: true,
          closeOnEscape: true,
          onClickOutside: leave,
          onKeypressEscape: leave
        })
      })
      .on(events.force_logout, () => {
        this.dispatch(logout())
        window.location.replace('/')
      })
      .onAny((eventName: string, payload: any) => {
        logger.debug(`ON EVENT::${eventName}`, payload)
        if (eventsToDispatchOnly.includes(eventName)) {
          this.dispatch({ type: eventName, payload })
        }
      })
  }

  addEventListener(event: string, callback: (...args: any[]) => void) {
    this.socket.on(event, callback)
  }

  removeEventListener(event: string, callback: (...args: any[]) => void) {
    this.socket.off(event, callback)
  }

  send(event: string, payload?: any, callback?: Function) {
    logger.info(`EMIT EVENT: ${event}`, payload)
    this.socket.emit(event, payload, callback)
  }
}

export * from './types'

export default new SocketManager()

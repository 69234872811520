import React, { ChangeEvent } from 'react'
import styled from 'styled-components'
import { blue, defaultTextColor, green, red } from '../../constants'
import { ChangeHandler } from 'react-hook-form'

interface InputProps {
  label?: string
  value?: string
  keyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  input?: {
    value: string
    onChange: React.ChangeEventHandler<HTMLInputElement>
    onBlur?: ChangeHandler
    name?: string
    ref?: React.Ref<any>
  }
  readOnly?: boolean
  autoComplete?: string
  defaultValue?: string | number
  type?: 'number' | 'text' | 'password'
  placeholder?: string
  inputStyle?: React.CSSProperties
  style?: {
    containerMargin?: string | string[]
    textColor?: string
    labelColor?: string
    minWidth?: string
    fontSize?: string
    autoFocus?: boolean
    alignSelf?: string
    containerWidth?: string
    containerHeight?: string
    inputFontWeight?: string
    inputFontSize?: string
  }
}

/**
 * Componente Input
 * @param props.label {string} label per l'input
 * @param props.value {string} valore assunto dalla input
 * @param props.keyPress {(e: React.KeyboardEvent<HTMLInputElement>) => void} Callback per il Keypress
 * @param props.input {{ onChange: ChangeHandler, onBlur: ChangeHandler, name: string, ref: React.Ref<any> }} parametri per l'integrazione con una form
 * @param props.readOnly {boolean}
 * @param props.autoComplete {string}
 * @param props.defaultValue {string | number}
 * @param props.type {'number' | 'text'} tipo dell'input

 * @param props.style {Object} oggetto contenente le personalizzazzioni css
 * @param props.style.containerMargin {string | string[]} margin del container contenente label e input
 * @param props.style.textColor {string} colore del testo della input
 * @param props.style.labelColor {string} colore del testo della label
 * @param props.style.minWidth {string}
 * @param props.style.fontSize {string}
 * @param props.style.inputFontWeight {string}
 * @param props.style.inputFontSize {string}
 * @param props.style.autoFocus {boolean}
 * @param props.style.alignSelf {string} align-self del container
 * @param props.style.containerHeight {string} height del container
 * @constructor
 */
export const SearchInput: React.FC<InputProps> = (props) => {
  const {
    keyPress,
    label,
    readOnly = false,
    autoComplete,
    style = {},
    inputStyle = {},
    input,
    type = 'text',
    placeholder = ''
  } = props

  //region helpers
  const getColor = (color?: string): string | null => {
    switch (color) {
      case 'red':
        return red
      case 'green':
        return green
      case 'blue':
        return blue
      default:
        return color || null
    }
  }
  //funzione per convertire il margin
  const getSpace = (type: string, value?: string[] | string): string => {
    if (!value) return ''
    if (typeof value === 'string') return `${type} : ${value}`
    if (Array.isArray(value) && value.length > 1) return `${type} : ${value.join(' ')}`
    return ''
  }
  //endregion

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (keyPress) {
      keyPress(e)
    }
  }

  return (
    <Container
      alignSelf={style.alignSelf}
      margin={getSpace('margin', style.containerMargin)}
      height={style.containerHeight}>
      {label && (
        <CustomLabel textColor={getColor(style.labelColor) || defaultTextColor} fontSize={style.fontSize || '0.95rem'}>
          {label}
        </CustomLabel>
      )}
      <CustomInput
        style={inputStyle}
        fontSize={style.inputFontSize}
        fontWeight={style.inputFontWeight}
        readOnly={readOnly}
        onBlur={(event) => {
          input?.onBlur && input?.onBlur(event)
        }}
        value={input?.value}
        autoComplete={autoComplete}
        name={input?.name}
        ref={input?.ref}
        textColor={getColor(style.textColor) || defaultTextColor}
        type={type}
        password={type === 'password'}
        minWidth={style.minWidth}
        onChange={(e: ChangeEvent<HTMLInputElement>) => input?.onChange && input?.onChange(e)}
        onKeyPress={onKeyPress}
        autoFocus={style.autoFocus}
        placeholder={placeholder}
      />
    </Container>
  )
}

type ContainerProps = {
  alignSelf?: string
  margin: string
  height?: string
  containerWidth?: string
}
const Container = styled.div<ContainerProps>`
 ${({containerWidth}) => `width:${containerWidth} ;` || ''}
  margin: ${({ margin }) => margin || '0'};
  display: grid;
  min-height: 40px;
  padding: 10px;
  color: red;
  ${({ height }) => height && `height :${height}`};
  ${({ alignSelf }) => alignSelf && `align-self: ${alignSelf}`};
  grid-row-gap: 8px;
`

type CustomInputProps = {
  minWidth?: string
  password: boolean
  textColor: string | null
  readOnly?: boolean
  fontWeight?: string
  fontSize?: string
}

const CustomInput = styled.input<CustomInputProps>`
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  ${({ fontWeight }) => (fontWeight ? `font-weight: ${fontWeight};` : '')}
  font-size:${({ fontSize }) => (fontSize ? ` ${fontSize};` : '0.9rem;')}
  box-sizing: border-box;
  border: 0;
  border-bottom: 1px solid #ffffff;
  opacity: 0.9;
  padding: 0.5rem 0 0.5rem 0.3rem;
  ${({ password }) => (password ? 'padding-right: 2rem;' : '')};
  ${({ readOnly }) => (readOnly ? 'cursor:not-allowed' : '')};
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}` : '6.25rem')};
  height: 40px;
  outline: none;

  color: ${({ textColor }) => textColor};

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--placeholder-color);
    font-size: var(--placeholder-size);
  }
`

interface CustomLabelProps {
  fontSize: string
  textColor?: string | null
}

const CustomLabel = styled.label<CustomLabelProps>`
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ textColor }) => textColor || 'var(--label-color)'};
`

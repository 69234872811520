import React from 'react'
import styled from 'styled-components'
import SliderLib from 'rc-slider'
type Props = {
  min?: number
  max?: number
  step?: number
  value: number
  onChange?: (newVal: number) => any
  onMouseLeave?: () => any
  style?: React.CSSProperties
}
export const SliderInput: React.FC<Props> = (props) => {
  const { min = 0, max = 1, step = 0.05, value, onMouseLeave = () => {}, style = {}, onChange = () => {} } = props

  return (
    <Container onMouseLeave={onMouseLeave} style={style}>
      <SliderLib
        min={min}
        max={max}
        step={step}
        vertical={true}
        onChange={(val) => onChange(val as number)}
        value={value}
        handleStyle={{ background: '#E5383B', borderColor: '#ffffff' }}
        trackStyle={{ background: '#E5383B', boxShadow: '' }}
      />
    </Container>
  )
}

//region Style

const Container = styled.div`
  display: grid;
  place-items: center;
  width: 50px;
  height: 100px;
`
//endregion

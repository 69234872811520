import React from 'react'
import styled from 'styled-components'
import {Category} from '../../../../types/rest'
import {useSelector} from 'react-redux'
import {getLanguage, getLogin, getTranslation} from '@store/selectors'
import {useNavigate} from 'react-router-dom'
import {animated, config, useSpring} from 'react-spring'
import useHeight from '../../../../hooks/useHeight'
import Dash from '../../../../images/icons/dash.svg'

/**
 * Componente per la visualizzazione espansa del Menu
 * @param top: pixel da distanziare dall'alto per non coprire il menu
 * @param hide: funzione per la chiusura eseguita sul click away o quando si naviga altrove
 * @param categories: categorie recuperate tramite api
 * @constructor
 */
const ExpandedMenu: React.FC<Props> = ({ top, hide, isInTemplate, categories, on }) => {
  const language = useSelector(getLanguage)
  const translation = useSelector(getTranslation)
  const { isAuthenticated } = useSelector(getLogin)

  const navigate = useNavigate()
  const [heightRef, height] = useHeight()
  const slideInStyles = useSpring({
    config: { ...config.stiff },
    from: { height: 0 },
    to: {
      // L'altezza deve essere sempre 475px
      height: on ? height + (475 - height) : 0
    }
  })

  if (categories[categories.length - 1]?.id !== 10000)
    categories.push({ id: 10000, label: 'tutti', color: '', translations: { it: 'vedi tutti', en: 'show more' } })

  return (
    <Container isInTemplate={isInTemplate} onClick={() => hide()} style={{ ...slideInStyles, overflow: 'hidden' }} heightRef={heightRef?.current} top={top}>
      <BlurBackground/>
      <Separator />
      <Wrapper>
      <Menu ref={heightRef} onClick={(e) => e.stopPropagation()}>
        <Categories>
          <div style={{  display: 'flex', alignItems: 'start' }}>
            <div style={{ display: 'flex', alignItems: 'center', height: '42px' }}>
              <img src={Dash} alt="" style={{ marginRight: '0.6rem', filter: 'invert(1)' }} />
            </div>
            <ItemsList>
              <h3 style={{ margin: 0,fontSize: 'var(--title-fs)', fontWeight: 700, whiteSpace: 'nowrap' }}>{translation.topBar.categories}</h3>
              {
                categories.slice(0, 4).map((c) => (
                <CategoryCard
                  onClick={() => {
                    hide()
                    navigate(`/categories/${c.slug || c.id}`)
                  }}
                  cId={c.id}
                  key={c.id}>
                  <CategoryLabel>{c.translations[language]}</CategoryLabel>
                </CategoryCard>
              ))}
              <CategoryCard>
                <CategoryLabel onClick={()=> {
                  hide()
                  navigate(`/categories`)
                }}>{language === 'it' ? 'vedi tutti' : 'show more'}</CategoryLabel>
              </CategoryCard>
            </ItemsList>
          </div>
        </Categories>
        <LinksMain>
          <Categories>
            <div style={{ display: 'flex', alignItems: 'start' }}>
              <div style={{ display: 'flex', alignItems: 'center', height: '42px' }}>
                <img src={Dash} alt="" style={{ marginRight: '0.6rem', filter: 'invert(1)' }} />
              </div>
              <ItemsList>
                <h3 style={{ margin: 0,fontSize: 'var(--title-fs)', fontWeight: 700,  whiteSpace: 'nowrap'}}>{translation.topBar.explore}</h3>
                <Link
                  style={{fontWeight: 400, whiteSpace: 'nowrap'}}
                  onClick={() => {
                    navigate('/maxxi-original')
                    hide()
                  }}>
                  <span>{translation.entities.AuthorContent}</span>
                </Link>
                <Link
                  style={{fontWeight: 400, whiteSpace: 'nowrap'}}
                  onClick={() => {
                    navigate('/courses')
                    hide()
                  }}>
                  <span>{translation.entities.masterclass}</span>
                </Link>
                <Link
                  style={{fontWeight: 400, whiteSpace: 'nowrap'}}
                  onClick={() => {
                    navigate('/maxxi-library')
                    hide()
                  }}>
                  <span>{translation.entities.archive}</span>
                </Link>
              </ItemsList>
            </div>
          </Categories>
        </LinksMain>
        <LinksSecondary>
          <Filler></Filler>
          {!isAuthenticated ? (
            <Link
              style={{ textAlign: 'right', width: '100%' , whiteSpace: 'nowrap', fontWeight: 500 }}
              onClick={() => {
                navigate('/faq')
                hide()
              }}>
              {translation.topBar.FAQ}
            </Link>
          ) : (
            <>
              <Link
                style={{ textAlign: 'right', width: '100%' , whiteSpace: 'nowrap', fontWeight: 500}}
                onClick={() => {
                  navigate('/get-started')
                  hide()
                }}>
                {translation.topBar.how}
              </Link>
              <Link
                style={{ textAlign: 'right', width: '100%' , whiteSpace: 'nowrap', fontWeight: 500 }}
                onClick={() => {
                  navigate('/faq')
                  hide()
                }}>
                {translation.topBar.FAQ}
              </Link>
            </>
          )}
        </LinksSecondary>
      </Menu>
    </Wrapper>
    </Container>
  )
}

export default ExpandedMenu

type Props = {
  top: number
  hide: Function
  categories: Category[]
  on: boolean
  isInTemplate?: boolean
}
//region Style
const Filler = styled.div`
  height: 41px;
`
const Container = styled(animated.div)<{ isInTemplate?: boolean, top: number, heightRef: number }>`
  position: absolute;
  top: ${({isInTemplate})=> isInTemplate ? 'var(--topbar-height)' : '155px' };
  right: 0;
  left: 0;
  height: 500px;
  background-color: #7c6eb0;
`
const Wrapper = styled.div`
  width: 100%;
  max-width: var(--max-content-width);
  margin: 0 auto;
`
const BlurBackground = styled.div`
  z-index: -1;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: var(--topbar-height);
  background-color: transparent;
  backdrop-filter: blur(6px);
  animation: blurring 0.4s;
  @keyframes blurring {
  from{ backdrop-filter: blur(0px); }
  to: { backdrop-filter: blur(6px); }
`
const Separator = styled.div`
  height: 1px;
  width: 90%;
  opacity: 0.3;
  background-color: #707070;
  position: absolute;
  top: 1px;
  left: 5%;
`
const Menu = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 65px;
  padding-bottom: 45px;
  place-content: space-between;
  font-size: var(--title-fs);
  font-weight: 500;
  color: white;
  grid-column-gap: 4rem;
`
const Categories = styled.div`
  display: grid;
  grid-template-columns: 1fr ;
  grid-column-gap: 30px;
  grid-row-gap: 20px;
  place-self: start;
  width: 90%;
`
const ItemsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
const CategoryCard = styled.div<{cId?: number}>`
  transition: 0.4s;
  ${({cId})=> {

  return cId !== 10000 ? '  cursor: pointer;  &:hover {\n    opacity: 0.7;\n    transition: 0.2s;\n    transform: translate(5px, 0);\n  }' : ''
}}
`
const CategoryLabel = styled.span`
  place-self: center start;
  font-weight: 400;
  white-space: nowrap;
`
const LinksMain = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 20px;
  place-self: start center;
  width: 90%;
`
const Link = styled.div`
  cursor: pointer;
  transition: 0.4s;
  font-weight 400;
  &:hover {
    filter: opacity(0.7);
    transform: translate(5px, 0);
  }
`
const LinksSecondary = styled.div`
  padding: 0 4%;
  display: flex;
  flex-direction: column;
  justify-self: end;
  align-self: start;
  gap: 20px;
  justify-content: space-around;
`
//endregion

import React, { FunctionComponent, SVGProps } from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'

type SVG = FunctionComponent<SVGProps<SVGSVGElement>>
type Props = {
  labelActive?: string
  labelDisactive?: string
  iconActive: SVG | string
  iconDisactive: SVG | string
  isActive: boolean
  onClick: () => void
  containerStyle?: React.CSSProperties
  imageStyle?: React.CSSProperties
  tooltip?: string
  onMouseEnter?: () => any
  onMouseLeave?: () => any
  disabled?: boolean
}
export const ControlButton: React.FC<Props> = (props) => {
  const {
    labelActive,
    labelDisactive,
    isActive,
    iconActive,
    iconDisactive,
    onClick,
    containerStyle = {},
    imageStyle = {},
    tooltip,
    disabled,
    onMouseEnter = () => {
    },
    onMouseLeave = () => {
    }
  } = props

  const getIcon = (Icon: SVG | string) => {
    if (typeof Icon === 'string') {
      return <img style={imageStyle} src={Icon} alt={labelActive || labelDisactive} />
    } else {
      return <Icon style={imageStyle} />
    }
  }
  return (
    <Container
      hasLabel={!!labelActive}
      onClick={() => (disabled === undefined ? onClick() : !disabled && onClick())}
      style={containerStyle}
      data-tip
      data-for={tooltip}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <Image style={imageStyle} disabled={disabled}>
        {isActive ? getIcon(iconActive) : getIcon(iconDisactive)}
      </Image>
      {labelActive && labelDisactive && <Label>{isActive ? labelActive : labelDisactive}</Label>}
      {tooltip && disabled === undefined &&
      <ReactTooltip id={tooltip} effect={'float'} place={'top'}>{tooltip}</ReactTooltip>}
    </Container>
  )
}

//region Style

const Container = styled.div<{ hasLabel: boolean }>`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 10px 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: grid;
  place-content: center;
  grid-template-rows: ${({ hasLabel }) => (hasLabel ? '2fr 1fr' : 'auto')};
  cursor: pointer;
  place-self: center;
  position: relative;
`

const Label = styled.div`
  color: #ffffff;
  font-size: 0.95rem;
`

const Image = styled.div<{ style: React.CSSProperties; disabled?: boolean }>`
  display: grid;
  place-content: center;

  & > img,
  & > svg {
    width: 30px;
    height: 30px;
    fill: ${({ disabled }) => (disabled === undefined ? '#ffffff' : disabled ? '#a8a8a8' : '#ffffff')};
    ${({ disabled }) => disabled && 'cursor: not-allowed'};
  }
`
//endregion

import { shuffleToken } from '../../helpers/shuffleToken'
import { Reducer } from 'redux'
import * as t from './types'

export const initialState: t.LoginState = {
  user: null,
  token: shuffleToken(localStorage.getItem('token')),
  error: '',
  isAuthenticated: false
}

type LoginReducer = Reducer<t.LoginState, t.LoginActionTypes>

const loginReducer: LoginReducer = (state = initialState, action: t.LoginActionTypes): t.LoginState => {
  switch (action.type) {
    case t.LOGIN:
      return {
        user: action.payload.user,
        token: action.payload.token,
        isAuthenticated: true,
        error: ''
      }
    case t.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
        isAuthenticated: true
      }
    case t.RENEW_TOKEN:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        isAuthenticated: true
      }
    case t.LOGOUT:
      return { ...state, user: null, token: null, isAuthenticated: false }
    case t.LOGIN_ERROR:
      return { ...state, error: action.payload, token: null, isAuthenticated: false }
    case t.CHANGE_AVATAR:
      return { ...state, user: state.user ? { ...state.user, avatar: action.payload } : null }
    case t.EDIT_USER:
      return { ...state, user: state.user ? { ...action.payload } : null }
    default:
      return state
  }
}

export default loginReducer

import React, {useState} from 'react'
import styled from 'styled-components'
import notFoundImg from '../../images/icons/kh-notFound.svg'

type Props = {
  src: string
  style?: React.CSSProperties
  aspectRatio?: string
}
export const Thumbnail: React.FC<Props> = ({ src, style = {}, aspectRatio }) => {
  const [notFound, setNotFound]= useState(false)
  return (
    <Container style={style} notFound={notFound} aspectRatio={aspectRatio}>
      <img
        src={!notFound ? src : notFoundImg}
        style={{backgroundColor: notFound ? '#000000' : 'transparent'}}
        alt="thumbnail"
          onError={({ currentTarget }) => {
          currentTarget.onerror = null // prevents looping
          setNotFound(true)
        }}
      />
    </Container>
  )
}

//region Style

const Container = styled.div<{ aspectRatio?: string, notFound?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  aspect-ratio: ${(props) => (props.aspectRatio ? props.aspectRatio : '4/3')};
  & img {
    object-fit:${({notFound})=> notFound ? 'contain' : 'cover'} ;
    ${({notFound})=> notFound ? 'padding: 1rem 0;' : ''}
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    height: 100%;
    @media (max-width: 720px) {
      min-width: 100%;
      min-height: 100%;
    }
  }
`

//endregion

import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import {  getTranslation } from '@store/selectors'
import RecommendedCard from '../../components/pages/course/RecommendedCard'
import useMediaQuery from '../../hooks/useMediaQuery'
import { small } from '../../constants'
import {Button} from "./Button";

interface Props {
  data: any[]
  title?: string
  showMore?: Function
  withShowMoreButton?: boolean
  showMoreType?: "ghost" | "primary" | "secondary" | "ghost-variant" | "secondary-inverted"
  fontSize?: string
  showMoreText?: string
}

export const Wall: React.FC<Props> = ({data, title, showMore, withShowMoreButton= true, showMoreType, showMoreText  }) => {
  const translation = useSelector(getTranslation)
  const isMobile = useMediaQuery(`(max-width: ${small})`)

  return (
    <>
      {data && (
        <Container mobile={isMobile}>
          {title && <TitleContainer isMobile={isMobile}>
            <h1 style={{margin: !isMobile ? '3rem 0 3rem 0' : '3rem 0', fontSize: isMobile ? 'var(--title-h1-mobile)' : 'var(--title-h1)'}}>{title}</h1>
          </TitleContainer>
          }
          <WallBox mobile={isMobile}>
            {data.map((d: any, i: number) => (
              <RecommendedCard wall key={i} data={d} />
            ))}
          </WallBox>
          { withShowMoreButton &&
              <div style={{display:'flex', justifyContent: "center", marginTop: '70px'}}>
                  <Button buttonType={showMoreType ? showMoreType : 'ghost'} text={showMoreText ? showMoreText : translation.common.buttons.showMore} onClick={() => showMore && showMore()}/>

              </div>
          }
        </Container>
      )}
    </>
  )
}

const Container = styled.div<{mobile: boolean}>`
  margin: 0 auto;
  width: 100%;
  ${({mobile})=> mobile ? '' : 'max-width: var(--max-content-width);'}
  ${({mobile})=> mobile && 'padding: 0 20px'}
  `

const WallBox = styled.div<{mobile: boolean }>`
  width: 100%;
  display: grid;
  grid-row-gap: 4rem;
  grid-column-gap: 30px;
  margin-bottom: 4rem;
  grid-template-columns: ${({mobile})=> mobile ? '1fr 1fr': '1fr 1fr 1fr' } ;
  max-width: ${({mobile})=> mobile ? '90vw' : 'var(--max-content-width)'};
 @media (max-width: 600px) {
   grid-template-columns: 1fr ;
  }
  grid-template-rows: repeat(auto-fit, 1fr);

`

const TitleContainer = styled.div<{isMobile: boolean}>`
  display:flex;
`

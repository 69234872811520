import React from 'react'
import useMediaQuery from '../../../hooks/useMediaQuery'
import {small} from '../../../constants'
import TopbarMobile from './Mobile/TopbarMobile'
import Topbar from './Desktop/TopBar'

/** Componente che decide con una media query quale versione della topbar renderizzare */
const TopbarChoose: React.FC<{isInTemplate: boolean; height: number }> = ({isInTemplate, height }) => {
  const isMobile = useMediaQuery(`(max-width: ${small})`)

  return isMobile ? <TopbarMobile /> : <Topbar isInTemplate={isInTemplate} height={height} />
}

export default TopbarChoose

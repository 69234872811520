import React from 'react'
import styled from 'styled-components'
import { useController, UseControllerProps, useFormState } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { getErrorMessage } from '../../helpers/get-error-message'

type Props<TFieldValues> = React.InputHTMLAttributes<HTMLInputElement> &
  UseControllerProps<TFieldValues> & {
    containerStyle?: React.CSSProperties
    label?: string
    leaveSpaceForError?: boolean
    mainColor?: string
    endAdornment?: React.ReactNode | null
    alphanum?: boolean
    validateMail?: boolean
  }

export function Input<TFieldValues>(props: Props<TFieldValues>) {
  const {
    alphanum,
    validateMail,
    control,
    name,
    rules,
    label,
    leaveSpaceForError = true,
    containerStyle = {},
    mainColor = 'var(--label-color)',
    endAdornment = null,
    ...inputProps
  } = props

  const newRules = alphanum ? {...rules, pattern: /^[a-zA-Z0-9èòàùìé ]*$/} : (validateMail ? {...rules, pattern: /^[a-zA-Z0-9èòàùìé @._-]*$/} : rules
  )


  const { field } = useController({ control, name,  rules: newRules })

  const { errors } = useFormState<TFieldValues>({ control, name })


  return (
    <Container style={containerStyle} leaveSpaceForError={leaveSpaceForError} $end={!!endAdornment}>
      <CustomLabel mainColor={mainColor} show={!!label}>
        {label}
      </CustomLabel>
        <CustomInput
            mainColor={mainColor}
            password={inputProps.type === 'password'}
            {...field}
            {...(inputProps as any)}

        />

      {endAdornment}
        <ErrorMessage
            errors={errors}
            name={name as any}
            render={({message}) => <Error>{getErrorMessage(errors, name, message)}</Error>}
        />
    </Container>
  )
}

const Container = styled.div<{ leaveSpaceForError: boolean; $end: boolean }>`
  display: grid;
  grid-template-areas: ${(props) => (props.$end ? `'label end' 'input end' 'error end'` : `'label' 'input' 'error'`)};
  grid-template-columns: 1fr ${(props) => (props.$end ? 'auto' : '')};
  grid-template-rows: ${(props) => (props.leaveSpaceForError ? 'auto min-content 20px' : 'auto 1fr')};
  width: 100%;
`

type CustomInputProps = {
  minWidth?: string
  password: boolean
  readOnly?: boolean
  mainColor: string
}

const CustomInput = styled.input<CustomInputProps>`
  width: 100%;
  color: ${(props) => props.mainColor};
  background: ${({ readOnly }) => (readOnly ? '#cecece' : 'transparent')};
  box-sizing: border-box;
  border: 0;
  border-bottom: 1px solid ${(props) => props.mainColor};
  opacity: 0.9;
  padding: 0.5rem 0 0.5rem 0.3rem;
  font-size: 0.9rem;
  ${({ password }) => (password ? 'padding-right: 2rem;' : '')};
  ${({ readOnly }) => (readOnly ? 'cursor:not-allowed' : '')};
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : '6.25rem')};
  height: 40px;
  outline: none;
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--placeholder-color);
    font-size: var(--placeholder-size);
  }
`

const CustomLabel = styled.label<{ show: boolean; mainColor: string }>`
  grid-area: label;
  ${(props) => !props.show && 'height: 0px'};
  font-size: 1rem;
  color: ${(props) => props.mainColor};
  width: 100%;
`

const Error = styled.div`
  grid-area: error;
  color: white;
  font-size: 0.8rem;
  margin-left: 8px;
  z-index: auto;
`
